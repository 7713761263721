<template>
  <div class="mainContainer" v-if="!this.mobileStore.mobile">
    <q-form v-on:submit="addTheme()">
      <div>
        <h1>Vytvořit nové téma:</h1>
        <q-input
            color="purple" square filled
            v-model="name"
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
            label="Název tématu:" />

        <q-input
            label="O čem chcete diskutovat/Jaký je Váš dotaz?"
            color="purple"
            v-model="text"
            filled
            autogrow
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
        /><br>
        <p class="error" v-if="this.error">{{this.errorMessage}}</p>
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vytvořit téma" flat type="submit" /><br><br><br>
      </div>
    </q-form>

    <h1>Dostupná témata:</h1>

    <p class="noThemes" v-if="this.discussionStore.themes.length === 0">Žádné téma :(</p>


    <q-card>
      <div v-for="themes in getData()" :key="themes.name" id="themesShowed">
        <a class="themeName" href="#" @click="getDetails(themes._id)">{{themes.name}}</a><br>
        <bc style="margin-left: 10px">Poslední odpověď:</bc> {{themes.lastReply}}
        <q-separator></q-separator>
      </div>
    </q-card>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>


  </div>








  <div class="fixed-center mainContainer mobile" v-if="this.mobileStore.mobile">
    <q-form v-on:submit="addTheme()">
      <div>
        <h1>Vytvořit nové téma:</h1>
        <q-input
            color="purple" square filled
            v-model="name"
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
            label="Název tématu:" />

        <q-input
            label="O čem chcete diskutovat/Jaký je Váš dotaz?"
            color="purple"
            v-model="text"
            filled
            autogrow
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
        /><br>
        <p class="error" v-if="this.error">{{this.errorMessage}}</p>
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vytvořit téma" flat type="submit" /><br><br><br>
      </div>
    </q-form>

    <h1>Dostupná témata:</h1>

    <p class="noThemes" v-if="this.discussionStore.themes.length === 0">Žádné téma :(</p>


    <q-card>
      <div v-for="themes in getData()" :key="themes.name" id="themesShowed mobile">
        <a class="themeName" href="#" @click="getDetails(themes._id)">{{themes.name}}</a><br>
        <bc style="margin-left: 10px">Poslední odpověď:</bc> {{themes.lastReply}}
        <q-separator></q-separator>
      </div>
    </q-card>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>
</template>

<script>
import {useMobileStore} from "../stores/MobileStore";
import {mapStores} from "pinia/dist/pinia";
import {useDiscussionStore} from "../stores/DiscussionStore";
import {useUserStore} from "@/stores/UserStore";

export default {
  name: "DiscussionView",
  components:{

  },

  data(){
    return {
      text: null,
      name: null,
      error: null,
      page: 1,
      totalPages: null,
    }
  },

  async created(){
    await this.discussionStore.getThemes(this.userStore.user.nickname);
    this.totalPages = Math.ceil(this.discussionStore.themes.length/20);
  },

  computed: {
    ...mapStores(useMobileStore),
    ...mapStores(useDiscussionStore),
    ...mapStores(useUserStore),
  },

  methods: {
    async addTheme(){
      try {
        await this.discussionStore.createTheme(this.userStore.user.nickname, this.name, this.text);
        await this.discussionStore.getThemes(this.userStore.user.nickname);
        await this.getDetails(this.discussionStore.lastCreated);
        this.error = true;
      } catch{
        this.error = true;
      }
    },


    getData(){
      window.scroll(0,0);
      if (this.page === 1) {
        return this.discussionStore.themes.slice(this.page-1, this.page*20);
      } else {
        return this.discussionStore.themes.slice(this.page*20-20, this.page*20);
      }
    },

    async getDetails(id){
      try {
        await this.discussionStore.getDetail(this.userStore.user.nickname, id);
        this.$router.push({name: 'themeDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    }

  },
}
</script>

<style scoped>
  .mainContainer {
    width: 50%;
    margin: auto;
  }

  .mobile {
    width: 90%;
  }

  .logoImage {
    width: 100%;
  }

  .mainContainer h1 {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .mainContainer h2 {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .all-page-button {
    width: 100%;
  }

  .error {
    color: darkred;
    font-size: 15px;
  }

  .themeName {
    color: purple;
    text-decoration: none;
    font-size: 25px;
    margin-left: 10px;
  }

  .themeName:hover {
    color: #008080;
  }

  .noThemes {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }
</style>