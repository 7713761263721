<template>
  <div>
    <img src="../images/Auraxed-Maggies.jpg" alt="Auraxiované Maggie na Hossinu" class="vehicleImage"><br><br>
  </div>
  <div class="vehiclesContainer" v-if="!this.mobileStore.mobile">

    <div v-if="(this.userStore.loggedIn === true  && this.userStore.user.role === 'admin') || (this.userStore.loggedIn === true  && this.userStore.user.role === 'member')">
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Přidat návod" @click="changePostingGuideState" /><br><br>
    </div>

    <div v-for="guides in getData()" :key="guides.name" id="guidesShowed">
      <q-card>
        <q-card-section>
          <a class="clickableTitle" href="#" @click="getGuideDetails(guides._id)">{{guides.name}}</a>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section horizontal>
          <q-card-section>
            <img v-bind:src="guides.vehicle + '.png'" style='height: 100px; width: auto' alt="Obrázek návodu" class="newsPicture"/>
          </q-card-section>
          <q-card-section>
            <p class="articleText">{{guides.description}}</p>
          </q-card-section>
        </q-card-section><br>
        <q-separator />
        <q-card-section>
          <p><b><a class="clickableUser" href="#" @click="getUserDetails(guides.author)">{{guides.author}}</a></b> {{guides.date}}</p>
          <p v-if="guides.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getUserDetails(guides.updateAuthor)">{{guides.updateAuthor}}</a></b> {{guides.updateDate}}</p>
        </q-card-section>
      </q-card><br>
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>

  </div>

  <div class="vehiclesContainer mobile" v-if="this.mobileStore.mobile">
    <div v-if="(this.userStore.loggedIn === true  && this.userStore.user.role === 'admin') || (this.userStore.loggedIn === true  && this.userStore.user.role === 'member')">
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Přidat návod" @click="changePostingGuideState" /><br><br>
    </div>

    <div v-for="guides in getData()" :key="guides.name" id="guidesShowedMobile">
      <q-card>
        <q-card-section>
          <a class="clickableTitle" href="#" @click="getGuideDetails(guides._id)">{{guides.name}}</a>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section horizontal>
          <q-card-section>
            <p class="articleText">{{guides.description}}</p>
          </q-card-section>
        </q-card-section><br>
        <q-separator />
        <q-card-section>
          <p><b><a class="clickableUser" href="#" @click="getUserDetails(guides.author)">{{guides.author}}</a></b> {{guides.date}}</p>
          <p v-if="guides.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getUserDetails(guides.updateAuthor)">{{guides.updateAuthor}}</a></b> {{guides.updateDate}}</p>
        </q-card-section>
      </q-card><br>
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>



  <q-dialog v-model="this.postingGuide">
    <q-card style="width: 100%">
      <div class="insideCard">
        <h1>Přidat návod do kategorie vozidla: </h1>

        <q-form v-on:submit="addGuide()">
          <q-input
              color="purple" square filled
              v-model="name"
              label="Nadpis *"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
          <br>
          <q-input
              color="purple" square filled
              v-model="description"
              autogrow
              label="Krátký popis *"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
          <br>
          <q-input
              color="purple" square filled
              v-model="text"
              autogrow
              label="Text návodu *"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/>
          <br>

          <q-select
              color="purple" filled
              v-model="selectedVehicle"
              :options="vehicle"
              :rules="[ val => val && val.length > 0 || 'Vyberte prosím vozidlo']"
              @update:model-value="this.nullEverything()"
              label="Vozidlo *" />

          <h1>Loadout:</h1>
          <p>(Pokud se Váš návod týka konkrétního playstyle)</p>

          <div v-if="this.selectedVehicle === 'Flash' || this.selectedVehicle === 'Lightning' || this.selectedVehicle === 'Magrider' || this.selectedVehicle === 'Prowler' || this.selectedVehicle === 'Chimera'
            || this.selectedVehicle === 'Vanguard' || this.selectedVehicle === 'Scythe' || this.selectedVehicle === 'Mosquito' || this.selectedVehicle === 'Reaver' || this.selectedVehicle === 'Dervish' || this.selectedVehicle === 'Valkyrie' ||
             this.selectedVehicle === 'Liberator' || this.selectedVehicle === 'Colossus' || this.selectedVehicle === 'Javelin'">
            <q-input
                color="purple" square filled
                v-model="weapon"
                label="Primární zbraň"/><br>
          </div>

          <div v-if="this.selectedVehicle === 'ANT'">
            <q-input
                color="purple" square filled
                v-model="harvest"
                label="Sběrač"/><br>
          </div>

          <div v-if="this.selectedVehicle === 'Harasser' || this.selectedVehicle === 'Sunderer' || this.selectedVehicle === 'ANT' || this.selectedVehicle === 'Magrider' || this.selectedVehicle === 'Vanguard'
              || this.selectedVehicle === 'Prowler' || this.selectedVehicle === 'Chimera' || this.selectedVehicle === 'Galaxy' || this.selectedVehicle === 'Corsair' || this.selectedVehicle === 'Dervish'">
            <q-input
                color="purple" square filled
                v-model="topGun"
                label="Horní zbraň"/><br>
          </div>

          <div v-if ="this.selectedVehicle === 'Sunderer' || this.selectedVehicle === 'Corsair'">
            <q-input
                color="purple" square filled
                v-model="secondTopGun"
                label="Sekundární horní zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Scythe' || this.selectedVehicle === 'Mosquito' || this.selectedVehicle === 'Reaver'">
            <q-input
                color="purple" square filled
                v-model="wings"
                label="Křídla"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Liberator'">
            <q-input
                color="purple" square filled
                v-model="bellyGun"
                label="Spodní zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Galaxy' || this.selectedVehicle === 'Liberator'">
            <q-input
                color="purple" square filled
                v-model="tailGun"
                label="Zadní zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Galaxy'">
            <q-input
                color="purple" square filled
                v-model="leftGun"
                label="Levá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Galaxy'">
            <q-input
                color="purple" square filled
                v-model="rightGun"
                label="Pravá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="frontRightGun"
                label="Přední pravá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="frontLeftGun"
                label="Přední levá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="backRightGun"
                label="Zadní pravá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="backLeftGun"
                label="Zadní levá zbraň"/><br>
          </div>
          <q-input
              color="purple" square filled
              v-model="utility"
              label="Utilita"/><br>
          <q-input
              color="purple" square filled
              v-model="defence"
              label="Obranný slot"/><br>
          <div v-if="this.selectedVehicle !== 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="performance"
                label="Slot pro úpravu výkonu"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="logistics"
                label="Logistika"/><br>
          </div>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" align="center" label="Přidat návod" flat type="submit" />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-form><br>
      </div>
    </q-card>
  </q-dialog>


</template>

<script>
import {useMobileStore} from "../stores/MobileStore";
import {mapStores} from "pinia/dist/pinia";
import {useVehicleGuideStore} from "@/stores/VehicleGuideStore";
import {useUserStore} from "@/stores/UserStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";

export default {
  name: "VehiclesView",

  setup(){
    return {
      vehicle: [
        'Flash', 'Javelin', 'Harasser', 'Sunderer', 'ANT', 'Lightning', 'Magrider', 'Prowler', 'Vanguard', 'Chimera', 'Scythe', 'Mosquito', 'Reaver', 'Dervish', 'Valkyrie', 'Liberator', 'Galaxy', 'Colossus', 'Corsair'
      ],
    }
  },

  computed: {
    ...mapStores(useMobileStore),
    ...mapStores(useVehicleGuideStore),
    ...mapStores(useUserStore),
    ...mapStores(useAccountDetailStore)
  },

  async created(){
    await this.vehicleGuideStore.getGuides();
    await this.getData();
    this.totalPages = Math.ceil(this.vehicleGuideStore.guides.length/5);
  },

  components: {
  },

  data() {
    return {
      selectedVehicle: null,
      name: null,
      text: null,
      description: null,
      postingGuide: false,
      error: false,

      weapon: null,
      harvest: null,
      topGun: null,
      secondTopGun: null,
      wings: null,
      bellyGun: null,
      tailGun: null,
      leftGun: null,
      rightGun: null,
      frontRightGun: null,
      frontLeftGun: null,
      backRightGun: null,
      backLeftGun: null,
      utility: null,
      defence: null,
      performance: null,
      logistics: null,

      page: 1,
      totalPages: null,
    }
  },


  methods: {
    async addGuide(){
      try {
        await this.vehicleGuideStore.addGuide(this.userStore.user.nickname, this.name, this.description, this.text, this.weapon, this.harvest, this.topGun, this.secondTopGun,
        this.wings, this.bellyGun, this.tailGun, this.leftGun, this.rightGun, this.frontLeftGun, this.frontRightGun, this.backLeftGun, this.backRightGun, this.utility,
        this.defence, this.performance, this.logistics, this.selectedVehicle);

        await this.vehicleGuideStore.getGuideDetail(this.vehicleGuideStore.lastID);
        this.$router.push({name: 'vehicleDetail'});
        this.error = false;
      } catch{
        this.error = true;
      }
    },

    nullEverything(){
          this.test = true;
          this.weapon = null;
          this.harvest = null;
          this.topGun = null;
          this.secondTopGun = null;
          this.wings = null;
          this.bellyGun = null;
          this.tailGun = null;
          this.leftGun = null;
          this.rightGun = null;
          this.frontRightGun = null;
          this.frontLeftGun = null;
          this.backRightGun = null;
          this.backLeftGun = null;
          this.utility = null;
          this.defence = null;
          this.performance = null;
          this.logistics = null;
    },

    changePostingGuideState(){
      if(this.postingGuide === false){
        this.postingGuide = true;
      } else {
        this.postingGuide = false;
      }
    },

    getData(){
      if (this.page === 1) {
        return this.vehicleGuideStore.guides.slice(this.page-1, this.page*5);
      } else {
        return this.vehicleGuideStore.guides.slice(this.page*5-5, this.page*5);
      }
    },

    async getUserDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async getGuideDetails(id){
      try {
        await this.vehicleGuideStore.getGuideDetail(id);
        this.$router.push({name: 'vehicleDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },
  }

}
</script>

<style scoped>
  .vehiclesContainer {
    width: 50%;
    margin: auto;
  }

  .vehiclesContainer h1{
    font-size: 25px;
  }

  .vehicleImage {
    width: 100%;
    margin-top: -50px;
  }

  .mobile {
    width: 90%;
  }

  .all-page-button {
    width: 100%;
  }

  .insideCard {
    width: 90%;
    margin: auto;
  }

  .insideCard h1 {
    font-size: 25px;
  }

  .clickableTitle {
    color: purple;
    text-decoration: none;
    font-size: 25px;
  }

  .clickableTitle:hover {
    color: #008080;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }
</style>