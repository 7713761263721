import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useDaybreakStore = defineStore('daybreak', {
    state: () => ({
        onlineIngameVS: [],
        onlineIngameTR: [],
        onlineAdmins: [],
        onlineMembers: [],
        onlineUsers: [],
        error: null,
    }),

    actions: {
        async getOnlineVS(){
            try {
                const response = await axios.post(config.backendUrl + '/getOnlineVS');
                this.onlineIngameVS = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getOnlineTR(){
            try {
                const response = await axios.post(config.backendUrl + '/getOnlineTR');
                this.onlineIngameTR = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getOnlineAdmins(){
            try {
                const response = await axios.post(config.backendUrl + '/getOnlineAdmins');
                this.onlineAdmins = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getOnlineMembers(){
            try {
                const response = await axios.post(config.backendUrl + '/getOnlineMembers');
                this.onlineMembers = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getOnlineUsers(){
            try {
                const response = await axios.post(config.backendUrl + '/getOnlineUsers');
                this.onlineUsers = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },
    }
});