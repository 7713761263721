<template>
  <div class="infantryContainer" v-if="!this.mobileStore.mobile">
    <div v-if="(this.userStore.loggedIn === true  && this.infantryGuideStore.guideDetail.author === this.userStore.user.nickname) || (this.userStore.loggedIn === true  && this.userStore.user.role === 'admin')">
      <q-card>
        <div style="width: 95%; margin: auto">
          <h1>Upravit návod: </h1>
          <q-form v-on:submit="updateGuide()">
            <q-input
                color="purple" square filled
                v-model="name"
                label="Nadpis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="description"
                autogrow
                label="Krátký popis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="text"
                autogrow
                label="Text návodu"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/><br>
            <q-select
                color="purple" filled
                v-model="selectedImage"
                :options="images"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím pěchotní třídu:']"
                label="Obrázek *" />
            <br>
            <h1>Loadout:</h1>
            <p>(Pokud se Váš návod týka konkrétního playstyle)</p>

            <div>
              <q-input
                  color="purple" square filled
                  v-model="weapon"
                  label="Primární zbraň"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="secondaryWeapon"
                  label="Sekundární zbraň"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="tool"
                  label="Nástroj"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="ability"
                  label="Abilita"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="suit"
                  label="Úprava obleku"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="grenade"
                  label="Granát"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="utility"
                  label="Utilita"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="melee"
                  label="Zbraň na blízko"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="implant"
                  label="Implantát 1"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="secondImplant"
                  label="Implantát 2"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="tactical"
                  label="Taktický slot"/><br>
            </div>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit návod" flat type="submit" /><br><br>
            <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit návod" flat @click="changeDeleteState()"/>
          </q-form><br>
        </div>
      </q-card>
    </div>


    <q-card>
      <div style="width: 95%; margin: auto">
        <h1>{{this.infantryGuideStore.guideDetail.name}}</h1>
        <p class="articleText">{{this.infantryGuideStore.guideDetail.description}}</p>
        <q-card-section>
          <img v-bind:src="this.infantryGuideStore.guideDetail.image + '.png'" style='width: 100%; height: auto' alt="Obrázek návodu" class="newsPicture"/>
        </q-card-section>
        <b><a class="clickableUser" href="#" @click="getUserDetails(this.infantryGuideStore.guideDetail.author)">{{this.infantryGuideStore.guideDetail.author}}</a></b> {{this.infantryGuideStore.guideDetail.date}}
        <div v-if="this.infantryGuideStore.guideDetail.updateAuthor !== null">
          <b>Update: <a class="clickableUser" href="#" @click="getUserDetails(this.infantryGuideStore.guideDetail.updateAuthor)">{{this.infantryGuideStore.guideDetail.updateAuthor}}</a></b> {{this.infantryGuideStore.guideDetail.updateDate}}
        </div><br>
        <q-separator></q-separator><br>
        <div>
          <b>Třída: </b>{{this.infantryGuideStore.guideDetail.infantry}}<br><br>
          <b>Loadout:</b><br><br>
          <div v-if="this.infantryGuideStore.guideDetail.weapon !== null">
            <b>Zbraň: </b>{{this.infantryGuideStore.guideDetail.weapon}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.secondaryWeapon !== null">
            <b>Sekundární zbraň: </b>{{this.infantryGuideStore.guideDetail.secondaryWeapon}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.tool !== null">
            <b>Nástroj: </b>{{this.infantryGuideStore.guideDetail.tool}}<br>
          </div>

          <div v-if="this.infantryGuideStore.guideDetail.ability !== null">
            <b>Abilita: </b>{{this.infantryGuideStore.guideDetail.ability}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.suit !== null">
            <b>Úprava obleku: </b>{{this.infantryGuideStore.guideDetail.suit}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.grenade !== null">
            <b>Granát: </b>{{this.infantryGuideStore.guideDetail.grenade}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.utility !== null">
            <b>Utilita: </b>{{this.infantryGuideStore.guideDetail.utility}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.melee !== null">
            <b>Zbraň na blízko: </b>{{this.infantryGuideStore.guideDetail.melee}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.implant !== null">
            <b>Implantát 1: </b>{{this.infantryGuideStore.guideDetail.implant}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.secondImplant !== null">
            <b>Implantát 2: </b>{{this.infantryGuideStore.guideDetail.secondImplant}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.tactical !== null">
            <b>Taktický slot: </b>{{this.infantryGuideStore.guideDetail.tactical}}<br>
          </div>
        </div><br><br>
        <q-separator></q-separator><br><br>
        <p class="articleText">{{this.infantryGuideStore.guideDetail.text}}</p><br>
        </div>
    </q-card><br><br>

  </div>



  <div class="infantryContainer mobile" v-if="this.mobileStore.mobile">
    <div v-if="(this.userStore.loggedIn === true  && this.infantryGuideStore.guideDetail.author === this.userStore.user.nickname) || (this.userStore.loggedIn === true  && this.userStore.user.role === 'admin')">
      <q-card>
        <div style="width: 95%; margin: auto">
          <h1>Upravit návod: </h1>
          <q-form v-on:submit="updateGuide()">
            <q-input
                color="purple" square filled
                v-model="name"
                label="Nadpis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="description"
                autogrow
                label="Krátký popis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="text"
                autogrow
                label="Text návodu"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/><br>
            <q-select
                color="purple" filled
                v-model="selectedImage"
                :options="images"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím pěchotní třídu:']"
                label="Obrázek *" />
            <br>
            <h1>Loadout:</h1>
            <p>(Pokud se Váš návod týka konkrétního playstyle)</p>

            <div>
              <q-input
                  color="purple" square filled
                  v-model="weapon"
                  label="Primární zbraň"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="secondaryWeapon"
                  label="Sekundární zbraň"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="tool"
                  label="Nástroj"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="ability"
                  label="Abilita"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="suit"
                  label="Úprava obleku"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="grenade"
                  label="Granát"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="utility"
                  label="Utilita"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="melee"
                  label="Zbraň na blízko"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="implant"
                  label="Implantát 1"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="secondImplant"
                  label="Implantát 2"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="tactical"
                  label="Taktický slot"/><br>
            </div>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit návod" flat type="submit" /><br><br>
            <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit návod" flat @click="changeDeleteState()"/>
          </q-form><br>
        </div>
      </q-card>
    </div>


    <q-card>
      <div style="width: 95%; margin: auto">
        <h1>{{this.infantryGuideStore.guideDetail.name}}</h1>
        <p class="articleText">{{this.infantryGuideStore.guideDetail.description}}</p>
        <q-card-section>
          <img v-bind:src="this.infantryGuideStore.guideDetail.image + '.png'" style='width: 100%; height: auto' alt="Obrázek návodu" class="newsPicture"/>
        </q-card-section>
        <b><a class="clickableUser" href="#" @click="getUserDetails(this.infantryGuideStore.guideDetail.author)">{{this.infantryGuideStore.guideDetail.author}}</a></b> {{this.infantryGuideStore.guideDetail.date}}
        <div v-if="this.infantryGuideStore.guideDetail.updateAuthor !== null">
          <b>Update: <a class="clickableUser" href="#" @click="getUserDetails(this.infantryGuideStore.guideDetail.updateAuthor)">{{this.infantryGuideStore.guideDetail.updateAuthor}}</a></b> {{this.infantryGuideStore.guideDetail.updateDate}}
        </div><br>
        <q-separator></q-separator><br>
        <div>
          <b>Třída: </b>{{this.infantryGuideStore.guideDetail.infantry}}<br><br>
          <b>Loadout:</b><br><br>
          <div v-if="this.infantryGuideStore.guideDetail.weapon !== null">
            <b>Zbraň: </b>{{this.infantryGuideStore.guideDetail.weapon}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.secondaryWeapon !== null">
            <b>Sekundární zbraň: </b>{{this.infantryGuideStore.guideDetail.secondaryWeapon}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.tool !== null">
            <b>Nástroj: </b>{{this.infantryGuideStore.guideDetail.tool}}<br>
          </div>

          <div v-if="this.infantryGuideStore.guideDetail.ability !== null">
            <b>Abilita: </b>{{this.infantryGuideStore.guideDetail.ability}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.suit !== null">
            <b>Úprava obleku: </b>{{this.infantryGuideStore.guideDetail.suit}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.grenade !== null">
            <b>Granát: </b>{{this.infantryGuideStore.guideDetail.grenade}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.utility !== null">
            <b>Utilita: </b>{{this.infantryGuideStore.guideDetail.utility}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.melee !== null">
            <b>Zbraň na blízko: </b>{{this.infantryGuideStore.guideDetail.melee}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.implant !== null">
            <b>Implantát 1: </b>{{this.infantryGuideStore.guideDetail.implant}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.secondImplant !== null">
            <b>Implantát 2: </b>{{this.infantryGuideStore.guideDetail.secondImplant}}<br>
          </div>
          <div v-if="this.infantryGuideStore.guideDetail.tactical !== null">
            <b>Taktický slot: </b>{{this.infantryGuideStore.guideDetail.tactical}}<br>
          </div>
        </div><br><br>
        <q-separator></q-separator><br><br>
        <p class="articleText">{{this.infantryGuideStore.guideDetail.text}}</p><br>
      </div>
    </q-card><br><br>
 </div>




  <q-dialog v-model="this.deleting">
    <q-card>
      <q-card-section>
        <div class="text-h6">Odstranění návodu</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        Jste si jisti, že chcete odstranit tento návod?
      </q-card-section>
      <q-card-actions>
        <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" @click="this.deleteInfantryGuide()"  />
        <q-space />
        <q-btn flat label="Zrušit" color="purple" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {useMobileStore} from "@/stores/MobileStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";
import {useInfantryGuideStore} from "@/stores/InfantryGuideStore";

export default {
  name: "infantryDetailView",

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAccountDetailStore),
    ...mapStores(useInfantryGuideStore)
  },

  setup(){
    return {
      images: [
        'Infiltrator VS', 'Infiltrator TR', 'Infiltrator NC', 'Infiltrator NS',
        'Light Assault VS', 'Light Assault TR', 'Light Assault NC', 'Light Assault NS',
        'Heavy VS', 'Heavy TR', 'Heavy NC', 'Heavy NS',
        'Medic VS', 'Medic TR', 'Medic NC', 'Medic NS',
        'Engineer VS', 'Engineer TR', 'Engineer NC', 'Engineer NS',
        'MAX VS', 'MAX TR', 'MAX NC', 'Defector',
      ],
    }
  },

  created(){
    this.weapon = this.infantryGuideStore.guideDetail.weapon;
    this.text = this.infantryGuideStore.guideDetail.text;
    this.name = this.infantryGuideStore.guideDetail.name;
    this.description = this.infantryGuideStore.guideDetail.description;
    this.secondaryWeapon = this.infantryGuideStore.guideDetail.secondaryWeapon;
    this.tool = this.infantryGuideStore.guideDetail.tool;
    this.ability = this.infantryGuideStore.guideDetail.ability;
    this.suit = this.infantryGuideStore.guideDetail.suit;
    this.grenade = this.infantryGuideStore.guideDetail.grenade;
    this.utility = this.infantryGuideStore.guideDetail.utility;
    this.melee = this.infantryGuideStore.guideDetail.melee;
    this.implant = this.infantryGuideStore.guideDetail.implant;
    this.secondImplant = this.infantryGuideStore.guideDetail.secondImplant;
    this.tactical = this.infantryGuideStore.guideDetail.tactical;
    this.selectedImage = this.infantryGuideStore.guideDetail.image;

  },

  data(){
    return {
      weapon: null,
      secondaryWeapon: null,
      tool: null,
      ability: null,
      suit: null,
      grenade: null,
      utility: null,
      melee: null,
      implant: null,
      secondImplant: null,
      tactical: null,
      name: null,
      description: null,
      text: null,
      deleting: false,
      image: null,
      selectedImage: null,
    }
  },

  methods: {
    async getUserDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async updateGuide(){
      try {
        await this.infantryGuideStore.updateGuide(this.userStore.user.nickname, this.name, this.description, this.text, this.weapon,
            this.secondaryWeapon, this.tool, this.ability, this.suit, this.grenade, this.utility, this.melee, this.implant,
            this.secondImplant, this.tactical, this.selectedImage);
        await this.infantryGuideStore.getGuideDetail(this.infantryGuideStore.guideDetail._id);
        this.$router.push({name: 'infantryDetail'});
        this.error = false;
      } catch{
        this.error = true;
      }
    },

    async deleteInfantryGuide(){
      try {
        await this.infantryGuideStore.deleteGuide(this.userStore.user.nickname);
        await this.infantryGuideStore.getGuides();
        this.$router.push({name: 'infantry'});
        this.error = false;
      }
      catch {
        this.error = true;
      }
    },

    changeDeleteState(){
      if (this.deleting === false){
        this.deleting = true;
      } else {
        this.deleting = false;
      }
    },

  }
}
</script>

<style scoped>

.infantryContainer {
  width: 50%;
  margin: auto;
}

.infantryContainer h1{
  font-size: 25px;
}

.mobile {
  width: 90%;
}

.all-page-button {
  width: 100%;
}

.clickableUser {
  color: purple;
  text-decoration: none;
  font-size: 15px;
}

.clickableUser:hover {
  color: #008080;
}

.articleText {
  white-space: pre-wrap;
}
</style>