import { createRouter, createWebHashHistory } from 'vue-router'
import {useUserStore} from "../stores/UserStore";
import HomeView from '../components/HomeView.vue'
import AboutView from '../components/AboutView.vue'
import DiscussionView from "../components/DiscussionView"
import VehiclesView from "../components/VehiclesView"
import InfantryView from "../components/InfantryView"
import StrategicView from "../components/StrategicView"
import LoginView from "../components/LoginView";
import RegisterView from "../components/RegisterView";
import RestorePassView from "../components/RestorePassView";
import AccountView from "@/components/AccountView";
import AdminView from "@/components/AdminView";
import registeredUsersView from "@/components/RegisteredUsersView";
import memberView from "@/components/MemberView";
import applicantView from "@/components/ApplicantView";
import codesView from "@/components/CodesView";
import applicantDetailView from "@/components/ApplicantDetailView";
import plannedActionsView from "@/components/PlannedActionsView";
import adminViewApplicantsView from "@/components/AdminViewApplicantsView";
import adminViewCompletedView from "@/components/AdminViewCompletedView";
import userDetailsView from "@/components/UserDetailsView";
import actionDetailView from "@/components/ActionDetailView";
import onlineMobileView from "@/components/OnlineMobileView";
import disscussionDetailView from "@/components/disscussionDetailView";
import openReportsView from "@/components/OpenReportsView";
import completedReportsView from "@/components/CompletedReportsView";
import usefulLinksView from "@/components/UsefulLinksView";
import vehicleDetailView from "@/components/VehicleDetailView";
import infantryDetailView from "@/components/InfantryDetailView";
import strategicDetailView from "@/components/strategicDetailView";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView
    },
    {
      path: '/discussion',
      name: 'discussion',
      component: DiscussionView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/vehicle_guide',
      name: 'vehicles',
      component: VehiclesView
    },
    {
      path: '/infantry_guide',
      name: 'infantry',
      component: InfantryView
    },
    {
      path: '/strategic_guide',
      name: 'strategic',
      component: StrategicView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      beforeEnter: checkAuthenticationReverse,
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView
    },
    {
      path: '/restore',
      name: 'restore',
      component: RestorePassView
    },
    {
      path: '/account',
      name: 'account',
      component: AccountView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/administration',
      name: 'administration',
      component: AdminView,
      beforeEnter: checkAdmin,
    },
    {
      path: '/registeredUsers',
      name: 'registeredUsers',
      component: registeredUsersView,
      beforeEnter: checkAdmin,
    },
    {
      path: '/membership',
      name: 'membership',
      component: memberView,
    },
    {
      path: '/applicant',
      name: 'applicant',
      component: applicantView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/codes',
      name: 'codes',
      component: codesView,
      beforeEnter: checkAdmin,
    },
    {
      path: '/applicantDetail',
      name: 'applicantDetail',
      component: applicantDetailView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/plannedActions',
      name: 'plannedActions',
      component: plannedActionsView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/viewApplicants',
      name: 'viewApplicants',
      component: adminViewApplicantsView,
      beforeEnter: checkAdmin,
    },
    {
      path: '/viewCompletedApplications',
      name: 'viewCompletedApplications',
      component: adminViewCompletedView,
      beforeEnter: checkAdmin,
    },
    {
      path : '/userDetail',
      name: 'userDetail',
      component: userDetailsView,
    },
    {
      path: '/actionDetail',
      name: 'actionDetail',
      component: actionDetailView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/onlineMobile',
      name: 'onlineMobile',
      component: onlineMobileView
    },
    {
      path: '/themeDetail',
      name: 'themeDetail',
      component: disscussionDetailView,
      beforeEnter: checkAuthentication,
    },
    {
      path: '/viewReports',
      name: 'viewReports',
      component: openReportsView,
      beforeEnter: checkAdmin,
    },
    {
      path: '/viewCompletedReports',
      name: 'viewCompletedReports',
      component: completedReportsView,
      beforeEnter: checkAdmin,
    },
    {
      path: '/usefulLinks',
      name: 'usefulLinks',
      component: usefulLinksView
    },
    {
      path: '/vehicleDetail',
      name: 'vehicleDetail',
      component: vehicleDetailView
    },
    {
      path: '/infantryDetail',
      name: 'infantryDetail',
      component: infantryDetailView
    },
    {
      path: '/strategicDetail',
      name: 'strategicDetail',
      component: strategicDetailView
    }
  ]
})

function checkAuthentication(to, from, next) {
  const store = useUserStore();
  if (store.loggedIn === true) {
    return next();
  } else {
    next({name: 'login'})
  }
}

function checkAuthenticationReverse(to, from, next) {
  const store = useUserStore();
  if (store.loggedIn === false) {
    return next();
  } else {
    next({name: 'account'})
  }
}

function checkAdmin(to, from, next){
  const store = useUserStore();
  if (store.loggedIn === false || store.user.role !== "admin") {
    next({name: 'account'});
  } else {
    return next();
  }
}

export default router
