import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useNewsStore = defineStore('news', {
    state: () => ({
        news: [],
        length: null,
        test: 'Hello there',
        error: null,
    }),

    actions: {

        async postNews(admin, name, text, pic){
            try {
                await axios.post(config.backendUrl + '/addNews', {
                    author: admin,
                    postName: name,
                    text: text,
                    picture: pic,
                    date: '',
                    updateAuthor: null,
                    updateDate: null,
                })
                await this.getNews();
                this.error = null;
            } catch {
                this.error = 'Chyba při postování updatu.';
            }
        },

        async getNews(){
          try {
              const response = await axios.get(config.backendUrl + '/getNews');
              this.news = response.data.reverse();
          } catch {
              this.error = "Chyba při načítání novinek!";
          }
        },

        async deleteNews(admin, id){
            try {
                await axios.post(config.backendUrl + '/deleteNews', {
                   id: id,
                   admin: admin
                });
                await this.getNews();
            } catch {
                this.error = "Chyba při odstranění novinky!"
            }
        },

        async updateNews(id, name, text, picture, nickname){
            try {
                await axios.post(config.backendUrl + '/updateNews', {
                    id: id,
                    name: name,
                    text: text,
                    picture:picture,
                    nickname: nickname,
                    date: '',
                });
                await this.getNews();
            } catch {
                this.error = "Chyba při updatu novinky!"
            }
        },
    }
})