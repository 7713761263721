<template>
  <div class="infantryContainer" v-if="!this.mobileStore.mobile">
    <div v-if="(this.userStore.loggedIn === true  && this.strategicGuideStore.guideDetail.author === this.userStore.user.nickname) || (this.userStore.loggedIn === true  && this.userStore.user.role === 'admin')">
      <q-card>
        <div style="width: 95%; margin: auto">
          <h1>Upravit návod: </h1>
          <q-form v-on:submit="updateGuide()">
            <q-input
                color="purple" square filled
                v-model="name"
                label="Nadpis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="description"
                autogrow
                label="Krátký popis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="text"
                autogrow
                label="Text návodu"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/><br>
            <q-select
                color="purple" filled
                v-model="selectedImage"
                :options="images"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím pěchotní třídu:']"
                label="Obrázek *" />
            <br>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit návod" flat type="submit" /><br><br>
            <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit návod" flat @click="changeDeleteState()"/>
          </q-form><br>
        </div>
      </q-card>
    </div>


    <q-card>
      <div style="width: 95%; margin: auto">
        <h1>{{this.strategicGuideStore.guideDetail.name}}</h1>
        <p class="articleText">{{this.strategicGuideStore.guideDetail.description}}</p>
        <q-card-section>
          <img v-bind:src="this.strategicGuideStore.guideDetail.image + '.png'" style='width: 100%; height: auto' alt="Obrázek návodu" class="newsPicture"/>
        </q-card-section>
        <b><a class="clickableUser" href="#" @click="getUserDetails(this.strategicGuideStore.guideDetail.author)">{{this.strategicGuideStore.guideDetail.author}}</a></b> {{this.strategicGuideStore.guideDetail.date}}
        <div v-if="this.strategicGuideStore.guideDetail.updateAuthor !== null">
          <b>Update: <a class="clickableUser" href="#" @click="getUserDetails(this.strategicGuideStore.guideDetail.updateAuthor)">{{this.strategicGuideStore.guideDetail.updateAuthor}}</a></b> {{this.strategicGuideStore.guideDetail.updateDate}}
        </div><br>
        <q-separator></q-separator><br><br>
        <p class="articleText">{{this.strategicGuideStore.guideDetail.text}}</p><br>
      </div>
    </q-card><br><br>

  </div>



  <div class="infantryContainer mobile" v-if="this.mobileStore.mobile">
    <div v-if="(this.userStore.loggedIn === true  && this.strategicGuideStore.guideDetail.author === this.userStore.user.nickname) || (this.userStore.loggedIn === true  && this.userStore.user.role === 'admin')">
      <q-card>
        <div style="width: 95%; margin: auto">
          <h1>Upravit návod: </h1>
          <q-form v-on:submit="updateGuide()">
            <q-input
                color="purple" square filled
                v-model="name"
                label="Nadpis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="description"
                autogrow
                label="Krátký popis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="text"
                autogrow
                label="Text návodu"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/><br>
            <q-select
                color="purple" filled
                v-model="selectedImage"
                :options="images"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím pěchotní třídu:']"
                label="Obrázek *" />
            <br>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit návod" flat type="submit" /><br><br>
            <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit návod" flat @click="changeDeleteState()"/>
          </q-form><br>
        </div>
      </q-card>
    </div>


    <q-card>
      <div style="width: 95%; margin: auto">
        <h1>{{this.strategicGuideStore.guideDetail.name}}</h1>
        <p class="articleText">{{this.strategicGuideStore.guideDetail.description}}</p>
        <q-card-section>
          <img v-bind:src="this.strategicGuideStore.guideDetail.image + '.png'" style='width: 100%; height: auto' alt="Obrázek návodu" class="newsPicture"/>
        </q-card-section>
        <b><a class="clickableUser" href="#" @click="getUserDetails(this.strategicGuideStore.guideDetail.author)">{{this.strategicGuideStore.guideDetail.author}}</a></b> {{this.strategicGuideStore.guideDetail.date}}
        <div v-if="this.strategicGuideStore.guideDetail.updateAuthor !== null">
          <b>Update: <a class="clickableUser" href="#" @click="getUserDetails(this.strategicGuideStore.guideDetail.updateAuthor)">{{this.strategicGuideStore.guideDetail.updateAuthor}}</a></b> {{this.strategicGuideStore.guideDetail.updateDate}}
        </div><br>
        <q-separator></q-separator><br><br>
        <p class="articleText">{{this.strategicGuideStore.guideDetail.text}}</p><br>
      </div>
    </q-card><br><br>
  </div>




  <q-dialog v-model="this.deleting">
    <q-card>
      <q-card-section>
        <div class="text-h6">Odstranění návodu</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        Jste si jisti, že chcete odstranit tento návod?
      </q-card-section>
      <q-card-actions>
        <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" @click="this.deleteStrategicGuide()"  />
        <q-space />
        <q-btn flat label="Zrušit" color="purple" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {useMobileStore} from "@/stores/MobileStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";
import {useStrategicGuideStore} from "@/stores/StrategicGuideStore";

export default {
  name: "infantryDetailView",

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAccountDetailStore),
    ...mapStores(useStrategicGuideStore)
  },

  setup(){
    return {
      images: [
        'Cortium', 'Pozadi VS', 'Pozadi TR', 'Pozadi NC', 'Pozadi NS', 'Pozadi Indar', 'Pozadi Esamir', 'Pozadi Amerish',
        'Pozadi Hossin', 'Pozadi Oshur'
      ],
    }
  },

  created(){
    this.weapon = this.strategicGuideStore.guideDetail.weapon;
    this.text = this.strategicGuideStore.guideDetail.text;
    this.name = this.strategicGuideStore.guideDetail.name;
    this.description = this.strategicGuideStore.guideDetail.description;
    this.selectedImage = this.strategicGuideStore.guideDetail.image;

  },

  data(){
    return {
      name: null,
      description: null,
      text: null,
      deleting: false,
      image: null,
      selectedImage: null,
    }
  },

  methods: {
    async getUserDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async updateGuide(){
      try {
        await this.strategicGuideStore.updateGuide(this.userStore.user.nickname, this.name, this.description, this.text, this.selectedImage);
        await this.strategicGuideStore.getGuideDetail(this.strategicGuideStore.guideDetail._id);
        this.$router.push({name: 'strategicDetail'});
        this.error = false;
      } catch{
        this.error = true;
      }
    },

    async deleteStrategicGuide(){
      try {
        await this.strategicGuideStore.deleteGuide(this.userStore.user.nickname);
        await this.strategicGuideStore.getGuides();
        this.$router.push({name: 'strategic'});
        this.error = false;
      }
      catch {
        this.error = true;
      }
    },

    changeDeleteState(){
      if (this.deleting === false){
        this.deleting = true;
      } else {
        this.deleting = false;
      }
    },

  }
}
</script>

<style scoped>

  .infantryContainer {
    width: 50%;
    margin: auto;
  }

  .infantryContainer h1{
    font-size: 25px;
  }

  .mobile {
    width: 90%;
  }

  .all-page-button {
    width: 100%;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .articleText {
    white-space: pre-wrap;
  }
</style>