<template>
  <div v-if="!this.mobileStore.mobile">
    <div class="applicationDetailContainer">
      <q-card>
        <h1>Přihláška číslo: {{this.applicantStore.detail._id}}</h1>
        <p v-if="this.userStore.user.role === 'admin'"><b>Přezdívka uchazeče: </b>{{this.applicantStore.detail.nickname}}</p>
        <p><b>Datum podání přihlášky: </b>{{this.applicantStore.detail.date}}</p>
        <p v-if="this.applicantStore.detail.vsChar !== null"><b>Jméno VS postavy: </b>{{this.applicantStore.detail.vsChar}}</p>
        <p v-if="this.applicantStore.detail.trChar !== null"><b>Jméno TR postavy: </b>{{this.applicantStore.detail.trChar}}</p>
        <p><b>Jak dlouho hrajete Planetside? </b>{{this.applicantStore.detail.howLong}}</p>
        <p><b>Jaký styl hry preferujete? </b>{{this.applicantStore.detail.style}}</p>
        <p><b>Proč právě OLT? </b>{{this.applicantStore.detail.whyUs}}</p>
        <p><b>Chcete nám něco sdělit? </b> {{this.applicantStore.detail.note}}</p>
        <p><b>STATUS: </b> {{this.applicantStore.detail.status}}</p>
        <p class="error" v-if="this.applicantStore.error !== null">{{this.applicantStore.error}}</p>
        <q-btn class="bg-red text-white shadow-2 all-page-button" v-if="this.applicantStore.detail.status === 'Podána' && (this.userStore.user.role === 'user')" align="center" label="Zrušit přihlášku" @click="cancelApplication()" />
        <br>
      </q-card><br>

      <div class="changeState" v-if="this.applicantStore.detail.status === 'Podána' && this.userStore.user.role === 'admin' || this.applicantStore.detail.status === 'Vyřizuje se' && this.userStore.user.role === 'admin'">
        <q-form v-on:submit="updateStatus()">
          <q-select
              color="purple" filled
              v-model="selectedState"
              :options="state"
              :rules="[ val => val && val.length > 0 || 'Vyberte prosím nějaký stav']"
              label="Změnit stav žádosti" />
          <br>
          <q-btn class="bg-purple text-white shadow-2 button all-page-button" align="center" label="Upravit stav žádosti" flat type="submit" /><br>
        </q-form>
      </div>
    </div>


    <div class="comments" v-if="this.applicantStore.detail.status === 'Podána' || this.applicantStore.detail.status === 'Vyřizuje se'">
      <h1 class="comments">Přidat komentář</h1>
      <q-input
          label="Text Komentáře:"
          color="purple"
          v-model="text"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Přidat komentář" flat @click="postComment()"/><br><br>
    </div>

    <div class="comments">
      <h1 class="comments">Komentáře</h1>
      <p class="noComment" v-if="this.applicantStore.comments.length === 0"><b>Žádné komentáře</b></p>


      <div v-for="(comments) in getData()" :key="comments._id" id="applicationsShowed">
        <q-card>
          <q-card-section>
            <p><b><a class="clickableUser" href="#" @click="getDetails(comments.nickname)">{{comments.nickname}}</a></b> {{comments.date}}</p>
            <p v-if="comments.updateDate !== null"><b>Upraveno: </b>{{comments.updateDate}}</p>
            <p v-if="comments.user[0].role === 'admin'"><b>Velitel outfitu</b></p>
          </q-card-section>
          <q-separator />
          <q-card-section horizontal>
            <img v-bind:src="comments.user[0].profilePicture" alt="Obrázek novinky" class="newsPicture"/>
            <q-card-section>
              <p>{{comments.text}}</p>
            </q-card-section>
          </q-card-section><br>
        </q-card><br>
        <div v-if="comments.nickname === this.userStore.user.nickname">
          <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Upravit komentář" flat @click="changeUpdatingStatus(comments._id, comments.text)"/><br><br>
        </div>
        <div v-if="comments.nickname === this.userStore.user.nickname || this.userStore.user.role === 'admin'">
          <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit komentář" flat @click="getDeleteID(comments._id)"/><br><br>
        </div>
      </div>

      <q-dialog v-model="this.deleteDialogue">
        <q-card class="deleteAccountDialogue">
          <q-card-section>
            <div class="text-h6">Odstranění komentáře</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            Jste si jisti, že chcete odstranit komentář?
          </q-card-section>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteComment(this.deleteId)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>


  <div class="mobileContainer" v-if="this.mobileStore.mobile">
    <div class="applicationDetailContainer mobile">
      <q-card>
        <h1>Přihláška číslo: {{this.applicantStore.detail._id}}</h1>
        <p v-if="this.userStore.user.role === 'admin'"><b>Přezdívka uchazeče: </b>{{this.applicantStore.detail.nickname}}</p>
        <p><b>Datum podání přihlášky: </b>{{this.applicantStore.detail.date}}</p>
        <p v-if="this.applicantStore.detail.vsChar !== null"><b>Jméno VS postavy: </b>{{this.applicantStore.detail.vsChar}}</p>
        <p v-if="this.applicantStore.detail.trChar !== null"><b>Jméno TR postavy: </b>{{this.applicantStore.detail.trChar}}</p>
        <p><b>Jak dlouho hrajete Planetside? </b>{{this.applicantStore.detail.howLong}}</p>
        <p><b>Jaký styl hry preferujete? </b>{{this.applicantStore.detail.style}}</p>
        <p><b>Proč právě OLT? </b>{{this.applicantStore.detail.whyUs}}</p>
        <p><b>Chcete nám něco sdělit? </b> {{this.applicantStore.detail.note}}</p>
        <p><b>STATUS: </b> {{this.applicantStore.detail.status}}</p>
        <p class="error" v-if="this.applicantStore.error !== null">{{this.applicantStore.error}}</p>
        <q-btn class="bg-red text-white shadow-2 all-page-button" v-if="this.applicantStore.detail.status === 'Podána' && (this.userStore.user.role === 'user')" align="center" label="Zrušit přihlášku" @click="cancelApplication()" />
        <br>
      </q-card><br>

      <div class="changeState" v-if="this.applicantStore.detail.status === 'Podána' && this.userStore.user.role === 'admin' || this.applicantStore.detail.status === 'Vyřizuje se' && this.userStore.user.role === 'admin'">
        <q-form v-on:submit="updateStatus()">
          <q-select
              color="purple" filled
              v-model="selectedState"
              :options="state"
              :rules="[ val => val && val.length > 0 || 'Vyberte prosím nějaký stav']"
              label="Změnit stav žádosti" />
          <br>
          <q-btn class="bg-purple text-white shadow-2 button all-page-button" align="center" label="Upravit stav žádosti" flat type="submit" /><br>
        </q-form>
      </div>
    </div>


    <div class="comments mobile" v-if="this.applicantStore.detail.status === 'Podána' || this.applicantStore.detail.status === 'Vyřizuje se'">
      <h1 class="comments">Přidat komentář</h1>
      <q-input
          label="Text Komentáře:"
          color="purple"
          v-model="text"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Přidat komentář" flat @click="postComment()"/><br><br>
    </div>

    <div class="comments mobile">
      <h1 class="comments">Komentáře</h1>
      <p class="noComment" v-if="this.applicantStore.comments.length === 0"><b>Žádné komentáře</b></p>


      <div class="comments mobile" v-for="(comments) in getData()" :key="comments._id" id="applicationsShowedMobile">
        <q-card class="newsCard">
          <q-card-section>
            <p><b><a class="clickableUser" href="#" @click="getDetails(comments.nickname)">{{comments.nickname}}</a></b> {{comments.date}}</p>
            <p v-if="comments.updateDate !== null"><b>Upraveno: </b>{{comments.updateDate}}</p>
            <p v-if="comments.user[0].role === 'admin'"><b>Velitel outfitu</b></p>
          </q-card-section>
          <q-separator />
          <q-card-section horizontal>
            <img v-bind:src="comments.user[0].profilePicture" alt="Obrázek novinky" class="newsPicture"/>
            <q-card-section>
              <p>{{comments.text}}</p>
            </q-card-section>
          </q-card-section><br>
        </q-card><br>
        <div v-if="comments.nickname === this.userStore.user.nickname">
          <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Upravit komentář" flat @click="changeUpdatingStatus(comments._id, comments.text)"/><br><br>
        </div>
        <div v-if="comments.nickname === this.userStore.user.nickname || this.userStore.user.role === 'admin'">
          <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit komentář" flat @click="getDeleteID(comments._id)"/><br><br>
        </div>
      </div>
      </div>
  </div>

    <q-dialog v-model="this.updating">
      <q-card style="width: 100%">
      <div style="width: 90%; margin: auto">
        <h1 style="font-size: 25px; font-weight: bold;margin: 20px 0px 20px 0px">Upravit komentář</h1>
      <q-input
          label="Text Komentáře:"
          color="purple"
          v-model="updateText"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
        <q-card-actions>
        <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Upravit komentář" flat @click="updateComment()"/><br><br>
        <q-space />
        <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </div>
      </q-card>
    </q-dialog>

      <q-dialog v-model="this.deleteDialogue">
        <q-card class="deleteAccountDialogue">
          <q-card-section>
            <div class="text-h6">Odstranění komentáře</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            Jste si jisti, že chcete odstranit komentář?
          </q-card-section>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteComment(this.deleteId)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useApplicantStore} from "@/stores/ApplicantStore";
import {useUserStore} from "@/stores/UserStore";
import {useMobileStore} from "../stores/MobileStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";

export default {
  name: "ApplicantDetailView",
  setup(){
    return {
      state: [
        'Vyřizuje se', 'Přijata', 'Zamítnuta',
      ],
    }
  },

  data(){
    return {
      selectedState: null,
      text: null,
      updateText: null,
      updating: false,
      deleteDialogue: false,
      deleteName: null,
      deleteId: null,
      updateId: null,
    }
  },

  async created(){
    await this.applicantStore.loadComments();
  },

  computed: {
    ...mapStores(useApplicantStore),
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAccountDetailStore)
  },

  methods: {
    async cancelApplication(){
      try {
        await this.applicantStore.cancelApplication(this.userStore.user.nickname);
        if (this.applicantStore.error === null){
          await this.$router.push({name: 'applicant'});
        }
        this.error = null;
      } catch {
        this.error = true;
      }
    },

    async postComment(){
      try {
        await this.applicantStore.comment(this.userStore.user.nickname, this.text);
        this.getData();
        this.error = null;
      } catch {
        this.error = true;
      }
    },

    async updateStatus(){
      try {
        await this.applicantStore.updateStatus(this.userStore.user.nickname, this.selectedState);
        this.error = null;
      } catch {
        this.error = true;
      }
    },

    getData() {
      window.scroll(0, 0);
      return this.applicantStore.comments;
    },

    async deleteComment(){
      try {
        await this.applicantStore.deleteComment(this.userStore.user.nickname, this.deleteId);
        this.getData();
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    getDeleteID(id){
      this.deleteId = id;
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

    changeUpdatingStatus(id, text){
      this.updateId = id;
      this.updateText = text
      if (this.updating) {
        this.updating = false;
      } else {
        this.updating = true;
      }
    },

    async updateComment(){
      try {
        await this.applicantStore.updateComment(this.userStore.user.nickname, this.updateText, this.updateId);
        this.getData();
        this.updating = false;
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async getDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    }
  },
}
</script>

<style scoped>
  .applicationDetailContainer {
    width: 50%;
    margin: auto;
  }

  .applicationDetailContainer h1 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 20px 20px 20px;
  }

  .applicationDetailContainer p {
    font-size: 20px;
    margin: 20px 20px 20px 20px;
  }

  .all-page-button {
    width: 100%;
  }

  .error {
    color: darkred;
  }

  .comments {
    width: 50%;
    margin: auto;
  }

  .comments h1 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0px 20px 0px;
  }

  .noComment {
    font-size: 20px;
    text-align: center;
  }

  .newsPicture {
    height: 150px;
    margin: 10px 10px 10px 10px;
  }

  .newsCard {
    width: 100%;
  }

  .updatingComment {
    width: 700px;
  }

  .updatingComment h1 {
    margin: 10px 10px 10px 10px;
  }

  .updatingTextField {
    width: 98%;
  }

  .mobile {
    width: 100%;
  }

  .mobileContainer{
    margin: auto;
    width: 90%;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }
</style>