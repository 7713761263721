<template>
  <div class="fixed-center restoreForm" v-if="!this.mobileStore.mobile">
    <q-form v-on:submit="restore()">
      <p class="restoreText">Obnova hesla:</p>
      <q-input
          color="purple" square filled
          v-model="nickname"
          label="Přezdívka *"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím svou přezdívku']"/><br>
      <q-input
          color="purple" square filled
          v-model="answeredQuestion"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím odpověď na bezpečnostní otázku']"
          label="Odpověď na bezpečnostní otázku *" required/>
      <br>
      <q-input
          color="purple" square filled
          v-model="password"
          :rules="[ val => val && val.length > 7 || 'Zadejte prosím nové heslo o délce alespoň 8 znaků']"
          v-on:change="testPasswords()"
          type="password"
          label="Zadejte prosím nové heslo *" />
      <br>
      <q-input
          color="purple" square filled
          v-model="passwordCheck"
          v-on:change="testPasswords()"
          error-message="Hesla se neshodují!"
          :error="!passwordsMatch"
          type="password"
          label="Potvrďte prosím nové heslo *" />
      <br>
      <p v-if="this.success" class="notice">Heslo bylo úspěšně změněno.</p>
      <q-btn class="bg-purple text-white button" align="center" label="Obnovit heslo" flat type="submit" />
    </q-form>
  </div>

  <div class="fixed-center restoreForm mobile" v-if="this.mobileStore.mobile">
    <q-form v-on:submit="restore()">
      <p class="restoreText">Obnova hesla:</p>
      <q-input
          color="purple" square filled
          v-model="nickname"
          label="Přezdívka *"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím svou přezdívku']"/><br>
      <q-input
          color="purple" square filled
          v-model="answeredQuestion"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím odpověď na bezpečnostní otázku']"
          label="Odpověď na bezpečnostní otázku *" required/>
      <br>
      <q-input
          color="purple" square filled
          v-model="password"
          :rules="[ val => val && val.length > 7 || 'Zadejte prosím nové heslo o délce alespoň 8 znaků']"
          v-on:change="testPasswords()"
          type="password"
          label="Zadejte prosím nové heslo *" />
      <br>
      <q-input
          color="purple" square filled
          v-model="passwordCheck"
          v-on:change="testPasswords()"
          error-message="Hesla se neshodují!"
          :error="!passwordsMatch"
          type="password"
          label="Potvrďte prosím nové heslo *" />
      <br>
      <p v-if="this.success" class="notice">Heslo bylo úspěšně změněno.</p>
      <q-btn class="bg-purple text-white button" align="center" label="Obnovit heslo" flat type="submit" />
    </q-form>
  </div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "../stores/UserStore";
import {useMobileStore} from "../stores/MobileStore";
import config from "../config";
import axios from "axios";

export default {
  name: "RestorePassView",
  setup(){
    return {
    }
  },

  data(){
    return {
      selectedSecurityQuestion: '',
      answeredQuestion: '',
      nickname: '',
      password: '',
      passwordCheck: '',
      passwordsMatch: true,
      error: '',
      success: false,
    }
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
  },

  methods: {
    changeLicenceState(){
      if (this.showLicence) {
        this.showLicence = false;
      } else {
        this.showLicence = true;
      }
    },

    testPasswords(){
      if (this.password !== this.passwordCheck) {
        this.passwordsMatch = false;
      } else {
        this.passwordsMatch = true;
      }
    },

    async restore(){
      if (this.passwordsMatch){
        this.error = null;
        try {
          await axios.post(config.backendUrl + '/changePassword', {
            nickname: this.nickname,
            answer: this.answeredQuestion,
            password: this.password
          });
          this.success = true;
          await this.$router.push('login');
        } catch {
          this.error = "Chyba při změně hesla!"
        }
      } else {
        this.error = "Hesla se neshodují!";
      }
    },
  },

}

</script>

<style scoped>
  .restoreText {
    font-size: 20px;
    text-align: center;
  }
  .restoreForm {
    width: 600px;
    margin: auto;
  }

  .button {
    width: 100%;
  }

  .mobile {
    width: 90%;
  }
</style>