import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useReportStore = defineStore('report', {
    state: () => ({
        error: null,
        reportsReply: [],
        reportsTheme: [],
        completedReportsReply: [],
        completedReportsTheme: [],
        replyDetail: null,
        themeDetail: null,
    }),

    actions: {
        async getReplyReports(nickname) {
            try {
                const results = await axios.post(config.backendUrl + '/getReplyReports', {
                    nickname: nickname,
                });
                this.reportsReply = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getThemeReports(nickname) {
            try {
                const results = await axios.post(config.backendUrl + '/getThemeReports', {
                    nickname: nickname,
                });
                this.reportsTheme = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getCompletedReplyReports(nickname) {
            try {
                const results = await axios.post(config.backendUrl + '/getCompletedReplyReports', {
                    nickname: nickname,
                });
                this.completedReportsReply = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getCompletedThemeReports(nickname) {
            try {
                const results = await axios.post(config.backendUrl + '/getCompletedThemeReports', {
                    nickname: nickname,
                });
                this.completedReportsTheme = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getReplyDetail(nickname, id){
            try{
                const results = await axios.post(config.backendUrl + '/getReportReplyDetail', {
                    nickname: nickname,
                    id: id
                });
                this.replyDetail = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getThemeDetail(nickname, id){
            try{
                const results = await axios.post(config.backendUrl + '/getReportThemeDetail', {
                    nickname: nickname,
                    id: id
                });
                this.themeDetail = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },


        async changeReportReplyState(nickname, id, state){
            try {
                await axios.post(config.backendUrl + '/changeReportReplyState', {
                    nickname: nickname,
                    id: id,
                    state: state,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async changeReportThemeState(nickname, id, state){
            try {
                await axios.post(config.backendUrl + '/changeReportThemeState', {
                    nickname: nickname,
                    id: id,
                    state: state,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

    }
})