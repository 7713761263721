<template>
 <div class="adminContainer" v-if="!this.mobileStore.mobile">
    <h1>Vítejte v administrační části OLT!</h1>
   <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit registrované uživatele" @click="getUsers"/>
   <br><br>
   <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit otevřené žádosti o členství" @click="getOpenApplications()"/>
   <br><br>
   <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit vyřízené žádosti o členství" @click="getCompletedApplications()"/>
   <br><br>
   <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit ověřovací kódy" :to="{name: 'codes'}"/>
   <br><br>
   <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit reportované příspěvky" @click="getReports()"/>
   <br><br>
   <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit vyřízené reporty" @click="getCompletedReports()"/>
   <br><br>
   <img src="../images/OLT-Logo.png" alt="OLT Logo" class="OLT-Logo-Administration">
 </div>

  <div class="adminContainer mobile" v-if="this.mobileStore.mobile">
    <h1>Vítejte v administrační části OLT!</h1>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit registrované uživatele" @click="getUsers"/>
    <br><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit otevřené žádosti o členství" @click="getOpenApplications()"/>
    <br><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit vyřízené žádosti o členství" @click="getCompletedApplications()"/>
    <br><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit ověřovací kódy" :to="{name: 'codes'}"/>
    <br><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit reportované příspěvky" @click="getReports()"/>
    <br><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zobrazit vyřízené reporty" @click="getCompletedReports()"/>
    <br><br>
    <img src="../images/OLT-Logo.png" alt="OLT Logo" class="OLT-Logo-Administration mobile">
  </div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useRegisteredUsersStore} from "@/stores/RegisteredUsersStore";
import {useUserStore} from "@/stores/UserStore";
import {useApplicantStore} from "@/stores/ApplicantStore";
import {useMobileStore} from "../stores/MobileStore";
import {useReportStore} from "@/stores/ReportsStore";


export default {
  name: "AdminView",

  computed: {
  ...mapStores(useRegisteredUsersStore),
  ...mapStores(useUserStore),
  ...mapStores(useApplicantStore),
  ...mapStores(useMobileStore),
  ...mapStores(useReportStore),
  },

  data(){
    return {
      error: null,
    }
  },

  methods: {
    async getUsers() {
      try {
        await this.registeredStore.getAllUsers(this.userStore.user.nickname);
        this.$router.push('registeredUsers');
        this.error = null;
      } catch {
        this.error = 'Chyba při načítání uživatelů!'
      }
    },

    async getOpenApplications(){
      try {
        await this.applicantStore.getOpenApplications(this.userStore.user.nickname);
        this.$router.push('viewApplicants');
        this.error = null;
      } catch {
        this.error = 'Chyba při načítaní aplikantů.';
      }
    },

    async getCompletedApplications(){
      try {
        await this.applicantStore.getCompletedApplications(this.userStore.user.nickname);
        this.$router.push('viewCompletedApplications');
        this.error = null;
      } catch {
        this.error = 'Chyba při načítaní aplikantů.';
      }
    },

    async getReports(){
      try {
        await this.reportStore.getReplyReports(this.userStore.user.nickname);
        await this.reportStore.getThemeReports(this.userStore.user.nickname);
        this.$router.push('viewReports');
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async getCompletedReports(){
      try {
        await this.reportStore.getCompletedReplyReports(this.userStore.user.nickname);
        await this.reportStore.getCompletedThemeReports(this.userStore.user.nickname);
        this.$router.push('viewCompletedReports');
        this.error = false;
      } catch {
        this.error = true;
      }
    },

  },
}

</script>

<style scoped>
  h1{
    font-weight: bold;
    font-size: 25px;
    text-align: center;
  }

  .adminContainer {
    width: 600px;
    margin: auto;
  }
  .all-page-button {
    width: 100%;
  }

  .OLT-Logo-Administration {
    max-width: 600px;
    margin: auto;
  }

  .mobile {
    width: 90%;
  }
</style>