<template>
  <div class="userDetail">
    <q-card class="my-card col" flat bordered v-if="!this.mobileStore.mobile">
      <q-card-section horizontal class="full-width">
        <q-card-section style="align-self: center">
          <h2 class="nickname">{{ this.accountDetailStore.user.nickname }}</h2>
          <div class="aboutText" v-if="this.accountDetailStore.user.role === 'admin'">
            <b>Role: </b> Velitel outfitu<br><br>
          </div>

          <div class="aboutText" v-if="this.accountDetailStore.user.role === 'member'">
            <b>Role: </b> Člen komunity OLT<br><br>
          </div>

          <div class="aboutText" v-if="this.accountDetailStore.user.role === 'user'">
            <b>Role: </b> Registrovaný uživatel<br><br>
          </div>

          <div class="aboutText">
            <b>O mně:</b><br><br>
            {{this.accountDetailStore.user.aboutMe}}
          </div><br>

          <div>
            <q-avatar>
              <img src="VS.png" alt="Vanu Sovereignty Logo">
            </q-avatar >
            <b>{{ this.accountDetailStore.user.VS }}</b><br>
          </div>

          <div>
            <q-avatar>
              <img src="TR.png" alt="Terran Republic Logo">
            </q-avatar>
            <b>{{ this.accountDetailStore.user.TR }}</b><br>
          </div>

          <div>
            <q-avatar>
              <img src="NC.png" alt="New Conglomerate Logo">
            </q-avatar>
            <b>{{ this.accountDetailStore.user.NC }}</b><br>
          </div>

          <div>
            <q-avatar>
              <img src="NS.png" alt="Nanite Systems Logo">
            </q-avatar>
            <b>{{ this.accountDetailStore.user.NS }}</b><br>
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>

  <div class="userDetail mobile" v-if="this.mobileStore.mobile">
    <q-card class="my-card col" flat bordered>
      <q-card-section horizontal class="full-width">
        <q-card-section style="align-self: center">
          <h2 class="nickname">{{ this.accountDetailStore.user.nickname }}</h2>
          <div class="aboutText" v-if="this.accountDetailStore.user.role === 'admin'">
            <b>Role: </b> Velitel outfitu<br><br>
          </div>

          <div class="aboutText" v-if="this.accountDetailStore.user.role === 'member'">
            <b>Role: </b> Člen komunity OLT<br><br>
          </div>

          <div class="aboutText" v-if="this.accountDetailStore.user.role === 'user'">
            <b>Role: </b> Registrovaný uživatel<br><br>
          </div>

          <div class="aboutText">
            <b>O mně:</b><br><br>
            {{this.accountDetailStore.user.aboutMe}}
          </div><br>

          <div>
            <q-avatar>
              <img src="VS.png" alt="Vanu Sovereignty Logo">
            </q-avatar >
            <b>{{ this.accountDetailStore.user.VS }}</b><br>
          </div>

          <div>
            <q-avatar>
              <img src="TR.png" alt="Terran Republic Logo">
            </q-avatar>
            <b>{{ this.accountDetailStore.user.TR }}</b><br>
          </div>

          <div>
            <q-avatar>
              <img src="NC.png" alt="New Conglomerate Logo">
            </q-avatar>
            <b>{{ this.accountDetailStore.user.NC }}</b><br>
          </div>

          <div>
            <q-avatar>
              <img src="NS.png" alt="Nanite Systems Logo">
            </q-avatar>
            <b>{{ this.accountDetailStore.user.NS }}</b><br>
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import {useAccountDetailStore} from "@/stores/AccountDetailStore";
import {mapStores} from "pinia/dist/pinia";
import {useMobileStore} from "@/stores/MobileStore";


export default {
  name: "UserDetailsView",

  computed: {
    ...mapStores(useAccountDetailStore),
    ...mapStores(useMobileStore)
  },
}
</script>

<style scoped>

.userDetail {
  width: 50%;
  margin: auto;
}

.mobile {
  width: 90%;
  margin: auto;
}

.nickname {
  font-size: 20px;
}

</style>