<template>
 <div class="membershipContainer" v-if="!this.mobileStore.mobile">
    <q-card class="memebrshipCard">
      <h1>Členství v OLT</h1>
      <p>One Llama Team stále hledá nové členy! Ať již jste osvíceným bojovníkem Suverenity Vanu, nebo loajálním vojákem Terranské republiky, v OLT je pro Vás místo!</p>
       <q-separator></q-separator>
       <h2>Jak získat členství?</h2>
       <p>Pro získání členství jsou dvě možnosti.</p>
       <p><b>1.</b> Pokud se Vámi potkáme ve hře a chvíli spolu budeme hrát, je možnost se domluvit osobně a získat pozvánku do outfitu.</p>
       <p><b>2.</b> Pokud se z jakéhokoliv důvodu nepotkáme ve hře, například z důvodu nedostatku času, nebo jste-li novým hráčem a chcete pomoci se začátky ve hře, stačí vyplnit formulář s žádostí o členství.</p>
       <p>Společně s formulářem můžete zároveň zaslat žádost o přijetí přímo ve hře. Pokud žádost ve hře nepošlete, ručně Vám po schválení pošleme pozvánku.</p>
       <p>O postupu žádosti budete informování ve stejné sekci, jako je její podání. Na stejném místě můžete zároveň najít případné upřesňující dotazy od našich velitelů.</p>
       <p><b>Pokud pošlete žádost jen ve hře bez vyplnění dotazníku, žádost bude zamítnuta!</b></p>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vyplnit žádost o členství" :to="{name: 'applicant'}"/><br><br>
       <q-separator></q-separator>
       <h2>Jak ověřit svůj účet v aplikaci pro přístup k funkcím pro členy?</h2>
       <p>Webová aplikace členům outfitu otevře nové možnosti. Jako členové můžete například předávat své zkušenosti dalším hráčům pomocí návodů.</p>
       <p><b>Jak na to?</b></p>
       <p>Po přijetí do outfitu ve hře uvidíte v rámci "Outfit message of the day" kód pro ověření účtu. Tenhle kód následně stačí zadat v sekci aplikace <b>Můj účet -> Moje informace -> Zadat kód pro ověření účtu.</b></p>
       <p>Po úspěšném zadání kódu Vám budou automaticky přidělena práva člena outfitu.</p>
       <q-separator></q-separator>
       <h2>Máte nějaké dotazy?</h2>
       <p>Máte-li jakékoliv dotazy, neváhejte se zeptat v naší diskuzi, s radostí Vám pomůžeme :)</p><br>
       <q-separator></q-separator>
       <img src="../images/OLT-Logo.png" alt="OLT Logo" class="logoImage">
   </q-card>
   <br><br>
 </div>

  <div class="membershipContainer mobile" v-if="this.mobileStore.mobile">
    <q-card class="memebrshipCard">
      <h1>Členství v OLT</h1>
      <p>One Llama Team stále hledá nové členy! Ať již jste osvíceným bojovníkem Suverenity Vanu, nebo loajálním vojákem Terranské republiky, v OLT je pro Vás místo!</p>
      <q-separator></q-separator>
      <h2>Jak získat členství?</h2>
      <p>Pro získání členství jsou dvě možnosti.</p>
      <p><b>1.</b> Pokud se Vámi potkáme ve hře a chvíli spolu budeme hrát, je možnost se domluvit osobně a získat pozvánku do outfitu.</p>
      <p><b>2.</b> Pokud se z jakéhokoliv důvodu nepotkáme ve hře, například z důvodu nedostatku času, nebo jste-li novým hráčem a chcete pomoci se začátky ve hře, stačí vyplnit formulář s žádostí o členství.</p>
      <p>Společně s formulářem můžete zároveň zaslat žádost o přijetí přímo ve hře. Pokud žádost ve hře nepošlete, ručně Vám po schválení pošleme pozvánku.</p>
      <p>O postupu žádosti budete informování ve stejné sekci, jako je její podání. Na stejném místě můžete zároveň najít případné upřesňující dotazy od našich velitelů.</p>
      <p><b>Pokud pošlete žádost jen ve hře bez vyplnění dotazníku, žádost bude zamítnuta!</b></p>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vyplnit žádost o členství" :to="{name: 'applicant'}"/><br><br>
      <q-separator></q-separator>
      <h2>Jak ověřit svůj účet v aplikaci pro přístup k funkcím pro členy?</h2>
      <p>Webová aplikace členům outfitu otevře nové možnosti. Jako členové můžete například předávat své zkušenosti dalším hráčům pomocí návodů.</p>
      <p><b>Jak na to?</b></p>
      <p>Po přijetí do outfitu ve hře uvidíte v rámci "Outfit message of the day" kód pro ověření účtu. Tenhle kód následně stačí zadat v sekci aplikace <b>Můj účet -> Moje informace -> Zadat kód pro ověření účtu.</b></p>
      <p>Po úspěšném zadání kódu Vám budou automaticky přidělena práva člena outfitu.</p>
      <q-separator></q-separator>
      <h2>Máte nějaké dotazy?</h2>
      <p>Máte-li jakékoliv dotazy, neváhejte se zeptat v naší diskuzi, s radostí Vám pomůžeme :)</p><br>
      <q-separator></q-separator>
      <img src="../images/OLT-Logo.png" alt="OLT Logo" class="logoImage">
    </q-card>
    <br><br>
  </div>
</template>

<script>
import {useMobileStore} from "../stores/MobileStore";
import {mapStores} from "pinia/dist/pinia";

export default {
  name: "MemberView",

  computed: {
    ...mapStores(useMobileStore)
  },
}
</script>

<style scoped>
  .membershipContainer{
    width: 50%;
    margin: auto;
  }

  .membershipContainer h1 {
    font-size: 25px;
    margin: 20px 2% 20px 2%;
    font-weight: bold;
  }

  .membershipContainer h2 {
    font-size: 20px;
    margin: 20px 2% 20px 2%;
    font-weight: bold;
  }

  .membershipContainer p {
    margin: 20px 2% 20px 2%;
  }

  .all-page-button {
    width: 96%;
    margin-left: 2%;
  }

  .mobile {
    width: 90%;
  }
</style>