import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useDiscussionStore = defineStore('discussion', {
    state: () => ({
        themes: [],
        themeDetail: null,
        error: null,
        lastCreated: null,
        themeDetailComments: [],
    }),

    actions: {
        async createTheme(nickname, theme, text){
          try {
              const response = await axios.post(config.backendUrl + '/addTheme', {
                 author: nickname,
                 name: theme,
                 text: text,
                 date: null,
                 updateDate: null,
                 updateAuthor: null,
              });
              this.lastCreated = response.data;
              this.error = true;
          } catch {
              this.error = false;
          }
        },

        async getThemes(nickname){
          try {
              const response = await axios.post(config.backendUrl + '/getThemes', {
                  nickname: nickname,
              });
              this.themes = response.data.reverse();
              this.error = false;
          } catch {
              this.error = true;
          }
        },

        async getDetail(nickname, id){
            try {
                const response = await axios.post(config.backendUrl + '/getThemeDetail', {
                    nickname: nickname,
                    id: id,
                });
                this.themeDetail = response.data;
                this.error = false;
            }catch {
                this.error = true;
            }
        },


        async updateTheme(id, nickname, theme, text){
            try {
                await axios.post(config.backendUrl + '/updateTheme', {
                   nickname: nickname,
                   name: theme,
                   text: text,
                   id: id,
                   updateDate: null,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteTheme(nickname){
            try {
                await axios.post(config.backendUrl + '/deleteTheme', {
                    nickname: nickname,
                    id: this.themeDetail._id,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async addComment(nickname, text, quoteAuthor, quoteText, quoteTime){
           try {
              await axios.post(config.backendUrl + '/addDiscussionComment', {
                 author: nickname,
                 text: text,
                 quoteAuthor: quoteAuthor,
                 quoteText: quoteText,
                 quoteDate: quoteTime,
                 discussionID: this.themeDetail._id,
                 updateDate: null,
                 date: null,
              });
              this.error = false;
          } catch {
              this.error = true;
          }
        },

        async getComments(nickname){
            try {
                const results = await axios.post(config.backendUrl + '/getDiscussionComments', {
                   nickname: nickname,
                   id: this.themeDetail._id,
                });
                this.themeDetailComments = results.data;
                this.error = false;
            } catch {
                this.error = false;
            }
        },


        async updateComment(nickname, id, text){
            try {
                await axios.post(config.backendUrl + '/updateDiscussionComment', {
                    nickname: nickname,
                    id: id,
                    text: text,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteComment(nickname, id){
            try {
                this.error = false;
                await axios.post(config.backendUrl + '/deleteDiscussionComment', {
                   nickname: nickname,
                   id: id,
                });
            } catch {
                this.error = true;
            }
        },

        async reportComment(nickname, id, reportText, quoteAuthor, quoteText){
            try {
                await axios.post(config.backendUrl + '/reportReply', {
                    author: nickname,
                    reportedUserName: quoteAuthor,
                    reportedText: quoteText,
                    reportText: reportText,
                    reportedCommentID: id,
                    reportDate: null,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async reportTheme(nickname, text){
            try {
                await axios.post(config.backendUrl + '/reportTheme', {
                    author: nickname,
                    reportedThemeName: this.themeDetail.name,
                    reportedUserName: this.themeDetail.author,
                    reportedText: this.themeDetail.text,
                    reportText: text,
                    reportedCommentID: this.themeDetail._id,
                    reportDate: null,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteThemeReport(nickname, id){
            try {
                await axios.post(config.backendUrl + '/deleteTheme', {
                    nickname: nickname,
                    id: id,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

    },

    persist: true
});