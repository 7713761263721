<template>
  <div class="fixed-center loginForm" v-if="!this.mobileStore.mobile">
    <q-form v-on:submit="login()">
      <p class="loginText">Přihlaste se svým účtem pro odemknutí všech funkcí aplikace.</p>
      <q-input
          color="purple" square filled
          v-model="nickname"
          label="Přezdívka"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím svou přezdívku']"/><br>
      <q-input
          color="purple" square filled
          v-model="password"
          type="password"
          label="Heslo"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím své heslo']"/>
      <p v-if="this.error" class="error">{{this.userStore.error}}</p>
      <q-btn class="bg-purple text-white shadow-2" align="center" label="Přihlásit" flat type="submit" />
      <router-link class="hyperLink password" to="restore">Obnovit heslo</router-link><br><br>
      <p class="loginText">Nemáte účet? <router-link class="hyperLink" to="register">Stačí kliknout pro registraci!</router-link></p>
    </q-form>
  </div>

  <div class="fixed-center loginForm mobile" v-if="this.mobileStore.mobile">
    <q-form v-on:submit="login()">
      <p class="loginText">Přihlaste se svým účtem pro odemknutí všech funkcí aplikace.</p>
      <q-input
          color="purple" square filled
          v-model="nickname"
          label="Přezdívka"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím svou přezdívku']"/><br>
      <q-input
          color="purple" square filled
          v-model="password"
          type="password"
          label="Heslo"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím své heslo']"/>
      <p v-if="this.error" class="error">{{this.userStore.error}}</p>
      <q-btn class="bg-purple text-white shadow-2" align="center" label="Přihlásit" flat type="submit" />
      <router-link class="hyperLink password" to="restore">Obnovit heslo</router-link><br><br>
      <p class="loginText">Nemáte účet? <router-link class="hyperLink" to="register">Stačí kliknout pro registraci!</router-link></p>
    </q-form>
  </div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "../stores/UserStore";
import {useMobileStore} from "../stores/MobileStore";


export default {
  name: "LoginView",

  data(){
    return {
      password: '',
      nickname: '',
      error: false,
    }
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore)
  },

  methods: {
    async login(){
      await this.userStore.login(this.nickname, this.password);
      if (!this.userStore.error){
        await this.$router.push(this.userStore.afterLoginRoute ?? {name: 'account'});
        this.userStore.setAfterLoginRoute(null);
        this.userStore.loggedIn = true;
      } else {
        this.error = true;
      }
    },
  },

}
</script>

<style scoped>
  .loginForm {
    margin: auto;
  }
  .hyperLink {
    text-decoration: none;
    color: purple;
    font-size: 20px;
  }
  .hyperLink:hover{
    color: #008080;
  }
  .loginText {
    font-size: 20px;
    text-align: center;
  }
  .password {
    float: right;
  }

  .error {
    color: darkred;
    font-weight: bold;
    font-size: 15px;
  }

  .mobile {
    width: 90%;
  }
</style>