<template>
  <img src="../images/Kala-Kraken.jpg" alt="Kaladriel na Hossinu s Krakenem" class="topImage"><br><br>
    <div class="infantryContainer" v-if="!this.mobileStore.mobile">

      <div v-if="(this.userStore.loggedIn === true  && this.userStore.user.role === 'admin') || (this.userStore.loggedIn === true  && this.userStore.user.role === 'member')">
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Přidat návod" @click="changePostingGuideState" /><br><br>
      </div>

      <div v-for="guides in getData()" :key="guides.name" id="guidesShowed">
        <q-card>
          <q-card-section>
            <a class="clickableTitle" href="#" @click="getGuideDetails(guides._id)">{{guides.name}}</a>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section horizontal>
            <q-card-section>
              <img v-bind:src="guides.image + '.png'" style='height: 100px; width: auto' alt="Obrázek návodu" class="newsPicture"/>
            </q-card-section>
            <q-card-section>
              <p class="articleText">{{guides.description}}</p>
            </q-card-section>
          </q-card-section><br>
          <q-separator />
          <q-card-section>
            <p><b><a class="clickableUser" href="#" @click="getUserDetails(guides.author)">{{guides.author}}</a></b> {{guides.date}}</p>
            <p v-if="guides.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getUserDetails(guides.updateAuthor)">{{guides.updateAuthor}}</a></b> {{guides.updateDate}}</p>
          </q-card-section>
        </q-card><br>
      </div>

      <div class="q-pa-lg flex flex-center">
        <q-pagination
            v-model="page"
            :max="totalPages"
            boundary-numbers
            direction-links
            color="purple"
        ></q-pagination><br>
      </div>

    </div>

    <div class="infantryContainer mobile" v-if="this.mobileStore.mobile">
      <div v-if="(this.userStore.loggedIn === true  && this.userStore.user.role === 'admin') || (this.userStore.loggedIn === true  && this.userStore.user.role === 'member')">
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Přidat návod" @click="changePostingGuideState" /><br><br>
      </div>

      <div v-for="guides in getData()" :key="guides.name" id="guidesShowedMobile">
        <q-card>
          <q-card-section>
            <a class="clickableTitle" href="#" @click="getGuideDetails(guides._id)">{{guides.name}}</a>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section horizontal>
            <q-card-section>
              <p class="articleText">{{guides.description}}</p>
            </q-card-section>
          </q-card-section><br>
          <q-separator />
          <q-card-section>
            <p><b><a class="clickableUser" href="#" @click="getUserDetails(guides.author)">{{guides.author}}</a></b> {{guides.date}}</p>
            <p v-if="guides.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getUserDetails(guides.updateAuthor)">{{guides.updateAuthor}}</a></b> {{guides.updateDate}}</p>
          </q-card-section>
        </q-card><br>
      </div>

      <div class="q-pa-lg flex flex-center">
        <q-pagination
            v-model="page"
            :max="totalPages"
            boundary-numbers
            direction-links
            color="purple"
        ></q-pagination><br>
      </div>
    </div>



    <q-dialog v-model="this.postingGuide">
      <q-card style="width: 100%">
        <div class="insideCard">
          <h1>Přidat návod do kategorie pěchota: </h1>

          <q-form v-on:submit="addGuide()">
            <q-input
                color="purple" square filled
                v-model="name"
                label="Nadpis *"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="description"
                autogrow
                label="Krátký popis *"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="text"
                autogrow
                label="Text návodu *"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/>
            <br>

            <q-select
                color="purple" filled
                v-model="selectedInfantry"
                :options="infantry"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím pěchotní třídu:']"
                label="Pěchotní třída *" />

            <q-select
                color="purple" filled
                v-model="selectedImage"
                :options="images"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím pěchotní třídu:']"
                label="Obrázek *" />

            <h1>Loadout:</h1>
            <p>(Pokud se Váš návod týka konkrétního playstyle)</p>

            <div>
              <q-input
                  color="purple" square filled
                  v-model="weapon"
                  label="Primářní zbraň"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="secondaryWeapon"
                  label="Sekundární zbraň"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="tool"
                  label="Nástroj"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="ability"
                  label="Abilita"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="suit"
                  label="Úprava obleku"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="grenade"
                  label="Granát"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="utility"
                  label="Utilita"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="melee"
                  label="Zbraň na blízko"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="implant"
                  label="Implantát 1"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="secondImplant"
                  label="Implantát 2"/><br>
            </div>
            <div>
              <q-input
                  color="purple" square filled
                  v-model="tactical"
                  label="Taktický slot"/><br>
            </div>




            <q-card-actions>
              <q-btn class="bg-purple text-white shadow-2" align="center" label="Přidat návod" flat type="submit" />
              <q-space />
              <q-btn flat label="Zrušit" color="purple" v-close-popup/>
            </q-card-actions>
          </q-form><br>
        </div>
      </q-card>
    </q-dialog>

  </template>

  <script>
    import {useMobileStore} from "../stores/MobileStore";
    import {mapStores} from "pinia/dist/pinia";
    import {useUserStore} from "@/stores/UserStore";
    import {useAccountDetailStore} from "@/stores/AccountDetailStore";
    import {useInfantryGuideStore} from "@/stores/InfantryGuideStore";

    export default {
      name: "infantryView",

      setup(){
        return {
          infantry: [
            'Infiltrátor', 'Lehký útočník', 'Těžký útočník', 'Medik', 'Inženýr', 'MAX', 'Defektor'
          ],
          images: [
            'Infiltrator VS', 'Infiltrator TR', 'Infiltrator NC', 'Infiltrator NS',
            'Light Assault VS', 'Light Assault TR', 'Light Assault NC', 'Light Assault NS',
            'Heavy VS', 'Heavy TR', 'Heavy NC', 'Heavy NS',
            'Medic VS', 'Medic TR', 'Medic NC', 'Medic NS',
            'Engineer VS', 'Engineer TR', 'Engineer NC', 'Engineer NS',
            'MAX VS', 'MAX TR', 'MAX NC', 'Defector',
          ],
        }
      },

      computed: {
        ...mapStores(useMobileStore),
        ...mapStores(useUserStore),
        ...mapStores(useAccountDetailStore),
        ...mapStores(useInfantryGuideStore)
      },

      async created(){
        await this.infantryGuideStore.getGuides();
        await this.getData();
        this.totalPages = Math.ceil(this.infantryGuideStore.guides.length/5);
      },

      components: {
      },

      data() {
        return {
          selectedInfantry: null,
          name: null,
          text: null,
          description: null,
          postingGuide: false,
          error: false,

          weapon: null,
          secondaryWeapon: null,
          tool: null,
          ability: null,
          suit: null,
          grenade: null,
          utility: null,
          melee: null,
          implant: null,
          secondImplant: null,
          tactical: null,
          selectedImage: null,


          page: 1,
          totalPages: null,
        }
      },


      methods: {
        async addGuide(){
          try {
            await this.infantryGuideStore.addGuide(this.userStore.user.nickname, this.name, this.description, this.text, this.selectedInfantry, this.weapon, this.secondaryWeapon, this.tool, this.ability, this.suit, this.grenade, this.utility, this.melee,
            this.implant, this.secondImplant, this.tactical, this.selectedImage);
            await this.infantryGuideStore.getGuideDetail(this.infantryGuideStore.lastID);
            this.$router.push({name: 'infantryDetail'});
            this.error = false;
          } catch{
            this.error = true;
          }
        },

        changePostingGuideState(){
          if(this.postingGuide === false){
            this.postingGuide = true;
          } else {
            this.postingGuide = false;
          }
        },

        getData(){
          if (this.page === 1) {
            return this.infantryGuideStore.guides.slice(this.page-1, this.page*5);
          } else {
            return this.infantryGuideStore.guides.slice(this.page*5-5, this.page*5);
          }
        },

        async getUserDetails(nickname){
          try {
            await this.accountDetailStore.getUser(nickname);
            this.$router.push({name: 'userDetail'});
            this.error = false;
          } catch {
            this.error = true;
          }
        },

        async getGuideDetails(id){
          try {
            await this.infantryGuideStore.getGuideDetail(id);
            this.$router.push({name: 'infantryDetail'});
            this.error = false;
          } catch {
            this.error = true;
          }
        },
      }

    }
  </script>

<style scoped>
    .infantryContainer {
      width: 50%;
      margin: auto;
    }

    .infantryContainer h1{
      font-size: 25px;
    }

    .topImage {
      width: 100%;
      margin-top: -50px;
    }

    .mobile {
      width: 90%;
    }

    .all-page-button {
      width: 100%;
    }

    .insideCard {
      width: 90%;
      margin: auto;
    }

    .insideCard h1 {
      font-size: 25px;
    }

    .clickableTitle {
      color: purple;
      text-decoration: none;
      font-size: 25px;
    }

    .clickableTitle:hover {
      color: #008080;
    }

    .clickableUser {
      color: purple;
      text-decoration: none;
      font-size: 15px;
    }

    .clickableUser:hover {
      color: #008080;
    }
</style>