<template>
  <div class="applicantContainer" v-if="!this.mobileStore.mobile">
    <q-form v-on:submit="apply()">
      <h1>Formulář pro aplikanta do outfitu</h1>
      <q-input
          color="purple" square filled
          v-model="nicknameVS"
          label="Přezdívka VS postavy (Chcete-li být členem OLT)"/>
      <br><br>
      <q-input
          color="purple" square filled
          v-model="nicknameTR"
          label="Přezdívka TR postavy (Chcete-li být členem OLT2)"/>
      <br><br>
      <q-input
          color="purple" square filled
          v-model="time"
          label="Jak dlouho hrajete Planetside? *"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím jak dlouho hrajete Planetside.']"/>
      <br><br>
      <q-input
          color="purple" square filled
          v-model="whatIWant"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
          label="Jaký styl hry preferujete? (Pěchota, vozidla, Hra jen pro zábavu...) *"/>

      <br><br>
      <q-input
          label="Proč právě OLT? *"
          color="purple"
          v-model="whyUs"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"/>
      <br><br>
      <q-input
          label="Chcete nám sdělit něco další? Není problém!"
          color="purple"
          v-model="tellUs"
          filled
          autogrow/>
      <br><br>
      <p class="error">{{this.applicantStore.error}}</p><br>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Poslat přihlášku" flat type="submit" /><br><br>
      <h1>Odeslané přihlášky</h1>
    </q-form>


    <div class="applications" v-for="applications in getData()" :key="applications.nickname" id="applicationsShowed">
      <p><b>ID přihlášky: </b>{{applications._id}}
        <b>Datum podání přihlášky: </b>{{applications.date}}<br>
        <b>Status přihlášky: </b>{{applications.status}}</p>
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail přihlášky" flat @click="getDetails(applications._id)"/><br><br>
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>


  <div class="applicantContainer mobile" v-if="this.mobileStore.mobile">
    <q-form v-on:submit="apply()">
      <h1>Formulář pro aplikanta do outfitu</h1>
      <q-input
          color="purple" square filled
          v-model="nicknameVS"
          label="Přezdívka VS postavy (Chcete-li být členem OLT)"/>
      <br><br>
      <q-input
          color="purple" square filled
          v-model="nicknameTR"
          label="Přezdívka TR postavy (Chcete-li být členem OLT2)"/>
      <br><br>
      <q-input
          color="purple" square filled
          v-model="time"
          label="Jak dlouho hrajete Planetside? *"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím jak dlouho hrajete Planetside.']"/>
      <br><br>
      <q-input
          color="purple" square filled
          v-model="whatIWant"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
          label="Jaký styl hry preferujete? (Pěchota, vozidla, Hra jen pro zábavu...) *"/>

      <br><br>
      <q-input
          label="Proč právě OLT? *"
          color="purple"
          v-model="whyUs"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"/>
      <br><br>
      <q-input
          label="Chcete nám sdělit něco další? Není problém!"
          color="purple"
          v-model="tellUs"
          filled
          autogrow/>
      <br><br>
      <p class="error">{{this.applicantStore.error}}</p><br>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Poslat přihlášku" flat type="submit" /><br><br>
      <h1>Odeslané přihlášky</h1>
    </q-form>


    <div class="applications" v-for="applications in getData()" :key="applications.nickname" id="applicationsShowedMobile">
      <p><b>ID přihlášky: </b>{{applications._id}}
        <b>Datum podání přihlášky: </b>{{applications.date}}<br>
        <b>Status přihlášky: </b>{{applications.status}}</p>
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail přihlášky" flat @click="getDetails(applications._id)"/><br><br>
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {useApplicantStore} from "@/stores/ApplicantStore";
import {useMobileStore} from "@/stores/MobileStore";

export default {
  name: "applicantView",

  data(){
    return {
      error: null,
      tellUs: null,
      whyUs: null,
      whatIwant: null,
      time: null,
      nicknameVS: null,
      nicknameTR: null,
      whatIWant: null,
      page: 1,
      totalPages: null,
    }
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useApplicantStore),
    ...mapStores(useMobileStore)
  },

  async created(){
    await this.getMyApplications();
    this.totalPages = Math.ceil(this.applicantStore.myApplications.length/5);
  },

  methods: {
    async apply(){
      if (this.nicknameVS === null && this.nicknameTR === null){
        this.error = 'Prosím zadejte jméno postavy alespoň na jedné z frakcí.';
      }else {
        this.error = null;
        try {
          await this.applicantStore.newApplication(this.userStore.user.nickname, this.nicknameVS, this.nicknameTR,
              this.time, this.whatIWant, this.whyUs, this.tellUs);
          this.totalPages = Math.ceil(this.applicantStore.myApplications.length/5);
          this.getMyApplications();
          this.error = null;
        } catch {
          this.error = true;
        }
      }
    },

    async getMyApplications(){
      try {
        await this.applicantStore.getMyApplications(this.userStore.user.nickname);
        this.error = null;
      } catch {
        this.error = true;
      }
    },

    getData(){
      window.scroll(0,0);
      if (this.page === 1) {
        return this.applicantStore.myApplications.slice(this.page-1, this.page*5);
      } else {
        return this.applicantStore.myApplications.slice(this.page*5-5, this.page*5);
      }
    },

    async getDetails(id){
      try {
        await this.applicantStore.getDetail(this.userStore.user.nickname, id);
        if (this.applicantStore.error === null){
          await this.$router.push({name: 'applicantDetail'});
        }
        this.error = null;
      } catch {
        this.error = true;
      }
    },

  },

}
</script>

<style scoped>
  .applicantContainer {
    width: 50%;
    margin: auto;
  }

  .applicantContainer h1{
    font-size: 25px;
    font-weight: bold;
  }

  .all-page-button {
    width: 100%;
  }

  .error {
    color: darkred;
    font-weight: bold;
    font-size: 15px;
  }

  .mobile {
    width: 90%;
  }
</style>