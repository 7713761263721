<template>
  <q-layout view="lHh Lpr lFf">
    <div class="q-pa-md" v-if="!mobileStore.mobile">
      <q-header elevated class="bg-primary text-white" height-hint="98">
        <q-toolbar class="bg-purple text-white shadow-2">

          <q-btn flat :to="{name: 'home'}">
            <q-avatar>
              <img src="../src/icons/VS-Logo-White.png" alt="Vanu Sovereignty Logo">
            </q-avatar>
            <label class="q-pl-sm">One LLama Team</label>
          </q-btn>

          <q-space />

          <q-btn-dropdown stretch flat label="Návody">
            <q-list>
              <q-item :to="{name: 'infantry'}">
              <q-item-section avatar>
                <q-avatar>
                  <img src="../src/icons/Orion-Black.png" alt="Infantry Icon">
                </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Pěchota</q-item-label>
                </q-item-section>
              </q-item>

              <q-item :to="{name: 'vehicles'}">
                <q-item-section avatar>
                  <q-avatar>
                    <img src="../src/icons/Maggie-Black.png" alt="Vehicle Icon">
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Vozidla</q-item-label>
                </q-item-section>
              </q-item>

              <q-item :to="{name: 'strategic'}">
                <q-item-section avatar>
                  <q-avatar>
                    <img src="../src/icons/Waypoint-Black.png" alt="Strategic Icon">
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Strategie</q-item-label>
                </q-item-section>
              </q-item>

            </q-list>
          </q-btn-dropdown>


          <q-btn label="Užitečné odkazy" flat :to="{name: 'usefulLinks'}" />
          <q-btn label="Plánované akce" v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'member' || this.userStore.loggedIn === true && this.userStore.user.role === 'admin'" flat :to="{name: 'plannedActions'}" />
          <q-btn label="Diskuze" v-if="this.userStore.loggedIn === true" flat :to="{name: 'discussion'}" />
          <q-btn label="Členství" flat :to="{name: 'membership'}" />
          <q-btn label="O nás" flat @click="getAboutUs"/>
          <q-btn class="VS-color" v-if="this.userStore.loggedIn === false" label="Přihlásit" flat :to="{name: 'login'}" />
          <q-btn v-if="this.userStore.loggedIn === true  && this.userStore.user.role === 'admin'" label="Administrace" flat :to="{name: 'administration'}" />


          <q-btn-dropdown v-if="this.userStore.loggedIn === true" stretch flat label="Můj účet">
            <q-list>
              <q-item to="account">
                <q-item-section>
                  <q-item-label>Moje informace</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable @click="logout">
                <q-item-section>
                  <q-item-label clickable>Odhlásit</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-avatar clickable ><span class="material-icons">logout</span></q-avatar>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>

        </q-toolbar>
      </q-header>
    </div>

    <div class="q-pa-md" v-if="mobileStore.mobile">
        <q-header elevated :class="$q.dark.isActive ? 'bg-primary' : 'bg-purple'">
          <q-toolbar>
            <q-btn flat @click="drawer = !drawer" icon="menu">
              <q-avatar>
                <img src="../src/icons/VS-Logo-White.png" alt="Vanu Sovereignty Logo">
              </q-avatar>
              <label class="q-pl-sm">One LLama Team</label>
            </q-btn>
          </q-toolbar>
        </q-header>

        <q-drawer
            v-model="drawer"
            show-if-above
            :width="200"
            :breakpoint="500"
        >
          <q-scroll-area class="fit">
            <q-list padding class="menu-list">
              <q-item>
                <q-btn label="Hlavní stránka" flat :to="{name: 'home'}" @click="closeDrawer"/>
              </q-item>

              <q-item>
                <q-btn-dropdown stretch flat label="Návody">
                  <q-list>
                    <q-item :to="{name: 'infantry'}" @click="closeDrawer">
                      <q-item-section avatar>
                        <q-avatar>
                          <img src="../src/icons/Orion-Black.png" alt="Infantry Icon">
                        </q-avatar>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Pěchota</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item :to="{name: 'vehicles'}" @click="closeDrawer">
                      <q-item-section avatar>
                        <q-avatar>
                          <img src="../src/icons/Maggie-Black.png" alt="Vehicle Icon">
                        </q-avatar>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Vozidla</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item :to="{name: 'strategic'}" @click="closeDrawer">
                      <q-item-section avatar>
                        <q-avatar>
                          <img src="../src/icons/Waypoint-Black.png" alt="Strategic Icon">
                        </q-avatar>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Strategie</q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-btn-dropdown>
              </q-item>


              <q-item>
                <q-btn @click="closeDrawer" label="Užitečné odkazy" flat :to="{name: 'usefulLinks'}" />
              </q-item>

              <q-item>
                <q-btn @click="closeDrawer" label="Členství" flat :to="{name: 'membership'}" />
              </q-item>

              <q-item>
                <q-btn @click="getAboutUs" label="O nás" flat />
              </q-item>

              <q-item>
                <q-btn @click="closeDrawer" label="Kdo je online?" flat :to="{name: 'onlineMobile'}" />
              </q-item>

              <q-item v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'member' || this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
                <q-btn @click="closeDrawer" label="Plánované akce" flat :to="{name: 'plannedActions'}" />
              </q-item>

              <q-item v-if="this.userStore.loggedIn === true">
                <q-btn @click="closeDrawer" label="Diskuze" flat :to="{name: 'discussion'}" />
              </q-item>

              <q-item v-if="this.userStore.loggedIn === true  && this.userStore.user.role === 'admin'">
                <q-btn @click="closeDrawer" label="Administrace" flat :to="{name: 'administration'}" />
              </q-item>

              <q-item v-if="this.userStore.loggedIn === true">
                <q-btn-dropdown stretch flat label="Můj účet">
                  <q-list>
                    <q-item @click="closeDrawer" to="account">
                      <q-item-section>
                        <q-item-label>Moje informace</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable @click="logout">
                      <q-item-section>
                        <q-item-label clickable>Odhlásit</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-avatar clickable ><span class="material-icons">logout</span></q-avatar>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </q-item>

              <q-item v-if="this.userStore.loggedIn === false">
                <q-btn @click="closeDrawer" v-if="this.userStore.loggedIn === false" label="Přihlásit" flat :to="{name: 'login'}" />
              </q-item>


            </q-list>
          </q-scroll-area>
        </q-drawer>
  </div>

    <q-page-container>
      <router-view />
    </q-page-container>

    <q-footer elevated class="bg-purple text-white shadow-2">
        <q-toolbar-title class="footer">One Llama Team - 2023</q-toolbar-title>
    </q-footer>
  </q-layout>
</template>

<script>

import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "./stores/UserStore";
import {useMobileStore} from "./stores/MobileStore";
import {useAboutStore} from "@/stores/AboutUsStore";

export default {
  setup () {

  },

  created() {
    this.getMobile();
  },

  name: 'LayoutDefault',
  data(){
    return {
      windowWidth: null,
      mobile: null,
      drawer: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAboutStore),
  },

  methods: {
    async logout(){
      const nickname = this.userStore.user.nickname;
      await this.userStore.logout(nickname);
      this.$router.push({name: 'home'});
      this.closeDrawer();
    },

    onResize() {
      this.getMobile();
    },

    getMobile(){
      this.windowWidth = window.innerWidth;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.mobileStore.mobile = true;
        } else if (this.windowWidth < 1250) {
            this.mobileStore.mobile = true;
        } else {
          this.mobileStore.mobile = false;
      }
    },

    closeDrawer(){
      this.drawer = false;
    },

    async getAboutUs(){
      try {
        await this.aboutStore.getLlama();
        await this.aboutStore.getKala();
        await this.aboutStore.getMarty();
        this.closeDrawer();
        this.$router.push({name: 'about'});
        this.error = false;
      } catch {
        this.error = true;
      }
    }

  },
}
</script>


<style scoped>
  .footer{
    text-align: center;
    margin: 10px 0px 10px 0px;
    font-size: 12px;
  }

  .VS-color{
    color: #00FFFF;
  }
</style>