<template>
<div class="codesContainer" v-if="!this.mobileStore.mobile">
  <h1>Vytvoření nového bezpečnostního kódu</h1>
  <q-input
      color="purple" square filled
      v-model="code"
      label="Kód *"
      :rules="[ val => val && val.length > 0 || 'Zadejte bezpečnostní kód']"/><br>
  <q-select
      color="purple" filled
      v-model="selectedFaction"
      :options="faction"
      :rules="[ val => val && val.length > 0 || 'Vyberte prosím fakci']"
      label="Fakce *" />
  <br>
  <q-select
      color="purple" filled
      v-model="selectedRole"
      :options="role"
      :rules="[ val => val && val.length > 0 || 'Vyberte prosím roli']"
      label="Role *" />
  <br>
  <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vytvořit nový kód" @click="createCode()" /><br><br>
  <h1>Aktivní kódy</h1>


  <div class="codes" v-for="codes in getData()" :key="codes.code" id="codesShowed">
      <p><b>Kód: </b>{{codes.code}}
      <b>Fakce: </b>{{codes.faction}}
      <b>Role: </b>{{codes.role}}</p>
      <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit kód" flat @click="getDeleteData(codes._id, codes.code)"/><br><br>
    </div>

  <q-dialog v-model="this.deleteDialogue">
    <q-card class="deleteAccountDialogue">
      <q-card-section>
        <div class="text-h6">Odstranění autorizačního kódu</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        Jste si jisti, že chcete odstranit kód <b>{{this.deleteCode}}</b>?
      </q-card-section>
      <q-card-actions>
        <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteThisCode()"  />
        <q-space />
        <q-btn flat label="Zrušit" color="purple" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <div class="q-pa-lg flex flex-center">
    <q-pagination
        v-model="page"
        :max="totalPages"
        boundary-numbers
        direction-links
        color="purple"
    ></q-pagination><br>
  </div>
</div>

  <div class="codesContainer mobile" v-if="this.mobileStore.mobile">
    <h1>Vytvoření nového bezpečnostního kódu</h1>
    <q-input
        color="purple" square filled
        v-model="code"
        label="Kód *"
        :rules="[ val => val && val.length > 0 || 'Zadejte bezpečnostní kód']"/><br>
    <q-select
        color="purple" filled
        v-model="selectedFaction"
        :options="faction"
        :rules="[ val => val && val.length > 0 || 'Vyberte prosím fakci']"
        label="Fakce *" />
    <br>
    <q-select
        color="purple" filled
        v-model="selectedRole"
        :options="role"
        :rules="[ val => val && val.length > 0 || 'Vyberte prosím roli']"
        label="Role *" />
    <br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vytvořit nový kód" @click="createCode()" /><br><br>
    <h1>Aktivní kódy</h1>


    <div class="codes" v-for="codes in getData()" :key="codes.code" id="codesShowedMobile">
      <p><b>Kód: </b>{{codes.code}}
        <b>Fakce: </b>{{codes.faction}}
        <b>Role: </b>{{codes.role}}</p>
      <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit kód" flat @click="getDeleteData(codes._id, codes.code)"/><br><br>
    </div>

    <q-dialog v-model="this.deleteDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění autorizačního kódu</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit kód <b>{{this.deleteCode}}</b>?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteThisCode()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {useCodesStore} from "@/stores/CodesStore";
import {useMobileStore} from "../stores/MobileStore";


export default {
  name: "CodesView",

  setup(){
    return {
      faction: [
        'VS', 'TR', 'NC', 'NS'
      ],
      role: [
          'member', 'admin'
      ]
    }
  },

  data(){
    return {
      selectedRole: null,
      selectedFaction: null,
      code: null,
      error: null,
      page: 1,
      totalPages: null,
      deleteCode: null,
      deleteId: null,
      deleteDialogue: false,
    }
  },

  async created() {
    await this.codesStore.getAllCodes(this.userStore.user.nickname);
    this.totalPages = Math.ceil(this.codesStore.codes.length/10);
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useCodesStore),
    ...mapStores(useMobileStore)

  },

  methods: {
    async createCode(){
      try {
        await this.codesStore.saveNewCode(this.userStore.user.nickname,this.selectedFaction, this.selectedRole, this.code);
        this.error = null;
        await this.codesStore.getAllCodes(this.userStore.user.nickname);
        this.totalPages = Math.ceil(this.codesStore.codes.length/10);
      } catch {
        this.error = "Chyba při ukládání nového kódu";
      }
    },

    getData(){
      window.scroll(0,0);
      if (this.page === 1) {
        return this.codesStore.codes.slice(this.page-1, this.page*10);
      } else {
        return this.codesStore.codes.slice(this.page*10-10, this.page*10);
      }
    },

    getDeleteData(id, name){
      this.deleteCode = name;
      this.deleteId = id;
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

    async deleteThisCode(){
      try {
        await this.codesStore.deleteCodes(this.userStore.user.nickname, this.deleteId);
        this.error = null;
        await this.codesStore.getAllCodes(this.userStore.user.nickname);
        this.totalPages = Math.ceil(this.codesStore.codes.length/10);
      }catch {
        this.error = "Chyba při odstranění kódu.";
      }
    },

  }
}
</script>

<style scoped>
  .codesContainer {
    width: 50%;
    margin: auto;
  }

  .codesContainer h1{
    font-size: 25px;
    font-weight: bold;
  }

  .all-page-button {
    width: 100%;
  }

  .codes p {
    font-size: 20px;
    margin: 10px 10px 10px 0px;
  }

  .deleteButton {
    float: right;
  }

  .mobile {
    width: 90%;
  }
</style>