<template>
  <div class="vehiclesContainer" v-if="!this.mobileStore.mobile">

    <div v-if="(this.userStore.loggedIn === true  && this.vehicleGuideStore.guideDetail.author === this.userStore.user.nickname) || (this.userStore.loggedIn === true  && this.userStore.user.role === 'admin')">
      <q-card>
        <div style="width: 95%; margin: auto">
        <h1>Upravit návod: </h1>
        <q-form v-on:submit="updateGuide()">
          <q-input
              color="purple" square filled
              v-model="name"
              label="Nadpis"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
          <br>
          <q-input
              color="purple" square filled
              v-model="description"
              autogrow
              label="Krátký popis"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
          <br>
          <q-input
              color="purple" square filled
              v-model="text"
              autogrow
              label="Text návodu"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/>
          <br>
          <h1>Loadout:</h1>
          <p>(Pokud se Váš návod týka konkrétního playstyle)</p>

          <div v-if="this.selectedVehicle === 'Flash' || this.selectedVehicle === 'Lightning' || this.selectedVehicle === 'Magrider' || this.selectedVehicle === 'Prowler' || this.selectedVehicle === 'Chimera'
            || this.selectedVehicle === 'Vanguard' || this.selectedVehicle === 'Scythe' || this.selectedVehicle === 'Mosquito' || this.selectedVehicle === 'Reaver' || this.selectedVehicle === 'Dervish' || this.selectedVehicle === 'Valkyrie' ||
             this.selectedVehicle === 'Liberator' || this.selectedVehicle === 'Colossus' || this.selectedVehicle === 'Javelin'">
            <q-input
                color="purple" square filled
                v-model="weapon"
                label="Primární zbraň"/><br>
          </div>

          <div v-if="this.selectedVehicle === 'ANT'">
            <q-input
                color="purple" square filled
                v-model="harvest"
                label="Sběrač"/><br>
          </div>

          <div v-if="this.selectedVehicle === 'Harasser' || this.selectedVehicle === 'Sunderer' || this.selectedVehicle === 'ANT' || this.selectedVehicle === 'Magrider' || this.selectedVehicle === 'Vanguard'
              || this.selectedVehicle === 'Prowler' || this.selectedVehicle === 'Chimera' || this.selectedVehicle === 'Galaxy' || this.selectedVehicle === 'Corsair' || this.selectedVehicle === 'Dervish'">
            <q-input
                color="purple" square filled
                v-model="topGun"
                label="Horní zbraň"/><br>
          </div>

          <div v-if ="this.selectedVehicle === 'Sunderer' || this.selectedVehicle === 'Corsair'">
            <q-input
                color="purple" square filled
                v-model="secondTopGun"
                label="Sekundární horní zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Scythe' || this.selectedVehicle === 'Mosquito' || this.selectedVehicle === 'Reaver'">
            <q-input
                color="purple" square filled
                v-model="wings"
                label="Křídla"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Liberator'">
            <q-input
                color="purple" square filled
                v-model="bellyGun"
                label="Spodní zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Galaxy' || this.selectedVehicle === 'Liberator'">
            <q-input
                color="purple" square filled
                v-model="tailGun"
                label="Zadní zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Galaxy'">
            <q-input
                color="purple" square filled
                v-model="leftGun"
                label="Levá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Galaxy'">
            <q-input
                color="purple" square filled
                v-model="rightGun"
                label="Pravá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="frontRightGun"
                label="Přední pravá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="frontLeftGun"
                label="Přední levá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="backRightGun"
                label="Zadní pravá zbraň"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="backLeftGun"
                label="Zadní levá zbraň"/><br>
          </div>
          <q-input
              color="purple" square filled
              v-model="utility"
              label="Utilita"/><br>
          <q-input
              color="purple" square filled
              v-model="defence"
              label="Obranný slot"/><br>
          <div v-if="this.selectedVehicle !== 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="performance"
                label="Slot pro úpravu výkonu"/><br>
          </div>
          <div v-if="this.selectedVehicle === 'Colossus'">
            <q-input
                color="purple" square filled
                v-model="logistics"
                label="Logistika"/><br>
          </div>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit návod" flat type="submit" /><br><br>
          <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit návod" flat @click="changeDeleteState()"/>
        </q-form><br>
        </div>
      </q-card>
    </div>


    <q-card>
      <div style="width: 95%; margin: auto">
        <h1>{{this.vehicleGuideStore.guideDetail.name}}</h1>
        <p class="articleText">{{this.vehicleGuideStore.guideDetail.description}}</p>
        <img v-bind:src="this.vehicleGuideStore.guideDetail.vehicle + '.png'" style="width: 100%" alt="Obrázek návodu" class="newsPicture"/>
        <b><a class="clickableUser" href="#" @click="getUserDetails(this.vehicleGuideStore.guideDetail.author)">{{this.vehicleGuideStore.guideDetail.author}}</a></b> {{this.vehicleGuideStore.guideDetail.date}}
        <div v-if="this.vehicleGuideStore.guideDetail.updateAuthor !== null">
          <b>Update: <a class="clickableUser" href="#" @click="getUserDetails(this.vehicleGuideStore.guideDetail.updateAuthor)">{{this.vehicleGuideStore.guideDetail.updateAuthor}}</a></b> {{this.vehicleGuideStore.guideDetail.updateDate}}
        </div><br>
        <q-separator></q-separator><br>
        <div>
          <b>Loadout:</b>
          <div v-if="this.vehicleGuideStore.guideDetail.weapon !== null">
            <b>Zbraň: </b>{{this.vehicleGuideStore.guideDetail.weapon}}<br>
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.harvest !== null">
            <b>Typ sběrače: </b>{{this.vehicleGuideStore.guideDetail.harvest}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.topGun !== null">
            <b>Horní zbraň: </b>{{this.vehicleGuideStore.guideDetail.topGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.secondTopGun !== null">
            <b>Zadní horní zbraň: </b>{{this.vehicleGuideStore.guideDetail.secondTopGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.wings !== null">
            <b>Křídla: </b>{{this.vehicleGuideStore.guideDetail.wings}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.bellyGun !== null">
            <b>Spodní zbraň: </b> {{this.vehicleGuideStore.guideDetail.bellyGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.tailGun !== null">
            <b>Zadní zbraň: </b> {{this.vehicleGuideStore.guideDetail.tailGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.leftGun !== null">
            <b>Levá zbraň: </b> {{this.vehicleGuideStore.guideDetail.leftGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.rightGun !== null">
            <b>Pravá zbraň: </b> {{this.vehicleGuideStore.guideDetail.rightGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.frontLeftGun !== null">
            <b>Přední levá zbraň: </b> {{this.vehicleGuideStore.guideDetail.frontLeftGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.frontRightGun !== null">
            <b>Přední pravá zbraň: </b> {{this.vehicleGuideStore.guideDetail.frontRightGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.backLeftGun !== null">
            <b>Zadní levá zbraň: </b> {{this.vehicleGuideStore.guideDetail.backLeftGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.backRightGun !== null">
            <b>Zadní pravá zbraň: </b> {{this.vehicleGuideStore.guideDetail.backRightGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.utility !== null">
            <b>Utilita: </b> {{this.vehicleGuideStore.guideDetail.utility}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.defence !== null">
            <b>Obranný slot: </b> {{this.vehicleGuideStore.guideDetail.defence}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.performance !== null">
            <b>Upravení kontroly: </b> {{this.vehicleGuideStore.guideDetail.performance}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.logistics !== null">
            <b>Logistika: </b> {{this.vehicleGuideStore.guideDetail.logistics}}
          </div>
        </div><br>
        <br><q-separator></q-separator><br><br>
        <p class="articleText">{{this.vehicleGuideStore.guideDetail.text}}</p><br>
      </div>
    </q-card>
  </div>

  <div class="vehiclesContainer mobile" v-if="this.mobileStore.mobile">
    <div v-if="(this.userStore.loggedIn === true  && this.vehicleGuideStore.guideDetail.author === this.userStore.user.nickname) || (this.userStore.loggedIn === true  && this.userStore.user.role === 'admin')">
      <q-card>
        <div style="width: 95%; margin: auto">
          <h1>Upravit návod: </h1>
          <q-form v-on:submit="updateGuide()">
            <q-input
                color="purple" square filled
                v-model="name"
                label="Nadpis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nadpis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="description"
                autogrow
                label="Krátký popis"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím krátký popis návodu:']"/>
            <br>
            <q-input
                color="purple" square filled
                v-model="text"
                autogrow
                label="Text návodu"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím text návodu:']"/>
            <br>
            <h1>Loadout:</h1>
            <p>(Pokud se Váš návod týka konkrétního playstyle)</p>

            <div v-if="this.selectedVehicle === 'Flash' || this.selectedVehicle === 'Lightning' || this.selectedVehicle === 'Magrider' || this.selectedVehicle === 'Prowler' || this.selectedVehicle === 'Chimera'
            || this.selectedVehicle === 'Vanguard' || this.selectedVehicle === 'Scythe' || this.selectedVehicle === 'Mosquito' || this.selectedVehicle === 'Reaver' || this.selectedVehicle === 'Dervish' || this.selectedVehicle === 'Valkyrie' ||
             this.selectedVehicle === 'Liberator' || this.selectedVehicle === 'Colossus' || this.selectedVehicle === 'Javelin'">
              <q-input
                  color="purple" square filled
                  v-model="weapon"
                  label="Primární zbraň"/><br>
            </div>

            <div v-if="this.selectedVehicle === 'ANT'">
              <q-input
                  color="purple" square filled
                  v-model="harvest"
                  label="Sběrač"/><br>
            </div>

            <div v-if="this.selectedVehicle === 'Harasser' || this.selectedVehicle === 'Sunderer' || this.selectedVehicle === 'ANT' || this.selectedVehicle === 'Magrider' || this.selectedVehicle === 'Vanguard'
              || this.selectedVehicle === 'Prowler' || this.selectedVehicle === 'Chimera' || this.selectedVehicle === 'Galaxy' || this.selectedVehicle === 'Corsair' || this.selectedVehicle === 'Dervish'">
              <q-input
                  color="purple" square filled
                  v-model="topGun"
                  label="Horní zbraň"/><br>
            </div>

            <div v-if ="this.selectedVehicle === 'Sunderer' || this.selectedVehicle === 'Corsair'">
              <q-input
                  color="purple" square filled
                  v-model="secondTopGun"
                  label="Sekundární horní zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Scythe' || this.selectedVehicle === 'Mosquito' || this.selectedVehicle === 'Reaver'">
              <q-input
                  color="purple" square filled
                  v-model="wings"
                  label="Křídla"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Liberator'">
              <q-input
                  color="purple" square filled
                  v-model="bellyGun"
                  label="Spodní zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Galaxy' || this.selectedVehicle === 'Liberator'">
              <q-input
                  color="purple" square filled
                  v-model="tailGun"
                  label="Zadní zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Galaxy'">
              <q-input
                  color="purple" square filled
                  v-model="leftGun"
                  label="Levá zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Galaxy'">
              <q-input
                  color="purple" square filled
                  v-model="rightGun"
                  label="Pravá zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Colossus'">
              <q-input
                  color="purple" square filled
                  v-model="frontRightGun"
                  label="Přední pravá zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Colossus'">
              <q-input
                  color="purple" square filled
                  v-model="frontLeftGun"
                  label="Přední levá zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Colossus'">
              <q-input
                  color="purple" square filled
                  v-model="backRightGun"
                  label="Zadní pravá zbraň"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Colossus'">
              <q-input
                  color="purple" square filled
                  v-model="backLeftGun"
                  label="Zadní levá zbraň"/><br>
            </div>
            <q-input
                color="purple" square filled
                v-model="utility"
                label="Utilita"/><br>
            <q-input
                color="purple" square filled
                v-model="defence"
                label="Obranný slot"/><br>
            <div v-if="this.selectedVehicle !== 'Colossus'">
              <q-input
                  color="purple" square filled
                  v-model="performance"
                  label="Slot pro úpravu výkonu"/><br>
            </div>
            <div v-if="this.selectedVehicle === 'Colossus'">
              <q-input
                  color="purple" square filled
                  v-model="logistics"
                  label="Logistika"/><br>
            </div>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit návod" flat type="submit" /><br><br>
            <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit návod" flat @click="changeDeleteState()"/>
          </q-form><br>
        </div>
      </q-card>
    </div>


    <q-card>
      <div style="width: 95%; margin: auto">
        <h1>{{this.vehicleGuideStore.guideDetail.name}}</h1>
        <p class="articleText">{{this.vehicleGuideStore.guideDetail.description}}</p>
        <img v-bind:src="this.vehicleGuideStore.guideDetail.vehicle + '.png'" style="width: 100%" alt="Obrázek návodu" class="newsPicture"/>
        <b><a class="clickableUser" href="#" @click="getUserDetails(this.vehicleGuideStore.guideDetail.author)">{{this.vehicleGuideStore.guideDetail.author}}</a></b> {{this.vehicleGuideStore.guideDetail.date}}
        <div v-if="this.vehicleGuideStore.guideDetail.updateAuthor !== null">
          <b>Update: <a class="clickableUser" href="#" @click="getUserDetails(this.vehicleGuideStore.guideDetail.updateAuthor)">{{this.vehicleGuideStore.guideDetail.updateAuthor}}</a></b> {{this.vehicleGuideStore.guideDetail.updateDate}}
        </div><br>
        <q-separator></q-separator><br>
        <div>
          <b>Loadout:</b>
          <div v-if="this.vehicleGuideStore.guideDetail.weapon !== null">
            <b>Zbraň: </b>{{this.vehicleGuideStore.guideDetail.weapon}}<br>
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.harvest !== null">
            <b>Typ sběrače: </b>{{this.vehicleGuideStore.guideDetail.harvest}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.topGun !== null">
            <b>Horní zbraň: </b>{{this.vehicleGuideStore.guideDetail.topGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.secondTopGun !== null">
            <b>Zadní horní zbraň: </b>{{this.vehicleGuideStore.guideDetail.secondTopGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.wings !== null">
            <b>Křídla: </b>{{this.vehicleGuideStore.guideDetail.wings}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.bellyGun !== null">
            <b>Spodní zbraň: </b> {{this.vehicleGuideStore.guideDetail.bellyGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.tailGun !== null">
            <b>Zadní zbraň: </b> {{this.vehicleGuideStore.guideDetail.tailGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.leftGun !== null">
            <b>Levá zbraň: </b> {{this.vehicleGuideStore.guideDetail.leftGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.rightGun !== null">
            <b>Pravá zbraň: </b> {{this.vehicleGuideStore.guideDetail.rightGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.frontLeftGun !== null">
            <b>Přední levá zbraň: </b> {{this.vehicleGuideStore.guideDetail.frontLeftGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.frontRightGun !== null">
            <b>Přední pravá zbraň: </b> {{this.vehicleGuideStore.guideDetail.frontRightGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.backLeftGun !== null">
            <b>Zadní levá zbraň: </b> {{this.vehicleGuideStore.guideDetail.backLeftGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.backRightGun !== null">
            <b>Zadní pravá zbraň: </b> {{this.vehicleGuideStore.guideDetail.backRightGun}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.utility !== null">
            <b>Utilita: </b> {{this.vehicleGuideStore.guideDetail.utility}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.defence !== null">
            <b>Obranný slot: </b> {{this.vehicleGuideStore.guideDetail.defence}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.performance !== null">
            <b>Upravení kontroly: </b> {{this.vehicleGuideStore.guideDetail.performance}}
          </div>
          <div v-if="this.vehicleGuideStore.guideDetail.logistics !== null">
            <b>Logistika: </b> {{this.vehicleGuideStore.guideDetail.logistics}}
          </div>
        </div><br>
        <q-separator></q-separator><br><br>
        <p class="articleText">{{this.vehicleGuideStore.guideDetail.text}}</p><br>
      </div>
    </q-card>
  </div><br><br>


  <q-dialog v-model="this.deleting">
    <q-card>
      <q-card-section>
        <div class="text-h6">Odstranění návodu</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        Jste si jisti, že chcete odstranit tento návod?
      </q-card-section>
      <q-card-actions>
        <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" @click="this.deleteVehicleGuide()"  />
        <q-space />
        <q-btn flat label="Zrušit" color="purple" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useVehicleGuideStore} from "@/stores/VehicleGuideStore";
import {useUserStore} from "@/stores/UserStore";
import {useMobileStore} from "@/stores/MobileStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";

export default {
  name: "VehicleDetailView",

  computed: {
    ...mapStores(useVehicleGuideStore),
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAccountDetailStore)
  },

  created(){
    this.weapon = this.vehicleGuideStore.guideDetail.weapon;
    this.harvest = this.vehicleGuideStore.guideDetail.harvest;
    this.topGun = this.vehicleGuideStore.guideDetail.topGun;
    this.secondTopGun = this.vehicleGuideStore.guideDetail.secondTopGun;
    this.wings = this.vehicleGuideStore.guideDetail.wings;
    this.bellyGun = this.vehicleGuideStore.guideDetail.bellyGun;
    this.tailGun = this.vehicleGuideStore.guideDetail.tailGun;
    this.leftGun = this.vehicleGuideStore.guideDetail.leftGun;
    this.rightGun = this.vehicleGuideStore.guideDetail.rightGun;
    this.frontRightGun = this.vehicleGuideStore.guideDetail.frontRightGun;
    this.frontLeftGun = this.vehicleGuideStore.guideDetail.frontLeftGun;
    this.backRightGun = this.vehicleGuideStore.guideDetail.backRightGun;
    this.backLeftGun = this.vehicleGuideStore.guideDetail.backLeftGun;
    this.utility = this.vehicleGuideStore.guideDetail.utility;
    this.defence = this.vehicleGuideStore.guideDetail.defence;
    this.performance = this.vehicleGuideStore.guideDetail.performance;
    this.logistics = this.vehicleGuideStore.guideDetail.logistics;
    this.selectedVehicle = this.vehicleGuideStore.guideDetail.vehicle;
    this.text = this.vehicleGuideStore.guideDetail.text;
    this.name = this.vehicleGuideStore.guideDetail.name;
    this.description = this.vehicleGuideStore.guideDetail.description;
  },

  data(){
    return {
      weapon: null,
      harvest: null,
      topGun: null,
      secondTopGun: null,
      wings: null,
      bellyGun: null,
      tailGun: null,
      leftGun: null,
      rightGun: null,
      frontRightGun: null,
      frontLeftGun: null,
      backRightGun: null,
      backLeftGun: null,
      utility: null,
      defence: null,
      performance: null,
      logistics: null,
      selectedVehicle: null,
      text: null,
      name: null,
      description: null,
      deleting: false,
    }
  },

  methods: {
    async getUserDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async updateGuide(){
      try {
        await this.vehicleGuideStore.updateGuide(this.userStore.user.nickname, this.name, this.description, this.text, this.weapon, this.harvest, this.topGun, this.secondTopGun,
            this.wings, this.bellyGun, this.tailGun, this.leftGun, this.rightGun, this.frontLeftGun, this.frontRightGun, this.backLeftGun, this.backRightGun, this.utility,
            this.defence, this.performance, this.logistics, this.selectedVehicle);

        await this.vehicleGuideStore.getGuideDetail(this.vehicleGuideStore.guideDetail._id);
        this.$router.push({name: 'vehicleDetail'});
        this.error = false;
      } catch{
        this.error = true;
      }
    },

    async deleteVehicleGuide(){
      try {
        await this.vehicleGuideStore.deleteGuide(this.userStore.user.nickname);
        await this.vehicleGuideStore.getGuides();
        this.$router.push({name: 'vehicles'});
        this.error = false;
      }
      catch {
        this.error = true;
      }
    },

    changeDeleteState(){
      if (this.deleting === false){
        this.deleting = true;
      } else {
        this.deleting = false;
      }
    },

  }
}
</script>

<style scoped>

  .vehiclesContainer {
    width: 50%;
    margin: auto;
  }

  .vehiclesContainer h1{
    font-size: 25px;
  }

  .mobile {
    width: 90%;
  }

  .all-page-button {
    width: 100%;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .articleText {
    white-space: pre-wrap;
  }
</style>