<template>
  <div class="mainContainer" v-if="!this.mobileStore.mobile">
      <q-form v-on:submit="updateAction()">
        <div v-if="this.userStore.user.role === 'admin'">
          <h1>Upravit akci:</h1>
          <q-input
              color="purple" square filled
              v-model="name"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
              label="Název akce:" />
          <q-select
              color="purple" filled
              v-model="selectedPlace"
              :options="place"
              :rules="[ val => val && val.length > 0 || 'Zadejte místo konání akce']"
              label="Místo konání akce *" />
          <br>

          <div class="q-gutter-md row justify-center">
            <q-date v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                    :rules="[ val => val && val.length > 0 || 'Vyberte prosím datum.']"
            />
            <q-time v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                    :rules="[ val => val && val.length > 0 || 'Vyberte prosím čas.']"
            />
          </div><br><br>

          <q-input
              label="Popis akce"
              color="purple"
              v-model="text"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
          /><br>
          <p class="error" v-if="this.error">{{this.errorMessage}}</p>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit akci" flat type="submit" /><br><br>
          <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit akci" @click="changedeleteState(this.plannedActionsStore.actionDetail._id, this.plannedActionsStore.actionDetail.actionName)"/><br><br>
        </div>
      </q-form>

  <q-dialog v-model="this.deleteDialogue">
    <q-card class="deleteAccountDialogue">
      <q-card-section>
        <div class="text-h6">Odstranění akce</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        Jste si jisti, že chcete odstranit akci <b>{{this.deleteName}}</b>?
      </q-card-section>
      <q-card-actions>
        <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteAction(this.deleteId)"  />
        <q-space />
        <q-btn flat label="Zrušit" color="purple" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

    <q-card>
      <q-card-section>
        <h1>{{this.plannedActionsStore.actionDetail.actionName}}</h1>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section horizontal>
        <img v-bind:src="this.image" alt="Obrázek akce" class="actionPicture"/>
        <p class="actionText">{{this.plannedActionsStore.actionDetail.text}}</p>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <p class="actionText"><b>Místo konání akce: </b>{{this.plannedActionsStore.actionDetail.place}}</p>
        <p class="actionText"><b>Čas konání akce: </b>{{this.plannedActionsStore.actionDetail.date}}</p>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <p><b><a class="clickableUser" href="#" @click="getDetails(this.plannedActionsStore.actionDetail.author)">{{this.plannedActionsStore.actionDetail.author}}</a></b> {{this.plannedActionsStore.actionDetail.dateCreated}}</p>
        <p v-if="this.plannedActionsStore.actionDetail.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(this.plannedActionsStore.actionDetail.updateAuthor)">{{this.plannedActionsStore.actionDetail.updateAuthor}}</a></b> {{this.plannedActionsStore.actionDetail.updateTime}}</p>
      </q-card-section>
    </q-card> <br>
    <div v-if="!this.plannedActionsStore.attending && (this.userStore.user.role === 'member' || this.userStore.user.role === 'admin')">
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zúčastním se" flat @click="attend()"/><br><br>
    </div>
    <div v-if="this.plannedActionsStore.attending && (this.userStore.user.role === 'member' || this.userStore.user.role === 'admin')">
      <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Zrušit účast" @click="attendNot()"/><br><br>
    </div>

  <h1>Účastnící: </h1>

    <div v-for="attendee in this.plannedActionsStore.attendees" :key="attendee" id="attendeesShowed">
        <q-card class="userName">
        <q-card-section horizontal>
          <img v-bind:src="attendee.user[0].profilePicture" alt="Obrázek uživatele" class="userPicture"/>
          <h1><a class="clickableUser clickableUserBig" href="#" @click="getDetails(attendee.attendee)">{{attendee.attendee}}</a></h1>
        </q-card-section>
      </q-card><br>
    </div>

    <div class="comments mobile">
      <h1 class="comments">Přidat komentář</h1>
      <q-input
          label="Text Komentáře:"
          color="purple"
          v-model="commentText"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Přidat komentář" flat @click="postComment()"/><br><br>
    </div>

    <p class="noComment" v-if="this.plannedActionsStore.actionComments.length === 0"><b>Žádné komentáře</b></p>

    <div v-for="(comments) in getData()" :key="comments._id" id="applicationsShowed">
      <q-card>
        <q-card-section>
          <p><b><a class="clickableUser" href="#" @click="getDetails(comments.nickname)">{{comments.nickname}}</a></b> {{comments.date}}</p>
          <p v-if="comments.updateDate !== null"><b>Upraveno: </b>{{comments.updateDate}}</p>
          <p v-if="comments.user[0].role === 'admin'"><b>Velitel outfitu</b></p>
          <p v-if="comments.user[0].role === 'member'"><b>Člen OLT</b></p>
        </q-card-section>
        <q-separator />
        <q-card-section horizontal>
          <img v-bind:src="comments.user[0].profilePicture" alt="Obrázek akce" class="newsPicture"/>
          <q-card-section>
            <p>{{comments.text}}</p>
          </q-card-section>
        </q-card-section><br>
      </q-card><br>
      <div v-if="comments.nickname === this.userStore.user.nickname">
        <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Upravit komentář" flat @click="changeUpdatingStatus(comments._id, comments.text)"/><br><br>
      </div>
      <div v-if="comments.nickname === this.userStore.user.nickname || this.userStore.user.role === 'admin'">
        <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit komentář" flat @click="getDeleteID(comments._id)"/><br><br>
      </div>
    </div>

    <q-dialog v-model="this.deleteCommentDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění komentáře</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit komentář?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteComment(this.deleteId)"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.updateCommentDialogue">
      <q-card class="commentCard">
      <div class="insideCard">
        <h1 class="comments">Upravit komentář</h1>
        <q-input
            label="Text Komentáře:"
            color="purple"
            v-model="updateCommentText"
            filled
            autogrow
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
        />
      </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Upravit komentář" flat @click="updateComment()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>








  <div class="mainContainer mobile" v-if="this.mobileStore.mobile">

    <q-form v-on:submit="updateAction()">
      <div v-if="this.userStore.user.role === 'admin'">
        <h1>Upravit akci:</h1>
        <q-input
            color="purple" square filled
            v-model="name"
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
            label="Název akce:" />
        <q-select
            color="purple" filled
            v-model="selectedPlace"
            :options="place"
            :rules="[ val => val && val.length > 0 || 'Zadejte místo konání akce']"
            label="Místo konání akce *" />
        <br>

        <div class="q-gutter-md row justify-center">
          <q-date v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                  :rules="[ val => val && val.length > 0 || 'Vyberte prosím datum.']"
          />
          <q-time v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                  :rules="[ val => val && val.length > 0 || 'Vyberte prosím čas.']"
          />
        </div><br><br>

        <q-input
            label="Popis akce"
            color="purple"
            v-model="text"
            filled
            autogrow
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
        /><br>
        <p class="error" v-if="this.error">{{this.errorMessage}}</p>
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit akci" flat type="submit" /><br><br>
        <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit akci" @click="changedeleteState(this.plannedActionsStore.actionDetail._id, this.plannedActionsStore.actionDetail.actionName)"/><br><br>
      </div>
    </q-form>

    <q-dialog v-model="this.deleteDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění akce</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit akci <b>{{this.deleteName}}</b>?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteAction(this.deleteId)"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-card>
      <q-card-section>
        <h1>{{this.plannedActionsStore.actionDetail.actionName}}</h1>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section horizontal>
        <p class="actionText">{{this.plannedActionsStore.actionDetail.text}}</p>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <p class="actionText"><b>Místo konání akce: </b>{{this.plannedActionsStore.actionDetail.place}}</p>
        <p class="actionText"><b>Čas konání akce: </b>{{this.plannedActionsStore.actionDetail.date}}</p>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <p><b><a class="clickableUser" href="#" @click="getDetails(this.plannedActionsStore.actionDetail.author)">{{this.plannedActionsStore.actionDetail.author}}</a></b> {{this.plannedActionsStore.actionDetail.dateCreated}}</p>
        <p v-if="this.plannedActionsStore.actionDetail.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(this.plannedActionsStore.actionDetail.updateAuthor)">{{this.plannedActionsStore.actionDetail.updateAuthor}}</a></b> {{this.plannedActionsStore.actionDetail.updateTime}}</p>
      </q-card-section>
    </q-card> <br>
    <div v-if="!this.plannedActionsStore.attending && (this.userStore.user.role === 'member' || this.userStore.user.role === 'admin')">
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Zúčastním se" flat @click="attend()"/><br><br>
    </div>
    <div v-if="this.plannedActionsStore.attending && (this.userStore.user.role === 'member' || this.userStore.user.role === 'admin')">
      <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Zrušit účast" @click="attendNot()"/><br><br>
    </div>

    <h1>Účastnící: </h1>

    <div v-for="attendee in this.plannedActionsStore.attendees" :key="attendee" id="attendeesShowedMobile">
      <q-card class="userName">
        <q-card-section horizontal>
          <img v-bind:src="attendee.user[0].profilePicture" alt="Obrázek uživatele" class="userPicture"/>
          <h1><a class="clickableUser clickableUserBig" href="#" @click="getDetails(attendee.attendee)">{{attendee.attendee}}</a></h1>
        </q-card-section>
      </q-card><br>
    </div>

    <div class="comments mobile">
      <h1 class="comments">Přidat komentář</h1>
      <q-input
          label="Text Komentáře:"
          color="purple"
          v-model="commentText"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Přidat komentář" flat @click="postComment()"/><br><br>
    </div>

    <p class="noComment" v-if="this.plannedActionsStore.actionComments.length === 0"><b>Žádné komentáře</b></p>

    <div v-for="(comments) in getData()" :key="comments._id" id="applicationsShowedMobile">
      <q-card>
        <q-card-section>
          <p><b><a class="clickableUser" href="#" @click="getDetails(comments.nickname)">{{comments.nickname}}</a></b> {{comments.date}}</p>
          <p v-if="comments.updateDate !== null"><b>Upraveno: </b>{{comments.updateDate}}</p>
          <p v-if="comments.user[0].role === 'admin'"><b>Velitel outfitu</b></p>
        </q-card-section>
        <q-separator />
        <q-card-section horizontal>
          <q-card-section>
            <p>{{comments.text}}</p>
          </q-card-section>
        </q-card-section><br>
      </q-card><br>
      <div v-if="comments.nickname === this.userStore.user.nickname">
        <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Upravit komentář" flat @click="changeUpdatingStatus(comments._id, comments.text)"/><br><br>
      </div>
      <div v-if="comments.nickname === this.userStore.user.nickname || this.userStore.user.role === 'admin'">
        <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit komentář" flat @click="getDeleteID(comments._id)"/><br><br>
      </div>
    </div>

    <q-dialog v-model="this.deleteCommentDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění komentáře</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit komentář?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteComment(this.deleteId)"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.updateCommentDialogue">
      <q-card class="commentCard">
        <div class="insideCard">
          <h1 class="comments">Upravit komentář</h1>
          <q-input
              label="Text Komentáře:"
              color="purple"
              v-model="updateCommentText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Upravit komentář" flat @click="updateComment()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {usePlannedActionsStore} from "@/stores/plannedActionsStore";
import {useMobileStore} from "@/stores/MobileStore";
import {useUserStore} from "@/stores/UserStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";

export default {
  name: "ActionDetailView",

  setup(){
    return {
      place: [
        'Indar', 'Esamir', 'Amerish', 'Hossin', 'Oshur', 'Podle dostupnosti',
      ],
    }
  },

  data() {
    return {
      name: null,
      text: null,
      image: null,
      author: null,
      date: null,
      datePosted: null,
      dateUpdated: null,
      updateAuthor: null,
      selectedPlace: null,
      time: null,
      error: null,
      errorMessage: null,
      deleteDialogue: null,
      deleteId: null,
      deleteName: null,
      attending: null,
      page: 1,
      totalPages: null,
      commentText: null,
      deleteCommentDialogue: null,
      deleteCommentId: null,
      updateCommentText: null,
      updateCommentDialogue: null,
      updateCommentID: null,
    }
  },

  async created() {
    this.name = this.plannedActionsStore.actionDetail.actionName;
    this.text = this.plannedActionsStore.actionDetail.text;
    this.image = this.plannedActionsStore.actionDetail.image;
    this.selectedPlace = this.plannedActionsStore.actionDetail.place;
    this.author = this.plannedActionsStore.actionDetail.author;
    this.updateAuthor = this.plannedActionsStore.actionDetail.updateAuthor;
    this.date = this.plannedActionsStore.actionDetail.date;
    this.datePosted = this.plannedActionsStore.actionDetail.dateCreated;
    this.dateUpdated = this.plannedActionsStore.actionDetail.updateTime;
    await this.plannedActionsStore.getAttendees(this.userStore.user.nickname, this.plannedActionsStore.actionDetail._id);
    await this.plannedActionsStore.amIAttending(this.userStore.user.nickname);
    await this.plannedActionsStore.getComments(this.userStore.user.nickname);
    this.getData();
  },

  computed: {
    ...mapStores(usePlannedActionsStore),
    ...mapStores(useMobileStore),
    ...mapStores(useUserStore),
    ...mapStores(useAccountDetailStore),
  },

  methods: {
    async updateAction(){
      try {
        if (this.selectedPlace === 'Indar'){
          this.image = 'indar.png';
        } else if (this.selectedPlace === 'Oshur'){
          this.image = 'oshur.png';
        } else if (this.selectedPlace === 'Amerish') {
          this.image = 'amerish.png';
        } else if (this.selectedPlace === 'Esamir') {
          this.image = 'esamir.png';
        } else if (this.selectedPlace === 'Hossin') {
          this.image = 'hossin.png';
        } else {
          this.image = 'auraxis.png';
        }
        await this.plannedActionsStore.updateAction(this.name, this.text, this.date, this.selectedPlace, this.image,this.userStore.user.nickname, this.plannedActionsStore.actionDetail._id);
        this.error = false;
        await this.plannedActionsStore.getActions();
        await this.plannedActionsStore.ActionDetails(this.plannedActionsStore.actionDetail._id, this.userStore.user.nickname);
        this.$router.push({name: 'actionDetail'});
      } catch {
        this.error = true;
        this.errorMessage = 'Chyba při přidání události. Zkuste prosím později.';
      }
    },

    async deleteAction(){
      try {
        await this.plannedActionsStore.deleteAction(this.deleteId, this.userStore.user.nickname);
        await this.plannedActionsStore.getActions();
        this.$router.push({name: 'plannedActions'});
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = 'Chyba při odstranení akce. Zkuste prosím podzěji.'
      }
    },

    changedeleteState(id, name){
      this.deleteName = name;
      this.deleteId = id;
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

    async getDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async attend(){
      try {
        await this.plannedActionsStore.attend(this.userStore.user.nickname, this.plannedActionsStore.actionDetail._id);
        await this.plannedActionsStore.getAttendees(this.userStore.user.nickname, this.plannedActionsStore.actionDetail._id);
        await this.plannedActionsStore.amIAttending(this.userStore.user.nickname);
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async attendNot(){
      try {
        await this.plannedActionsStore.attendCancel(this.plannedActionsStore.actionDetail._id, this.userStore.user.nickname);
        await this.plannedActionsStore.getAttendees(this.userStore.user.nickname, this.plannedActionsStore.actionDetail._id);
        await this.plannedActionsStore.amIAttending(this.userStore.user.nickname);
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async postComment(){
      try {
        await this.plannedActionsStore.comment(this.userStore.user.nickname, this.commentText);
        this.getData();
        this.error = null;
      } catch {
        this.error = true;
      }
    },

    getData() {
      return this.plannedActionsStore.actionComments;
    },

    getDeleteID(id){
      this.deleteCommentId = id;
      if (this.deleteDialogue) {
        this.deleteCommentDialogue = false;
      } else {
        this.deleteCommentDialogue = true;
      }
    },

    async deleteComment(){
      try {
        await this.plannedActionsStore.deleteComment(this.userStore.user.nickname, this.deleteCommentId);
        this.getData();
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    changeUpdatingStatus(id, text){
      this.updateCommentID = id;
      this.updateCommentText = text;
      if (this.updateCommentDialogue) {
        this.updateCommentDialogue = false;
      } else {
        this.updateCommentDialogue = true;
      }
    },

    async updateComment(){
      try {
        await this.plannedActionsStore.updateComment(this.userStore.user.nickname, this.updateCommentText, this.updateCommentID);
        this.getData();
        this.updateCommentDialogue = false;
        this.error = false;
      } catch {
        this.error = true;
      }
    },
  }
}
</script>

<style scoped>
  .mainContainer {
    width: 50%;
    margin: auto;
  }

  .mainContainer h1{
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .mobile {
    width: 90%;
  }

  .all-page-button {
    width: 100%;
  }

  .actionPicture {
    width: 25%;
  }

  .actionText {
    white-space: pre-wrap;
    margin-left: 10px;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .userName h1 {
    font-size: 40px;
  }

  .userPicture {
    margin: 10px 10px 10px 10px;
    width: 75px;
  }

  .clickableUserBig{
    font-size: 40px;
  }

  .comments {
    width: 100%;
  }

  .comments h1 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0px 20px 0px;
  }

  .noComment {
    font-size: 20px;
    text-align: center;
  }

  .newsPicture {
    height: 150px;
    margin: 10px 10px 10px 10px;
  }

  .commentCard {
    margin: 10px 10px 10px 10px;
    width: 100%;
  }

  .commentCard h1 {
    font-size: 20px;
  }

  .insideCard {
    width: 95%;
    margin: auto;
  }

  .comments {
    width: 100%;
  }

  .comments h1 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0px 20px 0px;
  }
</style>