<template>
  <div class="adminContainer" v-if="!this.mobileStore.mobile">

    <h1>Otevřené reporty</h1>

    <h1>Reportovaná témata</h1>

    <div v-if="this.reportStore.reportsTheme.length === 0">
      <h1 style="text-align: center">Žádné reporty :)</h1>
    </div>

    <div v-for="themeReport in this.reportStore.reportsTheme" :key="themeReport">
      <b>Repurtujici: </b> {{themeReport.author}}<br>
      <b>Datum reportu:</b> {{themeReport.reportDate}}<br>
      <b>Reportovaný uživatel:</b> {{themeReport.reportedUserName}}
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail reportu" flat @click="getThemeDetail(themeReport._id)"/><br><br>
    </div>
    <q-separator></q-separator>
    <h1>Reportované komentáře:</h1>

    <div v-if="this.reportStore.reportsReply.length === 0">
      <h1 style="text-align: center">Žádné reporty :)</h1>
    </div>

    <div v-for="replyReport in this.reportStore.reportsReply" :key="replyReport">
      <b>Repurtujici: </b> {{replyReport.author}}<br>
      <b>Datum reportu:</b> {{replyReport.reportDate}}<br>
      <b>Reportovaný uživatel:</b> {{replyReport.reportedUserName}}
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail reportu" flat @click="getReplyDetail(replyReport._id)"/><br><br>
    </div>


    <q-dialog v-model="this.showReplyDetail">
      <q-card class="dialog">
        <q-card-section>
            <b>Repurtujici: </b> {{this.reportStore.replyDetail.author}}<br>
            <b>Datum reportu:</b> {{this.reportStore.replyDetail.reportDate}}<br>
            <b>Reportovaný uživatel:</b> {{this.reportStore.replyDetail.reportedUserName}}<br>
            <b>Důvod reportu:</b> {{this.reportStore.replyDetail.reportText}}<br><br>
            <b>Příspěvek:</b><br>
          {{this.reportStore.replyDetail.reportedText}}<br><br>
          <b>Status:</b> {{this.reportStore.replyDetail.status}}
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Není zavadné" color="purple" v-close-popup @click="completeReplyReport()"/><br><br>
          <q-btn class="bg-red text-white shadow-2 all-page-button" label="Odstranit komentář" v-close-popup @click="deleteComment()"/><br><br><br>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Zavřít" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>


    <q-dialog v-model="this.showThemeDetail">
      <q-card class="dialog">
        <q-card-section>
          <b>Repurtujici: </b> {{this.reportStore.themeDetail.author}}<br>
          <b>Datum reportu:</b> {{this.reportStore.themeDetail.reportDate}}<br>
          <b>Reportovaný uživatel:</b> {{this.reportStore.themeDetail.reportedUserName}}<br>
          <b>Důvod reportu:</b> {{this.reportStore.themeDetail.reportText}}<br><br>
          <b>Název příspěvku:</b><br>
          {{this.reportStore.themeDetail.reportedThemeName}}<br><br>
          <b>Příspěvek:</b><br>
          {{this.reportStore.themeDetail.reportedText}}<br><br>
          <b>Status:</b> {{this.reportStore.themeDetail.status}}
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Není zavadné" color="purple" v-close-popup @click="completeThemeReport()"/><br><br>
          <q-btn class="bg-red text-white shadow-2 all-page-button" label="Odstranit téma" v-close-popup @click="deleteTheme()"/><br><br><br>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Zavřít" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>







  <div class="adminContainer mobile" v-if="this.mobileStore.mobile">

    <h1>Otevřené reporty</h1>

    <h1>Reportovaná témata</h1>

    <div v-if="this.reportStore.reportsTheme.length === 0">
      <h1 style="text-align: center">Žádné reporty :)</h1>
    </div>

    <div v-for="themeReport in this.reportStore.reportsTheme" :key="themeReport">
      <b>Repurtujici: </b> {{themeReport.author}}<br>
      <b>Datum reportu:</b> {{themeReport.reportDate}}<br>
      <b>Reportovaný uživatel:</b> {{themeReport.reportedUserName}}
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail reportu" flat @click="getThemeDetail(themeReport._id)"/><br><br>
    </div>
    <q-separator></q-separator>
    <h1>Reportované komentáře:</h1>

    <div v-if="this.reportStore.reportsReply.length === 0">
      <h1 style="text-align: center">Žádné reporty :)</h1>
    </div>

    <div v-for="replyReport in this.reportStore.reportsReply" :key="replyReport">
      <b>Repurtujici: </b> {{replyReport.author}}<br>
      <b>Datum reportu:</b> {{replyReport.reportDate}}<br>
      <b>Reportovaný uživatel:</b> {{replyReport.reportedUserName}}
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail reportu" flat @click="getReplyDetail(replyReport._id)"/><br><br>
    </div>


    <q-dialog v-model="this.showReplyDetail">
      <q-card class="dialog">
        <q-card-section>
          <b>Repurtujici: </b> {{this.reportStore.replyDetail.author}}<br>
          <b>Datum reportu:</b> {{this.reportStore.replyDetail.reportDate}}<br>
          <b>Reportovaný uživatel:</b> {{this.reportStore.replyDetail.reportedUserName}}<br>
          <b>Důvod reportu:</b> {{this.reportStore.replyDetail.reportText}}<br><br>
          <b>Příspěvek:</b><br>
          {{this.reportStore.replyDetail.reportedText}}<br><br>
          <b>Status:</b> {{this.reportStore.replyDetail.status}}
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Není zavadné" color="purple" v-close-popup @click="completeReplyReport()"/><br><br>
          <q-btn class="bg-red text-white shadow-2 all-page-button" label="Odstranit komentář" v-close-popup @click="deleteComment()"/><br><br><br>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Zavřít" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>


    <q-dialog v-model="this.showThemeDetail">
      <q-card class="dialog">
        <q-card-section>
          <b>Repurtujici: </b> {{this.reportStore.themeDetail.author}}<br>
          <b>Datum reportu:</b> {{this.reportStore.themeDetail.reportDate}}<br>
          <b>Reportovaný uživatel:</b> {{this.reportStore.themeDetail.reportedUserName}}<br>
          <b>Důvod reportu:</b> {{this.reportStore.themeDetail.reportText}}<br><br>
          <b>Název příspěvku:</b><br>
          {{this.reportStore.themeDetail.reportedThemeName}}<br><br>
          <b>Příspěvek:</b><br>
          {{this.reportStore.themeDetail.reportedText}}<br><br>
          <b>Status:</b> {{this.reportStore.themeDetail.status}}
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Není zavadné" color="purple" v-close-popup @click="completeThemeReport()"/><br><br>
          <q-btn class="bg-red text-white shadow-2 all-page-button" label="Odstranit téma" v-close-popup @click="deleteTheme()"/><br><br><br>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" label="Zavřít" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useMobileStore} from "@/stores/MobileStore";
import {useReportStore} from "@/stores/ReportsStore";
import {useUserStore} from "@/stores/UserStore";
import {useDiscussionStore} from "@/stores/DiscussionStore";

export default {
  name: "OpenReportsView",

  data(){
    return {
      showThemeDetail: null,
      showReplyDetail: null,
    }
  },

  computed: {
    ...mapStores(useMobileStore),
    ...mapStores(useReportStore),
    ...mapStores(useUserStore),
    ...mapStores(useDiscussionStore)
  },

  async created(){
    await this.reportStore.getReplyReports(this.userStore.user.nickname);
    await this.reportStore.getThemeReports(this.userStore.user.nickname);
  },

  methods: {
    async getReplyDetail(id){
      try {
        await this.reportStore.getReplyDetail(this.userStore.user.nickname, id);
        this.showReplyDetail = true;
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async completeReplyReport(){
      try {
        await this.reportStore.changeReportReplyState(this.userStore.user.nickname, this.reportStore.replyDetail._id, 'Vyřízeno');
        await this.reportStore.getReplyReports(this.userStore.user.nickname);
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async deleteComment(){
      try {
        await this.reportStore.changeReportReplyState(this.userStore.user.nickname, this.reportStore.replyDetail._id, 'Příspěvěk odstraněn');
        await this.discussionStore.deleteComment(this.userStore.user.nickname,this.reportStore.replyDetail.reportedCommentID);
        await this.reportStore.getReplyReports(this.userStore.user.nickname);
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async getThemeDetail(id){
      try {
        await this.reportStore.getThemeDetail(this.userStore.user.nickname, id);
        this.showThemeDetail = true;
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async completeThemeReport(){
      try {
        await this.reportStore.changeReportThemeState(this.userStore.user.nickname, this.reportStore.themeDetail._id, 'Vyřízeno');
        await this.reportStore.getThemeReports(this.userStore.user.nickname);
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async deleteTheme(){
      try {
        await this.reportStore.changeReportThemeState(this.userStore.user.nickname, this.reportStore.themeDetail._id, 'Příspěvěk odstraněn');
        await this.discussionStore.deleteThemeReport(this.userStore.user.nickname,this.reportStore.themeDetail.reportedCommentID);
        await this.reportStore.getThemeReports(this.userStore.user.nickname);
        this.error = false;
      } catch {
        this.error = true;
      }
    },

  }
}
</script>

<style scoped>

  .adminContainer {
    width: 50%;
    margin: auto;
    font-size: 20px;
  }

  .adminContainer h1{
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .mobile {
    width: 90%;
  }

  .all-page-button {
    width: 100%;
  }

  .dialog {
    width: 100%;
  }
</style>