<template>
    <div class="mainContainer" v-if="!this.mobileStore.mobile">
      <h1>Užitečné odkazy</h1>
      <a class="link" href="https://www.planetside2.com/home">Oficiální web Planetside 2</a><br>
      <a class="link" href="https://store.steampowered.com/app/218230/PlanetSide_2/">Steam stránka Planetside 2</a><br>
      <a class="link" href="https://ps2.fisu.pw/">Web FISU pro hledání statistik postav a outfitů</a><br>
      <a class="link" href="https://wt.honu.pw/">Web Honu pro hledání statistik postav a outfitů</a><br>
      <a class="link" href="https://www.reddit.com/r/Planetside/">Reddit komunita Planetside 2</a><br>
      <a class="link" href="https://www.facebook.com/PlanetSide2/">Oficiální Facebook Planetside 2</a><br>
      <a class="link" href="https://twitter.com/planetside2?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">Oficiální Twitter Planetside 2</a><br>
    </div>

    <div class="mainContainer mobile" v-if="this.mobileStore.mobile">
      <h1>Užitečné odkazy</h1>
      <a class="link" href="https://www.planetside2.com/home">Oficiální web Planetside 2</a><br>
      <a class="link" href="https://store.steampowered.com/app/218230/PlanetSide_2/">Steam stránka Planetside 2</a><br>
      <a class="link" href="https://ps2.fisu.pw/">Web FISU pro hledání statistik postav a outfitů</a><br>
      <a class="link" href="https://wt.honu.pw/">Web Honu pro hledání statistik postav a outfitů</a><br>
      <a class="link" href="https://www.reddit.com/r/Planetside/">Reddit komunita Planetside 2</a><br>
      <a class="link" href="https://www.facebook.com/PlanetSide2/">Oficiální Facebook Planetside 2</a><br>
      <a class="link" href="https://twitter.com/planetside2?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">Oficiální Twitter Planetside 2</a><br>
    </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useMobileStore} from "@/stores/MobileStore";

export default {
  name: "UsefulLinksView",
  computed: {
    ...mapStores(useMobileStore)
  },
}
</script>

<style scoped>

.mainContainer{
  width: 50%;
  margin: auto;
}

.mainContainer h1 {
  font-size: 25px;
}

.mobile {
  width: 90%;
}

.link {
  color: purple;
  text-decoration: none;
  font-size: 25px;
}

.link:hover {
  color: #008080;
}
</style>