import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useVehicleGuideStore = defineStore('vehicleGuide', {
    state: () => ({
        error: null,
        guides: [],
        lastID: null,
        role: null,
        guideDetail: null,
    }),

    actions: {
        async addGuide(nickname, name, description, text, weapon, harvest, topGun, secondTopGun,
        wings, bellyGun, tailGun, leftGun, rightGun, frontLeftGun, frontRightGun, backLeftGun, backRightGun, utility,
        defence, performance, logistics, vehicle){
            try {
                const results = await axios.post(config.backendUrl + '/addVehicleGuide', {
                    author: nickname,
                    updateAuthor: null,
                    name: name,
                    description: description,
                    text: text,
                    date: null,
                    updateDate: null,
                    vehicle: vehicle,
                    weapon: weapon,
                    harvest: harvest,
                    topGun: topGun,
                    secondTopGun: secondTopGun,
                    wings: wings,
                    bellyGun: bellyGun,
                    tailGun: tailGun,
                    leftGun: leftGun,
                    rightGun: rightGun,
                    frontLeftGun: frontLeftGun,
                    frontRightGun: frontRightGun,
                    backLeftGun: backLeftGun,
                    backRightGun: backRightGun,
                    utility: utility,
                    defence: defence,
                    performance: performance,
                    logistics: logistics
                });
                this.lastID = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getGuides(){
            try {
                const results = await axios.get(config.backendUrl + '/getVehicleGuides', {

                });
                this.guides = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getGuideDetail(id){
            try {
                const response = await axios.post(config.backendUrl + '/vehicleDetail', {
                   ID: id,
                });
                this.guideDetail = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async updateGuide(nickname, name, description, text, weapon, harvest, topGun, secondTopGun,
                       wings, bellyGun, tailGun, leftGun, rightGun, frontLeftGun, frontRightGun, backLeftGun, backRightGun, utility,
                       defence, performance, logistics){
            try {
                await axios.post(config.backendUrl + '/updateVehicleGuide', {
                    id: this.guideDetail._id,
                    updateAuthor: nickname,
                    name: name,
                    description: description,
                    text: text,
                    updateDate: null,
                    weapon: weapon,
                    harvest: harvest,
                    topGun: topGun,
                    secondTopGun: secondTopGun,
                    wings: wings,
                    bellyGun: bellyGun,
                    tailGun: tailGun,
                    leftGun: leftGun,
                    rightGun: rightGun,
                    frontLeftGun: frontLeftGun,
                    frontRightGun: frontRightGun,
                    backLeftGun: backLeftGun,
                    backRightGun: backRightGun,
                    utility: utility,
                    defence: defence,
                    performance: performance,
                    logistics: logistics
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteGuide(nickname){
            try {
                await axios.post(config.backendUrl + '/deleteVehicleGuide',{
                   id: this.guideDetail._id,
                   nickname: nickname,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

    },

    persist: true
})