<template>
  <div class="mobile">
    <div class="onlineInside">
      <q-card>
        <br>
        <p class="mainText">Přihlášení uživatelé:</p>
        <q-separator></q-separator>
        <p class="mainText">Velitelé:</p>
        <div class="nicknames" v-for="admin in this.daybreakStore.onlineAdmins" :key="admin">
          <a class="clickableUser" href="#" @click="getDetails(admin)">{{admin}}</a>
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineAdmins.length === 0">Nikdo není přihlášen :(</p>
        <br>
        <q-separator></q-separator>
        <p class="mainText">Členové komunity:</p>
        <div class="nicknames" v-for="member in this.daybreakStore.onlineMembers" :key="member">
          <a class="clickableUser" href="#" @click="getDetails(member)">{{member}}</a>
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineMembers.length === 0">Nikdo není přihlášen :(</p>
        <br>
        <q-separator></q-separator>
        <p class="mainText">Uživatelé mimo OLT:</p>
        <div class="nicknames" v-for="user in this.daybreakStore.onlineUsers" :key="user">
          <a class="clickableUser" href="#" @click="getDetails(user)">{{user}}</a>
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineUsers.length === 0">Nikdo není přihlášen :(</p>
        <br>
      </q-card>
    </div><br><br>


    <div class="onlineInside">
      <q-card>
        <br>
        <p class="mainText">Online členové outfitu:</p>
        <q-separator></q-separator>
        <q-avatar>
          <img src="../icons/VS.png" alt="Vanu Sovereignty Logo">
        </q-avatar>
        VS:
        <q-separator></q-separator>
        <div class="nicknames" v-for="vs in this.daybreakStore.onlineIngameVS" :key="vs">
          {{vs.nickname}}
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineIngameVS.length === 0">Nikdo online :(</p>
        <q-separator></q-separator>
        <q-avatar>
          <img src="../icons/TR.png" alt="Logo Terranské republiky">
        </q-avatar>
        TR:
        <q-separator></q-separator>
        <div class="nicknames" v-for="tr in this.daybreakStore.onlineIngameTR" :key="tr">
          {{tr.nickname}}
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineIngameTR.length === 0">Nikdo online :(</p>
        <br>
      </q-card>
    </div>

  <br><br>

  </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useDaybreakStore} from "@/stores/DayBreakStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";

export default {
  name: "OnlineMobileView",

  async created(){
    await this.daybreakStore.getOnlineAdmins();
    await this.daybreakStore.getOnlineMembers();
    await this.daybreakStore.getOnlineUsers();
    await this.daybreakStore.getOnlineVS();
    await this.daybreakStore.getOnlineTR();
  },

  data(){
    return {

    }
  },

  computed: {
    ...mapStores(useDaybreakStore),
    ...mapStores(useAccountDetailStore)
  },

  methods: {
    async getDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    }
  },

}


</script>

<style scoped>
  .mobile {
    width: 90%;
    margin: auto;
  }


  .mainText {
    margin: 10px 10px 10px 10px;
  }

  .online h2{
    font-size: 20px;
  }

  .nicknames{
    font-size: 15px;
    margin-left: 10px;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 18px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .onlineInside {
    border-style: solid;
    border-color: lightgray;
    border-width: 2px;
    font-size: 20px;
  }
</style>