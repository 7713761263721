<template>
  <div class="mainContainer" v-if="!this.mobileStore.mobile">
    <div>
      <div v-if="this.userStore.user.nickname === this.discussionStore.themeDetail.author || this.userStore.user.role === 'admin'">
        <q-form v-on:submit="updateTheme()">
          <q-card>
            <q-input
              color="purple" square filled
              v-model="updateName"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
              label="Název tématu:" />

          <q-input
              label="O čem chcete diskutovat/Jaký je Váš dotaz?"
              color="purple"
              v-model="updateText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
          /><br>
            <q-separator></q-separator>
            <b><a class="themeText clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.author)">{{this.discussionStore.themeDetail.author}}</a></b> {{this.discussionStore.themeDetail.date}}
            <p class="themeText" v-if="this.discussionStore.themeDetail.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.updateAuthor)">{{this.discussionStore.themeDetail.updateAuthor}}</a></b> {{this.discussionStore.themeDetail.updateDate}}</p>
          </q-card>
          <div v-if="this.userStore.user.nickname === this.discussionStore.themeDetail.author || this.userStore.user.role === 'admin'">
            <br>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit téma" type="submit"/><br><br>
          </div>
        </q-form>
        <div v-if="this.userStore.user.role === 'admin'">
          <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit téma" @click="this.changeDeleteThemeState(this.discussionStore.themeDetail.name)"/><br><br>
        </div>

      </div>


      <q-card v-else>
        <h1>{{this.discussionStore.themeDetail.name}}</h1>
        <q-separator></q-separator>
        <p class="themeText">{{this.discussionStore.themeDetail.text}}</p><br>
        <q-separator></q-separator>
        <b><a class="themeText clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.author)">{{this.discussionStore.themeDetail.author}}</a></b> {{this.discussionStore.themeDetail.date}}
        <p class="themeText" v-if="this.discussionStore.themeDetail.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.updateAuthor)">{{this.discussionStore.themeDetail.updateAuthor}}</a></b> {{this.discussionStore.themeDetail.updateDate}}</p>
        <q-separator></q-separator>
        <a class="themeText clickableUser" href="#" @click.prevent="changeReportingThemeStatus">Nahlásit téma</a>
      </q-card>
  </div>



    <h1>Odpovědi:</h1>

    <q-form v-on:submit="addComment()">
      <q-input
          label="Přidat komentář:"
          color="purple"
          v-model="commentText"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Přidat komentář" type="submit"/><br><br>
    </q-form>


    <div v-if="this.discussionStore.themeDetailComments.length === 0">
      <h1 class="empty">Žádné odpovědi  :(</h1>
    </div>

    <div v-for="comments in getData()" :key="comments._id" id="reply">
      <q-card>
        <q-card-section>
            <b><a class="clickableUser" href="#" @click="getDetails(comments.author)">{{comments.author}}</a></b> {{comments.date}}
            <div v-if="comments.updateDate !== null">
              <b>Upraveno: </b>{{comments.updateDate}}
            </div>
            <div v-if="comments.user[0].role === 'admin'">
              <b>Velitel outfitu</b>
            </div>
          <div v-if="comments.user[0].role === 'member'">
            <b>Člen OLT</b>
          </div>
        </q-card-section>
        <q-separator></q-separator>
          <q-card-section horizontal>
            <img v-bind:src="comments.user[0].profilePicture" alt="Obrázek uživatele" class="profilePicture"/>
            <q-card-section>
              <div v-if="comments.quoteAuthor !== null" class="quote">
                <b>{{ comments.quoteAuthor }}</b> {{comments.quoteDate}}<br>
                "{{comments.quoteText}}"<br><br>
              </div>
              {{comments.text}}
            </q-card-section>
          </q-card-section>
        <q-separator></q-separator>
        <q-card-section>
          <q-card-section-vertical>
            <a class="themeText clickableUser" href="#" @click.prevent="changeQuoteState(comments.text, comments.author, comments.date)">Odpověděť</a>
          </q-card-section-vertical>
          <q-card-section-vertical style="float: right">
            <a class="themeText clickableUser" href="#" @click.prevent="changeReportingCommentState(comments.text, comments.author, comments.date, comments._id)">Nahlásit příspěvek</a>
          </q-card-section-vertical>
        </q-card-section>
      </q-card><br>
      <div v-if="comments.author === this.userStore.user.nickname">
        <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Upravit komentář" flat @click="changeCommentUpdateStatus(comments._id, comments.text)"/><br><br>
      </div>
      <div v-if="comments.author === this.userStore.user.nickname || this.userStore.user.role === 'admin'">
        <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit komentář" flat @click="changeCommentDeleteStatus(comments._id)"/><br><br>
      </div>
    </div>


    <q-dialog v-model="this.deletingTheme">
      <q-card class="dialog">
        <q-card-section>
          <div class="text-h6">Odstranění diskuze</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit diskuzi <b>{{this.discussionStore.themeDetail.name}}</b>?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteTheme()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>


      <q-dialog v-model="this.quoting" style="width: 100%">
        <q-card style="width: 100%">
          <q-card-section>
            <b>Původní komentář</b><br><br>
            <div class="quote">
              <b>{{ this.quoteAuthor }}</b> {{this.quoteTime}}<br>
              "{{this.quoteText}}"<br><br><br>
            </div>
          </q-card-section>
          <q-card-section>
            <b>Váš komentář:</b>
          </q-card-section>
          <q-card-section>
            <q-form v-on:submit="addComment()" style="width: 100%">
              <q-input
                  label="Přidat komentář:"
                  color="purple"
                  v-model="commentText"
                  filled
                  autogrow
                  :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
                  style="width: 100%; margin-top: 0px"
              />
              <q-card-actions>
                <q-btn class="bg-purple text-white shadow-2" align="center" label="Přidat komentář" type="submit"/>
                <q-space />
                <q-btn flat label="Zrušit" color="purple" v-close-popup/>
              </q-card-actions>
            </q-form>
          </q-card-section>
        </q-card>
      </q-dialog>


    <q-dialog v-model="this.deletingComment">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění komentáře</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit komentář?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteComment()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.updatingComment">
      <q-card class="commentCard" style="width: 100%">
        <div class="insideCard">
          <h1 class="comments" style="font-size: 25px; margin-left: 10px">Upravit komentář</h1>
          <q-input
              label="Text Komentáře:"
              color="purple"
              v-model="commentUpdateText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
              style="width: 97%; margin: auto"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Upravit komentář" flat @click="updateComment()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.reportingComment">
      <q-card class="commentCard" style="width: 100%">
        <div class="insideCard">
          <q-card-section>
            <b>Hlášený komentář</b><br><br>
            <div class="quote">
              <b>{{ this.quoteAuthor }}</b> {{this.quoteTime}}<br>
              "{{this.quoteText}}"<br><br><br>
            </div>
          </q-card-section>
          <b style="margin-left: 10px">Důvod hlášení</b>
          <q-input
              label="Jaký je s komentářem problém?"
              color="purple"
              v-model="reportText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
              style="width: 97%; margin: auto"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Nahlásit komentář" flat @click="reportComment()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>


    <q-dialog v-model="this.reportingTheme">
      <q-card class="commentCard" style="width: 100%">
        <div class="insideCard">
          <q-card-section>
            <b>Hlášené téma:</b><br><br>
            <div class="quote">
              "{{this.discussionStore.themeDetail.name}}"<br><br><br>
            </div>
          </q-card-section>
          <b style="margin-left: 10px">Důvod hlášení</b>
          <q-input
              label="Jaký je s tématem problém?"
              color="purple"
              v-model="reportText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
              style="width: 97%; margin: auto"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Nahlásit téma" flat @click="reportTheme()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.reportSuccess">
      <q-card class="commentCard" style="width: 100%">
          <q-card-section>
            Děkujeme,<br>
            Report byl úspěšně podán. O jeho vyhodnocení nebudete informování, ale naši velitelé každý report berou vážně.<br>
          </q-card-section>
          <q-btn flat label="Zavřít" color="purple" v-close-popup/>
      </q-card>
    </q-dialog>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>

  <div class="mainContainer mobile" v-if="this.mobileStore.mobile">
    <div>
      <div v-if="this.userStore.user.nickname === this.discussionStore.themeDetail.author || this.userStore.user.role === 'admin'">
        <q-form v-on:submit="updateTheme()">
          <q-card>
            <q-input
                color="purple" square filled
                v-model="updateName"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
                label="Název tématu:" />

            <q-input
                label="O čem chcete diskutovat/Jaký je Váš dotaz?"
                color="purple"
                v-model="updateText"
                filled
                autogrow
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
            /><br>
            <q-separator></q-separator>
            <b><a class="themeText clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.author)">{{this.discussionStore.themeDetail.author}}</a></b> {{this.discussionStore.themeDetail.date}}
            <p class="themeText" v-if="this.discussionStore.themeDetail.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.updateAuthor)">{{this.discussionStore.themeDetail.updateAuthor}}</a></b> {{this.discussionStore.themeDetail.updateDate}}</p>
          </q-card>
          <div v-if="this.userStore.user.nickname === this.discussionStore.themeDetail.author || this.userStore.user.role === 'admin'">
            <br>
            <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit téma" type="submit"/><br><br>
          </div>
        </q-form>
        <div v-if="this.userStore.user.role === 'admin'">
          <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit téma" @click="this.changeDeleteThemeState(this.discussionStore.themeDetail.name)"/><br><br>
        </div>

      </div>


      <q-card v-else>
        <h1>{{this.discussionStore.themeDetail.name}}</h1>
        <q-separator></q-separator>
        <p class="themeText">{{this.discussionStore.themeDetail.text}}</p><br>
        <q-separator></q-separator>
        <b><a class="themeText clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.author)">{{this.discussionStore.themeDetail.author}}</a></b> {{this.discussionStore.themeDetail.date}}
        <p class="themeText" v-if="this.discussionStore.themeDetail.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(this.discussionStore.themeDetail.updateAuthor)">{{this.discussionStore.themeDetail.updateAuthor}}</a></b> {{this.discussionStore.themeDetail.updateDate}}</p>
        <q-separator></q-separator>
        <a class="themeText clickableUser" href="#" @click.prevent="changeReportingThemeStatus">Nahlásit téma</a>
      </q-card>
    </div>



    <h1>Odpovědi:</h1>

    <q-form v-on:submit="addComment()">
      <q-input
          label="Přidat komentář:"
          color="purple"
          v-model="commentText"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      />
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Přidat komentář" type="submit"/><br><br>
    </q-form>


    <div v-if="this.discussionStore.themeDetailComments.length === 0">
      <h1 class="empty">Žádné odpovědi  :(</h1>
    </div>

    <div v-for="comments in getData()" :key="comments._id" id="reply mobile">
      <q-card>
        <q-card-section>
          <b><a class="clickableUser" href="#" @click="getDetails(comments.author)">{{comments.author}}</a></b> {{comments.date}}
          <div v-if="comments.updateDate !== null">
            <b>Upraveno: </b>{{comments.updateDate}}
          </div>
          <div v-if="comments.user[0].role === 'admin'">
            <b>Velitel outfitu</b>
          </div>
          <div v-if="comments.user[0].role === 'member'">
            <b>Člen OLT</b>
          </div>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section horizontal>
          <q-card-section>
            <div v-if="comments.quoteAuthor !== null" class="quote">
              <b>{{ comments.quoteAuthor }}</b> {{comments.quoteDate}}<br>
              "{{comments.quoteText}}"<br><br>
            </div>
            {{comments.text}}
          </q-card-section>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section>
          <q-card-section-vertical>
            <a class="themeText clickableUser" href="#" @click.prevent="changeQuoteState(comments.text, comments.author, comments.date)">Odpověděť</a>
          </q-card-section-vertical>
          <q-card-section-vertical style="float: right">
            <a class="themeText clickableUser" href="#" @click.prevent="changeReportingCommentState(comments.text, comments.author, comments.date, comments._id)">Nahlásit příspěvek</a>
          </q-card-section-vertical>
        </q-card-section>
      </q-card><br>
      <div v-if="comments.author === this.userStore.user.nickname">
        <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Upravit komentář" flat @click="changeCommentUpdateStatus(comments._id, comments.text)"/><br><br>
      </div>
      <div v-if="comments.author === this.userStore.user.nickname || this.userStore.user.role === 'admin'">
        <q-btn class="bg-red text-white shadow-2 deleteButton all-page-button" align="center" label="Odstranit komentář" flat @click="changeCommentDeleteStatus(comments._id)"/><br><br>
      </div>
    </div>


    <q-dialog v-model="this.deletingTheme">
      <q-card class="dialog">
        <q-card-section>
          <div class="text-h6">Odstranění diskuze</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit diskuzi <b>{{this.discussionStore.themeDetail.name}}</b>?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteTheme()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>


    <q-dialog v-model="this.quoting" style="width: 100%">
      <q-card style="width: 100%">
        <q-card-section>
          <b>Původní komentář</b><br><br>
          <div class="quote">
            <b>{{ this.quoteAuthor }}</b> {{this.quoteTime}}<br>
            "{{this.quoteText}}"<br><br><br>
          </div>
        </q-card-section>
        <q-card-section>
          <b>Váš komentář:</b>
        </q-card-section>
        <q-card-section>
          <q-form v-on:submit="addComment()" style="width: 100%">
            <q-input
                label="Přidat komentář:"
                color="purple"
                v-model="commentText"
                filled
                autogrow
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
                style="width: 100%; margin-top: 0px"
            />
            <q-card-actions>
              <q-btn class="bg-purple text-white shadow-2" align="center" label="Přidat komentář" type="submit"/>
              <q-space />
              <q-btn flat label="Zrušit" color="purple" v-close-popup/>
            </q-card-actions>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>


    <q-dialog v-model="this.deletingComment">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění komentáře</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit komentář?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteComment()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.updatingComment">
      <q-card class="commentCard" style="width: 100%">
        <div class="insideCard">
          <h1 class="comments" style="font-size: 25px; margin-left: 10px">Upravit komentář</h1>
          <q-input
              label="Text Komentáře:"
              color="purple"
              v-model="commentUpdateText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
              style="width: 97%; margin: auto"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Upravit komentář" flat @click="updateComment()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.reportingComment">
      <q-card class="commentCard" style="width: 100%">
        <div class="insideCard">
          <q-card-section>
            <b>Hlášený komentář</b><br><br>
            <div class="quote">
              <b>{{ this.quoteAuthor }}</b> {{this.quoteTime}}<br>
              "{{this.quoteText}}"<br><br><br>
            </div>
          </q-card-section>
          <b style="margin-left: 10px">Důvod hlášení</b>
          <q-input
              label="Jaký je s komentářem problém?"
              color="purple"
              v-model="reportText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
              style="width: 97%; margin: auto"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Nahlásit komentář" flat @click="reportComment()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>


    <q-dialog v-model="this.reportingTheme">
      <q-card class="commentCard" style="width: 100%">
        <div class="insideCard">
          <q-card-section>
            <b>Hlášené téma:</b><br><br>
            <div class="quote">
              "{{this.discussionStore.themeDetail.name}}"<br><br><br>
            </div>
          </q-card-section>
          <b style="margin-left: 10px">Důvod hlášení</b>
          <q-input
              label="Jaký je s tématem problém?"
              color="purple"
              v-model="reportText"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
              style="width: 97%; margin: auto"
          />
        </div>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2 deleteButton" align="center" label="Nahlásit téma" flat @click="reportTheme()"/><br><br>
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="this.reportSuccess">
      <q-card class="commentCard" style="width: 100%">
        <q-card-section>
          Děkujeme,<br>
          Report byl úspěšně podán. O jeho vyhodnocení nebudete informování, ale naši velitelé každý report berou vážně.<br>
        </q-card-section>
        <q-btn flat label="Zavřít" color="purple" v-close-popup/>
      </q-card>
    </q-dialog>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>


</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useDiscussionStore} from "@/stores/DiscussionStore";
import {useUserStore} from "@/stores/UserStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";
import {useMobileStore} from "@/stores/MobileStore";

export default {
  name: "disscussionDetailView",

  async created(){
    this.updateName = this.discussionStore.themeDetail.name;
    this.updateText = this.discussionStore.themeDetail.text;
    await this.discussionStore.getComments(this.userStore.user.nickname);
    this.getData();
    this.totalPages = Math.ceil(this.discussionStore.themeDetailComments.length/20);
  },

  data(){
    return {
      updatingTheme: true,
      updatingComment: false,
      deletingTheme: false,
      deletingComment: false,
      quoting: false,
      themeID: null,
      commentID: null,
      updateName: null,
      updateText: null,
      error: null,
      commentText: null,
      quote: null,
      quoteAuthor: null,
      quoteText: null,
      quoteTime: null,
      page: 1,
      totalPages: null,
      commentUpdateText: null,
      reportingComment: null,
      reportText: null,
      reportSuccess: null,
      reportingTheme: null,
    }
  },

  computed:{
    ...mapStores(useDiscussionStore),
    ...mapStores(useUserStore),
    ...mapStores(useAccountDetailStore),
    ...mapStores(useMobileStore)
  },

  methods: {
    async getDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async updateTheme(){
      try {
        await this.discussionStore.updateTheme(this.discussionStore.themeDetail._id, this.userStore.user.nickname, this.updateName, this.updateText);
        await this.discussionStore.getDetail(this.userStore.user.nickname, this.discussionStore.themeDetail._id);
        this.$router.push({name: 'themeDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    changeDeleteThemeState(){
      if (this.deletingTheme === false){
        this.deletingTheme = true;
      } else {
        this.deletingTheme = false;
      }
    },

    async deleteTheme(){
      try {
        await this.discussionStore.deleteTheme(this.userStore.user.nickname);
        await this.discussionStore.getThemes(this.userStore.user.nickname);
        this.$router.push({name: 'discussion'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async addComment(){
      try {
        await this.discussionStore.addComment(this.userStore.user.nickname, this.commentText, this.quoteAuthor, this.quoteText, this.quoteTime);
        await this.discussionStore.getComments(this.userStore.user.nickname);
        this.getData();
        this.quoteAuthor = null;
        this.quoteText = null;
        this.quoteTime = null;
        if (this.quoting === true){
          this.quoting = false;
        }
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    getData() {
      if (this.page === 1) {
        return this.discussionStore.themeDetailComments.slice(this.page-1, this.page*20);
      } else {
        return this.discussionStore.themeDetailComments.slice(this.page*20-20, this.page*20);
      }
    },

    changeQuoteState(text, author, date){
      this.quoteAuthor = author;
      this.quoteText = text;
      this.quoteTime = date;
      if (this.quoting === false){
        this.quoting = true;
      } else {
        this.quoting = false;
      }
    },

    changeCommentUpdateStatus(id, text){
      this.commentID = id;
      this.commentUpdateText = text;
      if (this.updatingComment === false){
        this.updatingComment = true;
      } else {
        this.updatingComment = false;
      }
    },

    async updateComment(){
      try{
        await this.discussionStore.updateComment(this.userStore.user.nickname, this.commentID, this.commentUpdateText);
        await this.discussionStore.getComments(this.userStore.user.nickname);
        this.getData();
        this.updatingComment = false;
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    changeCommentDeleteStatus(id){
      this.commentID = id;
      if (this.deletingComment === false){
        this.deletingComment = true;
      } else {
        this.deletingComment = false;
      }
    },

    async deleteComment(){
      try {
        await this.discussionStore.deleteComment(this.userStore.user.nickname, this.commentID)
        await this.discussionStore.getComments(this.userStore.user.nickname);
        this.getData();
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    changeReportingCommentState(text, author, date, id){
      this.commentID = id;
      this.quoteAuthor = author;
      this.quoteDate = date;
      this.quoteText = text;
      if (this.reportingComment === true){
        this.reportingComment = false;
      } else {
        this.reportingComment = true;
      }
    },

    async reportComment(){
        try {
          await this.discussionStore.reportComment(this.userStore.user.nickname, this.commentID, this.reportText, this.quoteAuthor, this.quoteText);
          this.reportSuccess = true;
          this.reportingComment = false;
          this.error = false;
        } catch {
          this.error = true;
        }
    },

    changeReportingThemeStatus(){
      if(this.reportingTheme === true){
        this.reportingTheme = false;
      } else {
        this.reportingTheme = true;
      }
    },

    async reportTheme(){
      try {
        await this.discussionStore.reportTheme(this.userStore.user.nickname, this.reportText);
        this.reportSuccess = true;
        this.reportingTheme = false;
        this.error = true;
      } catch {
        this.error = false;
      }
    }

  }
}
</script>

<style scoped>
  .mainContainer{
    width: 50%;
    margin: auto;
  }

  .mainContainer h1 {
    font-size: 25px;
    margin: 0px 0px 0px 10px;
  }

  .mobile {
    width: 90%;
  }

  .themeText {
    white-space: pre-wrap;
    margin: 0px 10px 0px 10px;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .empty {
    text-align: center;
  }

  .profilePicture {
    margin: 10px 10px 10px 10px;
    width: 75px;
    height: 75px;
  }

  .all-page-button {
    width: 100%;
  }

  .quote{
    color: gray;
    font-style: italic;
  }

  .dialog{
    width: 100%
  }

  .comments h1 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0px 20px 0px;
  }
</style>