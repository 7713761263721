<template>
    <div class="mainContainer" v-if="!this.mobileStore.mobile">
    <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
      <q-card class="my-card col" flat bordered>
        <h1 style="margin-left: 15px">O nás</h1>
        <q-separator></q-separator>
        <q-card-section horizontal class="full-width">
          <q-card-section style="align-self: center">
            <p><b>One Llama Team, neboli OLT je malá komunita hráčů převážně Planetside 2 na serveru Miller.</b></p>
            <p>Byla založena v roce 2017 jako takzvaný SoloFit, ale časem se rozrostla na více členů.</p>
            <p>V současné době se komunita skládá z malé skupinky přátel, ale v budoucnu bychom rádi rozšíříli naši působnost a rádi bychom se stali jedním z větších českých outfitů na straně Suverenity Vanu na Milleru.</p>
            <p>Než se tak stane, rádi bychom alespoň předali naše dlouholeté znalosti hry dále pomocí této webové aplikace, na které najdete návody ohledně různých částí hry. Po registraci můžete také využít diskuzní fóra pro položení otázek nejen ohledně Planetside.</p>
            <p>Chcete se k nám přidat? Pokud najdete někoho z velení ve hře, stačí napsat a můžeme si spolu zahrát a zhodnotit Vaši aplikaci.</p>
            <p>Pokud zrovna nejsme ve hře, použite prosím kontaktní formulář v sekci "Žádost o členství".</p>
            <p>Nechcete hrát na straně osvícených? Nevadí! Máme také sekundární outfit na straně Terranské republiky. One Llama Team TR (OLTR).</p>
            <p>Těšíme se, až se uvidíme na bojišti!</p>
            <q-avatar>
              <img src="VS.png" alt="Vanu Sovereignty Logo">
            </q-avatar>
            <b>[OLT] One Llama Team</b><br><br>
            <q-avatar>
              <img src="TR.png" alt="Terran Republic Logo">
            </q-avatar>
            <b>[OLT2] One Llama Team TR</b><br><br>
            <p>A nezapomeňte:</p>
            <p><b>Naši nepřátelé se vyvinou, nebo přestanou existovat!<br><br>Technologie se rovná síle!</b></p>
          </q-card-section>
          <q-card-section class="col-1">
          </q-card-section>
          <img
              class="col-5 logoImage"
              src="../images/OLT-Logo.png"
              style="max-height: 500px; max-width: 500px; margin-right: 30px; align-self: center"
          />
        </q-card-section>
      </q-card>
      </div>

    <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
      <q-card class="my-card col" flat bordered>
        <h1 style="margin-left: 15px">Naši velitelé:
          <q-avatar>
            <img src="VS.png" alt="Vanu Sovereignty Logo">
          </q-avatar>Suverenita Vanu
          <q-avatar>
            <img src="TR.png" alt="Terran Republic Logo">
          </q-avatar>Terranská republika</h1>
        <q-separator />
        <q-card-section horizontal class="full-width">
          <q-card-section>
            <h2>N7MaxiLlama</h2>
            <img
                class="col-5 logoImage"
                src="../images/Llama.jpg"
                style="max-height: 500px; max-width: 300px; align-self: center"
            />

          </q-card-section>
            <q-card-section style="align-self: center">
              <h2>Role: Zakladatel outfitu/Llamas Chosen</h2>
              <h2>O mně:</h2>
              <div class="aboutText">
                {{this.aboutStore.llama.aboutMe}}
              </div><br>
                <q-avatar>
                <img src="VS.png" alt="Vanu Sovereignty Logo">
                </q-avatar>
                <b>{{ this.aboutStore.llama.VS }}</b><br>
              <q-avatar>
                <img src="TR.png" alt="Terran Republic Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.TR }}</b><br>
              <q-avatar>
                <img src="NC.png" alt="New Conglomerate Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.NC }}</b><br>
              <q-avatar>
                <img src="NS.png" alt="Nanite Systems Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.NS }}</b><br>
            </q-card-section>
          </q-card-section>
      </q-card>
   </div>

    <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
      <q-card class="my-card col" flat bordered>
        <q-card-section horizontal class="full-width">
          <q-card-section>
            <h2>Kaladriel</h2>
            <img
                class="col-5 logoImage"
                src="../images/Kala.jpg"
                style="max-height: 500px; max-width: 300px; align-self: center"
            />
          </q-card-section>
          <q-card-section style="align-self: center">
            <h2>Role: Llamas Chosen</h2>
            <h2>O mně:</h2>
            <div class="aboutText">
              {{this.aboutStore.kala.aboutMe}}
            </div><br>
            <q-avatar>
              <img src="VS.png" alt="Vanu Sovereignty Logo">
            </q-avatar>
            <b>{{ this.aboutStore.kala.VS }}</b><br>
            <q-avatar>
              <img src="TR.png" alt="Terran Republic Logo">
            </q-avatar>
            <b>{{ this.aboutStore.kala.TR }}</b><br>
            <q-avatar>
              <img src="NC.png" alt="New Conglomerate Logo">
            </q-avatar>
            <b>{{ this.aboutStore.kala.NC }}</b><br>
            <q-avatar>
              <img src="NS.png" alt="Nanite Systems Logo">
            </q-avatar>
            <b>{{ this.aboutStore.kala.NS }}</b><br>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>

    <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
      <q-card class="my-card col" flat bordered>
        <h1 style="margin-left: 15px">Naši velitelé:       <q-avatar>
          <img src="TR.png" alt="Terran Republic Logo">
        </q-avatar>Terranská republika</h1>
        <q-separator />
        <q-card-section horizontal class="full-width">
          <q-card-section>
            <h2>brusheR</h2>
            <img
                class="col-5 logoImage"
                src="../images/Marty.jpg"
                style="max-height: 500px; max-width: 300px; align-self: center"
            />
          </q-card-section>
          <q-card-section style="align-self: center">
            <h2>Role: Praeceptorem Llama</h2>
            <h2>O mně:</h2>
            <div class="aboutText">
              {{this.aboutStore.marty.aboutMe}}
            </div><br>
            <q-avatar>
              <img src="VS.png" alt="Vanu Sovereignty Logo">
            </q-avatar>
            <b>{{ this.aboutStore.marty.VS }}</b><br>
            <q-avatar>
              <img src="TR.png" alt="Terran Republic Logo">
            </q-avatar>
            <b>{{ this.aboutStore.marty.TR }}</b><br>
            <q-avatar>
              <img src="NC.png" alt="New Conglomerate Logo">
            </q-avatar>
            <b>{{ this.aboutStore.marty.NC }}</b><br>
            <q-avatar>
              <img src="NS.png" alt="Nanite Systems Logo">
            </q-avatar>
            <b>{{ this.aboutStore.marty.NS }}</b><br>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
  </div>


    <div class="mobile" v-if="mobileStore.mobile">
      <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
        <q-card class="my-card col" flat bordered>
          <h1 style="margin-left: 15px">O nás</h1>
          <q-separator></q-separator>
          <q-card-section horizontal class="full-width">
            <q-card-section style="align-self: center">
              <p><b>One Llama Team, neboli OLT je malá komunita hráčů převážně Planetside 2 na serveru Miller.</b></p>
              <p>Byla založena v roce 2017 jako takzvaný SoloFit, ale časem se rozrostla na více členů.</p>
              <p>V současné době se komunita skládá z malé skupinky přátel, ale v budoucnu bychom rádi rozšíříli naši působnost a rádi bychom se stali jedním z větších českých outfitů na straně Suverenity Vanu na Milleru.</p>
              <p>Než se tak stane, rádi bychom alespoň předali naše dlouholeté znalosti hry dále pomocí této webové aplikace, na které najdete návody ohledně různých částí hry. Po registraci můžete také využít diskuzní fóra pro položení otázek nejen ohledně Planetside.</p>
              <p>Chcete se k nám přidat? Pokud najdete někoho z velení ve hře, stačí napsat a můžeme si spolu zahrát a zhodnotit Vaši aplikaci.</p>
              <p>Pokud zrovna nejsme ve hře, použite prosím kontaktní formulář v sekci "Žádost o členství".</p>
              <p>Nechcete hrát na straně osvícených? Nevadí! Máme také sekundární outfit na straně Terranské republiky. One Llama Team TR (OLTR).</p>
              <p>Těšíme se, až se uvidíme na bojišti!</p>
              <q-avatar>
                <img src="VS.png" alt="Vanu Sovereignty Logo">
              </q-avatar>
              <b>[OLT] One Llama Team</b><br><br>
              <q-avatar>
                <img src="TR.png" alt="Terran Republic Logo">
              </q-avatar>
              <b>[OLT2] One Llama Team TR</b><br><br>
              <p>A nezapomeňte:</p>
              <p><b>Naši nepřátelé se vyvinou, nebo přestanou existovat!<br><br>Technologie se rovná síle!</b></p>
            </q-card-section>
            <q-card-section class="col-1">
            </q-card-section>
          </q-card-section>
          <img
              class="col-5 logoImage"
              src="../images/OLT-Logo.png"
          />
        </q-card>
      </div>

      <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
        <q-card class="my-card col" flat bordered>
          <h1 style="margin-left: 15px">Naši velitelé:<br>
            <q-avatar>
              <img src="VS.png" alt="Vanu Sovereignty Logo">
            </q-avatar>Suverenita Vanu<br>
            <q-avatar>
              <img src="TR.png" alt="Terran Republic Logo">
            </q-avatar>Terranská republika</h1>
          <q-separator />
          <q-card-section>
            <h2>N7MaxiLlama</h2>
          </q-card-section>
          <q-card-section horizontal class="full-width">
            <q-card-section style="align-self: center">
              <h2>Role: Zakladatel outfitu/Llamas Chosen</h2>
              <h2>O mně:</h2>
              <div class="aboutText">
                {{this.aboutStore.llama.aboutMe}}
              </div><br>
              <q-avatar>
                <img src="VS.png" alt="Vanu Sovereignty Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.VS }}</b><br>
              <q-avatar>
                <img src="TR.png" alt="Terran Republic Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.TR }}</b><br>
              <q-avatar>
                <img src="NC.png" alt="New Conglomerate Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.NC }}</b><br>
              <q-avatar>
                <img src="NS.png" alt="Nanite Systems Logo">
              </q-avatar>
              <b>{{ this.aboutStore.llama.NS }}</b><br>
            </q-card-section>
          </q-card-section>
          <img
              class="col-5 logoImage"
              src="../images/Llama.jpg"
              style="align-self: center"
          />
        </q-card>
      </div>

      <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
        <q-card class="my-card col" flat bordered>
          <q-card-section>
            <h2>Kaladriel</h2>
          </q-card-section>
          <q-card-section horizontal class="full-width">
            <q-card-section style="align-self: center">
              <h2>Role: Llamas Chosen</h2>
              <h2>O mně:</h2>
              <div class="aboutText">
                {{this.aboutStore.kala.aboutMe}}
              </div><br>
              <q-avatar>
                <img src="VS.png" alt="Vanu Sovereignty Logo">
              </q-avatar>
              <b>{{ this.aboutStore.kala.VS }}</b><br>
              <q-avatar>
                <img src="TR.png" alt="Terran Republic Logo">
              </q-avatar>
              <b>{{ this.aboutStore.kala.TR }}</b><br>
              <q-avatar>
                <img src="NC.png" alt="New Conglomerate Logo">
              </q-avatar>
              <b>{{ this.aboutStore.kala.NC }}</b><br>
              <q-avatar>
                <img src="NS.png" alt="Nanite Systems Logo">
              </q-avatar>
              <b>{{ this.aboutStore.kala.NS }}</b><br>
            </q-card-section>
          </q-card-section>
          <img
              class="col-5 logoImage"
              src="../images/Kala.jpg"
              style="align-self: center"
          />
        </q-card>
      </div>

      <div class="q-pa-md row items-start q-gutter-md justify-center items-center">
        <q-card class="my-card col" flat bordered>
          <h1 style="margin-left: 15px">Naši velitelé: <br>
          <q-avatar>
            <img src="TR.png" alt="Terran Republic Logo">
          </q-avatar>Terranská republika</h1>
          <q-separator />
          <q-card-section>
            <h2>brusheR</h2>
          </q-card-section>
          <q-card-section horizontal class="full-width">
            <q-card-section style="align-self: center">
              <h2>Role: Praeceptorem Llama</h2>
              <h2>O mně:</h2>
              <div class="aboutText">
                {{this.aboutStore.marty.aboutMe}}
              </div><br>
              <q-avatar>
                <img src="VS.png" alt="Vanu Sovereignty Logo">
              </q-avatar>
              <b>{{ this.aboutStore.marty.VS }}</b><br>
              <q-avatar>
                <img src="TR.png" alt="Terran Republic Logo">
              </q-avatar>
              <b>{{ this.aboutStore.marty.TR }}</b><br>
              <q-avatar>
                <img src="NC.png" alt="New Conglomerate Logo">
              </q-avatar>
              <b>{{ this.aboutStore.marty.NC }}</b><br>
              <q-avatar>
                <img src="NS.png" alt="Nanite Systems Logo">
              </q-avatar>
              <b>{{ this.aboutStore.marty.NS }}</b><br>
            </q-card-section>
          </q-card-section>
          <img
              class="col-5 logoImage"
              src="../images/Marty.jpg"
              style="align-self: center"
          />
        </q-card>
      </div>
    </div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useMobileStore} from "../stores/MobileStore";
import {useAboutStore} from "@/stores/AboutUsStore";


export default {
  name: 'AboutView',
  components:{
  },

  data(){
    return {
      windowWidth: null,
      mobile: null,
      error: null,
    }
  },

  async created(){
    try {
      await this.aboutStore.getLlama();
      await this.aboutStore.getKala();
      await this.aboutStore.getMarty();
      this.error = false;
    } catch {
      this.error = true;
    }
  },

  computed: {
    ...mapStores(useMobileStore),
    ...mapStores(useAboutStore),
  },

  methods: {

  }
}

</script>

<style scoped>

.mainContainer {
  max-width: 60%;
  text-align: left;
  margin: auto;
}

.mainContainer h1 {
  font-size: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

.mainContainer h2 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

.mainContainer p {
  font-size: 15px;
}

.mobile {
  width: 90%;
  text-align: left;
  margin: auto;
}

.mobile h1 {
  font-size: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

.mobile h2 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

.mobile p {
  font-size: 15px;
}

.aboutText{
  white-space: pre-wrap;
}
</style>