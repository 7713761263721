import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useAboutStore = defineStore('about', {
    state: () => ({
        llama: null,
        kala: null,
        marty: null,
    }),

    actions: {
            async getLlama() {
                try {
                    const response = await axios.post(config.backendUrl + '/getAboutUs', {
                        nickname: 'N7MaxiLlama'
                    });
                    this.llama = response.data;
                    this.error = false;
                } catch {
                    this.error = true;
                }
            },

            async getKala() {
                try {
                    const response = await axios.post(config.backendUrl + '/getAboutUs', {
                        nickname: 'KaladrielCZ'
                    });
                    this.kala = response.data;
                    this.error = false;
                } catch {
                    this.error = true;
                }
            },

            async getMarty() {
                try {
                    const response = await axios.post(config.backendUrl + '/getAboutUs', {
                        nickname: 'brusheR'
                    });
                    this.marty = response.data;
                    this.error = false;
                } catch {
                    this.error = true;
                }
            },
        }
});