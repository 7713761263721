<template>
  <div class="mainContainer" v-if="!this.mobileStore.mobile">

    <q-form v-on:submit="addAction()">
      <div v-if="this.userStore.user.role === 'admin'">
      <h1>Vytvořit novou akci:</h1>
        <q-input
            color="purple" square filled
            v-model="name"
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
            label="Název akce:" />
        <q-select
          color="purple" filled
          v-model="selectedPlace"
          :options="place"
          :rules="[ val => val && val.length > 0 || 'Zadejte místo konání akce']"
          label="Místo konání akce *" />
      <br>

      <div class="q-gutter-md row justify-center">
        <q-date v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím datum.']"
        />
        <q-time v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím čas.']"
        />
      </div><br><br>

      <q-input
          label="Popis akce"
          color="purple"
          v-model="text"
          filled
          autogrow
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
      /><br>
      <p class="error" v-if="this.error">{{this.errorMessage}}</p>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vytvořit akci" flat type="submit" /><br><br><br>
      </div>
    </q-form>

    <div v-for="actions in getData()" :key="actions.actionName" id="actionsShowed">
      <q-card class="actionCard">
        <q-card-section>
          <h2><a class="clickableTitle" href="#" @click="getActionDetails(actions._id)">{{actions.actionName}}</a></h2>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <p><b>Místo konáni:</b> {{actions.place}}<br>
          <b> Čas konáni:</b> {{actions.date}}</p>
        </q-card-section>
        <q-separator />
        <q-card-section horizontal>
          <img v-bind:src="actions.image" alt="Obrázek akce" class="actionPicture"/>
          <q-card-section class="actionText">
            {{actions.text}}
          </q-card-section>
        </q-card-section><br>
        <q-separator />
        <q-card-section>
          <p><b><a class="clickableUser" href="#" @click="getDetails(actions.author)">{{actions.author}}</a></b> {{actions.dateCreated}}</p>
          <p v-if="actions.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(actions.updateAuthor)">{{actions.updateAuthor}}</a></b> {{actions.updateTime}}</p>
        </q-card-section>
      </q-card><br>
      <div v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
        <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit akci" @click="changedeleteState(actions._id, actions.actionName)"/><br><br>
      </div>
    </div>

    <q-dialog v-model="this.deleteDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění akce</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit akci <b>{{this.deleteName}}</b>?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteAction(this.deleteId)"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>

  </div>

  <div class="mainContainer mobile" v-if="this.mobileStore.mobile">
    <q-form v-on:submit="addAction()">

      <div v-if="this.userStore.user.role === 'admin'">
        <h1>Vytvořit novou akci:</h1>
        <q-input
            color="purple" square filled
            v-model="name"
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím název akce.']"
            label="Název akce:" />
        <q-select
            color="purple" filled
            v-model="selectedPlace"
            :options="place"
            :rules="[ val => val && val.length > 0 || 'Zadejte místo konání akce']"
            label="Místo konání akce *" />
        <br>

        <div class="q-gutter-md row justify-center">
          <q-date v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                  :rules="[ val => val && val.length > 0 || 'Vyberte prosím datum.']"
          />
          <q-time v-model="date" mask="DD.MM.YYYY HH:mm" color="purple"
                  :rules="[ val => val && val.length > 0 || 'Vyberte prosím čas.']"
          />
        </div><br><br>

        <q-input
            label="Popis akce"
            color="purple"
            v-model="text"
            filled
            autogrow
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
        /><br>
        <p class="error" v-if="this.error">{{this.errorMessage}}</p>
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Vytvořit akci" flat type="submit" /><br><br><br>
      </div>
    </q-form>

    <div v-for="actions in getData()" :key="actions.actionName" id="actionsShowedMobile">
      <q-card class="actionCard">
        <q-card-section>
          <h2><a class="clickableTitle" href="#" @click="getActionDetails(actions._id)">{{actions.actionName}}</a></h2>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <p><b>Místo konáni:</b> {{actions.place}}<br>
            <b> Čas konáni:</b> {{actions.date}}</p>
        </q-card-section>
        <q-separator />
        <q-card-section horizontal>
          <q-card-section class="actionText">
            {{actions.text}}
          </q-card-section>
        </q-card-section><br>
        <q-separator />
        <q-card-section>
          <p><b><a class="clickableUser" href="#" @click="getDetails(actions.author)">{{actions.author}}</a></b> {{actions.dateCreated}}</p>
          <p v-if="actions.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(actions.updateAuthor)">{{actions.updateAuthor}}</a></b> {{actions.updateTime}}</p>
        </q-card-section>
      </q-card><br>
      <div v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
        <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit akci" @click="changedeleteState(actions._id, actions.actionName)"/><br><br>
      </div>
    </div>

    <q-dialog v-model="this.deleteDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Odstranění akce</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete odstranit akci <b>{{this.deleteName}}</b>?
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteAction(this.deleteId)"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>

  </div>
</template>

<script>
import {useMobileStore} from "../stores/MobileStore";
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {usePlannedActionsStore} from "@/stores/plannedActionsStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";


export default {
  name: "PlannedActionsView",

  async created() {
    await this.plannedActionsStore.getActions();
    this.totalPages = Math.ceil(this.plannedActionsStore.actions.length/5);
  },

  setup(){
    return {
      place: [
        'Indar', 'Esamir', 'Amerish', 'Hossin', 'Oshur', 'Podle dostupnosti',
      ],
    }
  },

  computed: {
    ...mapStores(useMobileStore),
    ...mapStores(useUserStore),
    ...mapStores(usePlannedActionsStore),
    ...mapStores(useAccountDetailStore),
  },

  data() {
    return {
      selectedPlace: null,
      date: null,
      time: null,
      text: null,
      error: null,
      errorMessage: null,
      name: null,
      image: null,
      page: 1,
      totalPages: null,
      deleteDialogue: false,
      deleteName: null,
      deleteId: null,
    }
  },

  methods: {
    async addAction() {
      if (this.selectedPlace === 'Indar'){
        this.image = 'indar.png';
      } else if (this.selectedPlace === 'Oshur'){
        this.image = 'oshur.png';
      } else if (this.selectedPlace === 'Amerish') {
        this.image = 'amerish.png';
      } else if (this.selectedPlace === 'Esamir') {
        this.image = 'esamir.png';
      } else if (this.selectedPlace === 'Hossin') {
        this.image = 'hossin.png';
      } else {
        this.image = 'auraxis.png';
      }
      if (this.date === null) {
        this.errorMessage = "Zadejte prosím datum a čas."
        this.error = true;
      } else {
        try {
          await this.plannedActionsStore.createAction(this.userStore.user.nickname, this.name, this.text, this.date, this.selectedPlace, this.image);
          this.error = false;
          await this.plannedActionsStore.getActions();
          this.totalPages = Math.ceil(this.plannedActionsStore.actions.length/5);
        } catch {
          this.error = true;
          this.errorMessage = 'Chyba při přidání události. Zkuste prosím později.';
        }
      }
    },

    async getDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    async getActionDetails(id){
      try {
        await this.plannedActionsStore.ActionDetails(id, this.userStore.user.nickname);
        await this.plannedActionsStore.getAttendees(this.userStore.user.nickname, id);
        await this.plannedActionsStore.amIAttending(this.userStore.user.nickname);
        this.$router.push({name: 'actionDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

    getData(){
      window.scroll(0,0);
      if (this.page === 1) {
        return this.plannedActionsStore.actions.slice(this.page-1, this.page*5);
      } else {
        return this.plannedActionsStore.actions.slice(this.page*5-5, this.page*5);
      }
    },

    async deleteAction(){
      try {
        await this.plannedActionsStore.deleteAction(this.deleteId, this.userStore.user.nickname);
        await this.plannedActionsStore.getActions();
        this.totalPages = Math.ceil(this.plannedActionsStore.actions.length/5);
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = 'Chyba při odstranení akce. Zkuste prosím podzěji.'
      }
    },

    changedeleteState(id, name){
      this.deleteName = name;
      this.deleteId = id;
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

  },
}

</script>

<style scoped>
  .mainContainer {
    width: 50%;
    margin: auto;
  }

  .mainContainer h1 {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .mainContainer h2 {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .logoImage {
    width: 100%;
  }

  .mobile {
    width: 90%;
  }

  .all-page-button {
    width: 100%;
  }

  .error {
    color: darkred;
    font-size: 15px;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .actionPicture {
    height: 150px;
    margin: 10px 10px 10px 10px;
  }

  .actionText {
    white-space: pre-wrap;
  }

  .clickableTitle {
    color: purple;
    text-decoration: none;
    font-size: 25px;
  }

  .clickableTitle:hover {
    color: #008080;
  }
</style>