<template>
  <div class="row justify-around" v-if="!this.mobileStore.mobile">
    <div class="col-2 .col-md-1">
      <div class="onlineInside">
        <p class="mainText">Online členové outfitu:</p>
        <q-separator></q-separator>
        <q-avatar>
          <img src="VS.png" alt="Vanu Sovereignty Logo">
        </q-avatar>
        VS:
        <q-separator></q-separator>
        <div class="nicknames" v-for="vs in this.daybreakStore.onlineIngameVS" :key="vs">
          {{vs.nickname}}
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineIngameVS.length === 0">Nikdo online :(</p>
        <q-separator></q-separator>
        <q-avatar>
          <img src="TR.png" alt="Logo Terranské republiky">
        </q-avatar>
        TR:
        <q-separator></q-separator>
        <div class="nicknames" v-for="tr in this.daybreakStore.onlineIngameTR" :key="tr">
          {{tr.nickname}}
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineIngameTR.length === 0">Nikdo online :(</p>
      </div>
    </div>

  <div class="homePage col-6 .col-md-1">
    <div v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
    <h1>Přidat novinku:</h1>
    <q-card class="usersCard" v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
      <q-card-section>
        <q-input
            color="purple" square filled
            v-model="name"
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím název novinky.']"
            label="Název novinky:" />
        <q-input
            label="Text novinky:"
            color="purple"
            v-model="text"
            filled
            autogrow
            :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
        />
        <q-select
            color="purple" filled
            v-model="selectedPicture"
            :options="pictures"
            :rules="[ val => val && val.length > 0 || 'Vyberte prosím nějaký obrázek novinky.']"
            label="Obrázek novinky:" />
        <p class="error" v-if="this.error != null">{{this.error}}</p>
        <p class="success" v-if="this.success != null">{{this.success}}</p><br>
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Publikovat novinku" @click="addNews()"/>
      </q-card-section>
    </q-card><br><br>
    </div>

    <div v-for="news in getData()" :key="news.name" id="newsShowed">
      <q-card class="newsCard">
        <q-card-section>
          <h2>{{news.postName}}</h2>
        </q-card-section>
        <q-separator />
        <q-card-section horizontal>
          <img v-bind:src="news.picture" alt="Obrázek novinky" class="newsPicture"/>
          <q-card-section class="newsText">
            {{news.text}}
          </q-card-section>
        </q-card-section><br>
        <q-separator />
        <q-card-section>
          <p v-if="news.author !=='Automatické ověření'"><b><a class="clickableUser" href="#" @click="getDetails(news.author)">{{news.author}}</a></b> {{news.date}}</p>
          <p v-if="news.author ==='Automatické ověření'"><b>{{news.author}}</b> {{news.date}}</p>
          <p v-if="news.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(news.updateAuthor)">{{news.updateAuthor}}</a></b> {{news.updateDate}}</p>
        </q-card-section>
      </q-card><br>
      <div v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit novinku" @click="this.selectUpdateNews(news._id, news.postName, news.text, news.picture)"/><br><br>
        <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit novinku" @click="this.changedeleteState(news.postName), this.getId(news._id)"/><br><br>
      </div>


      <q-dialog v-model="this.updating">
        <q-card class="usersCard newsUpdating" v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin' && this.updating === true">
          <q-card-section>
            <h1 class="text-h6">Update novinky:</h1>
            <q-input
                color="purple" square filled
                v-model="updateName"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím název novinky.']"
                label="Název novinky:" />
            <q-input
                label="Text novinky:"
                color="purple"
                v-model="updateText"
                filled
                autogrow
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
            />
            <q-select
                color="purple" filled
                v-model="updateSelectedPicture"
                :options="pictures"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím nějaký obrázek novinky.']"
                label="Obrázek novinky:" />
            <p class="error" v-if="this.error != null">{{this.error}}</p>
            <p class="success" v-if="this.success != null">{{this.success}}</p><br>
            <q-card-actions>
              <q-btn class="bg-purple text-white shadow-2" align="center" label="Updatovat novinku" @click="updateNews()"/>
              <q-space />
              <q-btn flat label="Zrušit" color="purple" v-close-popup/>
            </q-card-actions>
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog v-model="this.deleteDialogue">
        <q-card class="deleteAccountDialogue">
          <q-card-section>
            <div class="text-h6">Odstranění novinky</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            Jste si jisti, že chcete odstranit novinku <b>{{this.deleteName}}</b>?
          </q-card-section>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteNews(this.deleteId)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

    </div>
    <div class="q-pa-lg flex flex-center">
    <q-pagination
        v-model="page"
        :max="totalPages"
        boundary-numbers
        direction-links
        color="purple"
    ></q-pagination><br>
    </div>
  </div>

    <div class="col-2">
      <div class="onlineInside">
        <p class="mainText">Přihlášení uživatelé:</p>
        <q-separator></q-separator>
        <p class="mainText">Velitelé:</p>
        <div class="nicknames" v-for="admin in this.daybreakStore.onlineAdmins" :key="admin">
          <a class="clickableUser" href="#" @click="getDetails(admin)">{{admin}}</a>
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineAdmins.length === 0">Nikdo není přihlášen :(</p>
        <q-separator></q-separator>
        <p class="mainText">Členové komunity:</p>
        <div class="nicknames" v-for="member in this.daybreakStore.onlineMembers" :key="member">
          <a class="clickableUser" href="#" @click="getDetails(member)">{{member}}</a>
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineMembers.length === 0">Nikdo není přihlášen :(</p>
        <q-separator></q-separator>
        <p class="mainText">Uživatelé mimo OLT:</p>
        <div class="nicknames" v-for="user in this.daybreakStore.onlineUsers" :key="user">
          <a class="clickableUser" href="#" @click="getDetails(user)">{{user}}</a>
        </div>
        <p class="mainText" v-if="this.daybreakStore.onlineUsers.length === 0">Nikdo není přihlášen :(</p>
      </div>
    </div>


  </div>











  <div class="homePage mobile" v-if="this.mobileStore.mobile">
    <div v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
      <h1>Přidat novinku:</h1>
      <q-card class="usersCard" v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
        <q-card-section>
          <q-input
              color="purple" square filled
              v-model="name"
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím název novinky.']"
              label="Název novinky:" />
          <q-input
              label="Text novinky:"
              color="purple"
              v-model="text"
              filled
              autogrow
              :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
          />
          <q-select
              color="purple" filled
              v-model="selectedPicture"
              :options="pictures"
              :rules="[ val => val && val.length > 0 || 'Vyberte prosím nějaký obrázek novinky.']"
              label="Obrázek novinky:" />
          <p class="error" v-if="this.error != null">{{this.error}}</p>
          <p class="success" v-if="this.success != null">{{this.success}}</p><br>
          <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Publikovat novinku" @click="addNews()"/>
        </q-card-section>
      </q-card><br><br>
    </div>

    <div v-for="news in getData()" :key="news.name" id="newsShowedMobile">
      <q-card class="newsCard">
        <q-card-section>
          <h2>{{news.postName}}</h2>
        </q-card-section>
        <q-separator />
        <q-card-section horizontal>
          <q-card-section class="newsText">
            {{news.text}}
          </q-card-section>
        </q-card-section><br>
        <q-separator />
        <q-card-section>
          <p v-if="news.author !=='Automatické ověření'"><b><a class="clickableUser" href="#" @click="getDetails(news.author)">{{news.author}}</a></b> {{news.date}}</p>
          <p v-if="news.author ==='Automatické ověření'"><b>{{news.author}}</b> {{news.date}}</p>
          <p v-if="news.updateAuthor !== null"><b>Update: <a class="clickableUser" href="#" @click="getDetails(news.updateAuthor)">{{news.updateAuthor}}</a></b> {{news.updateDate}}</p>
        </q-card-section>
      </q-card><br>
      <div v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin'">
        <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Upravit novinku" @click="this.selectUpdateNews(news._id, news.postName, news.text, news.picture)"/><br><br>
        <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Odstranit novinku" @click="this.changedeleteState(news.postName), this.getId(news._id)"/><br><br>
      </div>


      <q-dialog v-model="this.updating" class="mobile">
        <q-card class="usersCard newsUpdating" v-if="this.userStore.loggedIn === true && this.userStore.user.role === 'admin' && this.updating === true">
          <q-card-section>
            <h1 class="text-h6">Update novinky:</h1>
            <q-input
                color="purple" square filled
                v-model="updateName"
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím název novinky.']"
                label="Název novinky:" />
            <q-input
                label="Text novinky:"
                color="purple"
                v-model="updateText"
                filled
                autogrow
                :rules="[ val => val && val.length > 0 || 'Zadejte prosím nějaký text.']"
            />
            <q-select
                color="purple" filled
                v-model="updateSelectedPicture"
                :options="pictures"
                :rules="[ val => val && val.length > 0 || 'Vyberte prosím nějaký obrázek novinky.']"
                label="Obrázek novinky:" />
            <p class="error" v-if="this.error != null">{{this.error}}</p>
            <p class="success" v-if="this.success != null">{{this.success}}</p><br>
            <q-card-actions>
              <q-btn class="bg-purple text-white shadow-2" align="center" label="Updatovat novinku" @click="updateNews()"/>
              <q-space />
              <q-btn flat label="Zrušit" color="purple" v-close-popup/>
            </q-card-actions>
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog v-model="this.deleteDialogue">
        <q-card class="deleteAccountDialogue">
          <q-card-section>
            <div class="text-h6">Odstranění novinky</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            Jste si jisti, že chcete odstranit novinku <b>{{this.deleteName}}</b>?
          </q-card-section>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteNews(this.deleteId)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

    </div>
    <div class="q-pa-lg flex flex-center justify-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {useNewsStore} from "@/stores/NewsStore";
import {useMobileStore} from "@/stores/MobileStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";
import {useDaybreakStore} from "@/stores/DayBreakStore";

export default {
  name: 'HomeView',

  setup() {
    return {
      pictures: [
        'Logo OLT', 'Logo Suverenity Vanu', 'Logo Terranské Republiky', 'Logo Nového Konglomerátu', 'Logo Nanite Systems',
      ],
    }
  },

  async created() {
    await this.newsStore.getNews();
    this.totalPages = Math.ceil(this.newsStore.news.length/5);
    await this.daybreakStore.getOnlineAdmins();
    await this.daybreakStore.getOnlineMembers();
    await this.daybreakStore.getOnlineUsers();
    await this.daybreakStore.getOnlineVS();
    await this.daybreakStore.getOnlineTR();
  },

  components: {},

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useNewsStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAccountDetailStore),
    ...mapStores(useDaybreakStore),
    ...mapStores(useDaybreakStore),
  },

  data() {
    return {
      text: '',
      name: '',
      selectedPicture: '',
      pictureName: '',
      error: null,
      page: 1,
      totalPages: null,
      success: null,
      updating: false,
      updateText: '',
      updateName: '',
      updateSelectedPicture: '',
      updatePicture: '',
      updateId: '',
      deleteDialogue: false,
      deleteName: null,
      deleteId: null,
    }
  },

  methods: {
    getPictureName(picture) {
      if (picture === 'Logo OLT') {
        return 'OLT-Logo.png';
      } else if (picture === 'Logo Suverenity Vanu') {
        return 'VS.png';
      } else if (picture === 'Logo Terranské Republiky') {
        return 'TR.png';
      }  else if (picture === 'Logo Nového Konglomerátu') {
        return 'NC.png'
      } else {
        return 'NS.png'
      }
    },

    reverseGetPictureName(picture){
      if (picture === 'OLT-Logo.png') {
        this.updateSelectedPicture = 'Logo OLT';
      } else if (picture === 'VS.png') {
        this.updateSelectedPicture = 'Logo Suverenity Vanu';
      } else if (picture === 'TR.png') {
        this.updateSelectedPicture = 'Logo Terranské Republiky';
      }  else if (picture === 'NC.png') {
        this.updateSelectedPicture = 'Logo Nového Konglomerátu'
      } else {
        this.updateSelectedPicture = 'Logo Nanite Systems'
      }
    },

    changedeleteState(name){
      this.deleteName = name;
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

    getId(id){
      this.deleteId = id;
    },

    async addNews(){
      if (this.userStore.user.role === 'admin'){
        try {
          this.pictureName = this.getPictureName(this.selectedPicture);
          await this.newsStore.postNews(this.userStore.user.nickname, this.name, this.text, this.pictureName);
          await this.newsStore.getNews();
          this.totalPages = Math.round(this.newsStore.news.length/5);
          await this.getData();
          this.error = null;
          this.success = "Novinka úspěšně přidána.";
        } catch {
          this.error = 'Chyba při přidávání novinky. Zkuste prosím později.'
          this.success = null;
        }
      } else {
        this.error = 'Nejste admin!'
      }
    },

    getData(){
      window.scroll(0,0);
      if (this.page === 1) {
        return this.newsStore.news.slice(this.page-1, this.page*5);
      } else {
        return this.newsStore.news.slice(this.page*5-5, this.page*5);
      }
    },

    async deleteNews(){
      try {
        if (this.userStore.user.role === 'admin'){
          await this.newsStore.deleteNews(this.userStore.user.nickname, this.deleteId);
          await this.newsStore.getNews();
          this.totalPages = Math.round(this.newsStore.news.length/5);
          this.getData();
          this.error = null;
        } else {
          this.error = 'Nejste admin!'
        }
      } catch {
        this.error = "Chyba při odstranění novinky!"
      }
    },

    selectUpdateNews(id, name, text, picture){
      window.scroll(0,0);
      this.updating = true;
      this.reverseGetPictureName(picture);
      this.updateName = name;
      this.updateText = text;
      this.updateId = id;
    },

    async updateNews(){
      try {
        this.updatePicture = this.getPictureName(this.updateSelectedPicture);
        await this.newsStore.updateNews(this.updateId, this.updateName, this.updateText, this.updatePicture, this.userStore.user.nickname);
        this.error = null;
        this.success = "Novinka úspěšně updatována.";
        this.updating = false;
      } catch {
        this.error = "Chyba při updatu novinky!"
      }
    },

    async getDetails(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    }

  }
}

</script>

<style scoped>
  .homePage {
    width: 50%;
    text-align: left;
    margin: auto;
  }

  .homePage h1 {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .homePage h2 {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
  }

  .homePage p {
    font-size: 15px;
    margin: 0px 0px 0px 0px;
  }

  .all-page-button {
    width: 100%;
  }

  .error {
    color: darkred;
    font-size: 15px;
  }

  .newsPicture {
    height: 150px;
    margin: 10px 10px 10px 10px;
  }

  .success {
    color: green;
    font-size: 15px;
  }

  .newsUpdating {
    width: 90%;
  }

  .newsText {
    white-space: pre-wrap;
  }

  .newsPictureMobile{
    width: 300px;
  }

  .mobile {
    width: 90%;
  }

  .clickableUser {
    color: purple;
    text-decoration: none;
    font-size: 15px;
  }

  .clickableUser:hover {
    color: #008080;
  }

  .online {
    width: 20%;
  }

  .onlineInside {
    border-style: solid;
    border-color: lightgray;
    border-width: 2px;
    font-size: 20px;
  }

  .right {
    float: right;
  }

  .mainText {
    margin: 10px 10px 10px 10px;
  }

  .online h2{
    font-size: 20px;
  }

  .nicknames{
    font-size: 15px;
    margin-left: 10px;
  }
</style>
