import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";
import jwtDecode from "jwt-decode";

export const useUserStore = defineStore('user',{
    state(){
      const oldToken = localStorage.getItem('token');
      if (oldToken){
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + oldToken;
      }

        return {
            token: oldToken,
            error: null,
            errorMessage: null,
            afterLoginRoute: null,
            success: null,
            successMessage: null,
            allUsers: [],
            length: null,
            loggedIn: false,
            authenticator: null,
            testRole: null,
        }
    },

    getters: {
        isAuthenticated: state => state.token !== null,
        user: state => jwtDecode(state.token),
    },

    actions: {

        async checkLogin(){
          if (localStorage.token) {
              this.token = localStorage.getItem('token');
          }
        },

        async login(nick, pass){
            try {
                const response = await axios.post(config.backendUrl + '/login', {
                    nickname: nick,
                    password: pass
                });
                this.token = response.data.token;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                localStorage.setItem('token', this.token);
                this.error = null;
                this.loggedIn = true;
            } catch {
                this.error = 'Špatné jméno, nebo heslo. Zkuste prosím znovu.';
            }
        },

        async logout(nick){
          try {
              await axios.post(config.backendUrl + '/logout', {
                  nickname: nick,
              });
          } catch {
              this.error = 'Chyba při odhlášení. Zkuste prosím později.';
          }
          this.token = null;
          delete axios.defaults.headers.common['Authorization'];
          localStorage.removeItem('token');
          this.loggedIn = false;
        },

        async changeAvatar(nick, pic){
            try {
                await axios.post(config.backendUrl + '/changeAvatar', {
                    nickname: nick,
                    picture: pic
                });
                this.error = false;
                this.success = true;
                this.successMessage = 'Avatar úspěšně změněn.';
            } catch {
                this.error = true;
                this.errorMessage = 'Chyba při změně avatara. Zkuste prosím později.';
            }
            this.user.profilePicture = "pic";
        },

        setAfterLoginRoute(route){
            this.afterLoginRoute = route;
        },

        async changeNickname(nick, security){
            try {
                await axios.post(config.backendUrl + '/changeNickname',{
                   oldNickname: this.user.nickname,
                   nickname: nick,
                   question: security
                });
                this.user.nickname = nick;
                this.error = false;
                this.success = true;
                this.successMessage = '';
            } catch {
                this.errorMessage = "Chyba při změně přezdívky. Zkuste prosím později.";
                this.success = false;
                this.error = true;
            }
        },

        async deleteAccount(pass, nick){
            try {
                await this.logout(nick);
                await axios.post(config.backendUrl + '/deleteAccount', {
                   nickname: nick,
                   password: pass
                });
            } catch {
                this.error = true;
                this.errorMessage = "Chyba při odstranění úštu. Zkuste prosím později.";
            }
        },

        async authenticateMe(nick, code){
          try {
              const response = await axios.post(config.backendUrl + '/authenticateMe', {
                  nickname: nick,
                  code: code,
              });
              this.authenticator = response.data;
              this.user.role = this.authenticator;
              this.error = false;
          } catch {
              this.error = true;
              this.errorMessage = 'Chyba při autentizaci uživatele';
          }
        },

        async saveCharacters(nickname, VS, TR, NC, NS){
            try {
                await axios.post(config.backendUrl + '/updateCharacters', {
                    nickname: nickname,
                    VS: VS,
                    TR: TR,
                    NC: NC,
                    NS: NS,
                });
                this.user.VS = VS;
                this.user.TR = TR;
                this.user.NC = NC;
                this.user.NS = NS;
                this.error = false;
            } catch {
                this.error = true;
                this.errorMessage = 'Chyba při úpravě postav.';
            }
        },

        async updateAbout(nickname, text){
            try {
                await axios.post(config.backendUrl + '/updateABoutMe', {
                    nickname: nickname,
                    aboutMe: text
                });
                this.user.aboutMe = text;
                this.error = false;
            } catch {
                this.error = true;
                this.errorMessage = 'Chyba při úpravě popisu profilu.';
            }
        },
    },

    persist: true
});