import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const usePlannedActionsStore = defineStore('plannedActions', {
    state: () => ({
        actions: null,
        error: null,
        actionDetail: null,
        actionComments: [],
        attendees: null,
        attending: null,
    }),

    actions: {
        async createAction(nickname, title, text, date, place, image) {
            try {
                await axios.post(config.backendUrl + '/createAction', {
                    author: nickname,
                    actionName: title,
                    text: text,
                    date: date,
                    place: place,
                    image: image,
                    dateCreated: null,
                    updateAuthor: null,
                    updateTime: null,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getActions(){
            try {
                const response = await axios.post(config.backendUrl + '/getActions', {
                });
                this.actions = response.data.reverse();
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async ActionDetails(id, user) {
            try {
                const response = await axios.post(config.backendUrl + '/getActionDetails', {
                    id: id,
                    user: user,
                });
                this.actionDetail = response.data;
                const responseComments = await axios.post(config.backendUrl + '/loadActionComments', {
                    nickname: user,
                    id: id,
                });
                this.actionComments = responseComments.data.reverse();
                this.error = null;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteAction(id, admin) {
            try {
                await axios.post(config.backendUrl + '/deleteAction', {
                    id: id,
                    user: admin,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async updateAction(title, text, date, place, image, updateAuthor, id){
            try {
                await axios.post(config.backendUrl + '/updateAction', {
                    actionName: title,
                    text: text,
                    date: date,
                    place: place,
                    image: image,
                    updateAuthor: updateAuthor,
                    id: id,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },


        async attend(nickname, actionID){
            try {
                await axios.post(config.backendUrl + '/attend', {
                    user: nickname,
                    actionId: actionID,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getAttendees(nickname,id){
            try {
                const results = await axios.post(config.backendUrl + '/getAttendees', {
                    nickname: nickname,
                    id: id,
                })
                this.attendees = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async amIAttending(nickname){
            try {
                const result = await axios.post(config.backendUrl + '/amiAttending', {
                    nickname: nickname,
                    id: this.actionDetail._id,
                });
                this.attending = result.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async attendCancel(id, nickname){
          try {
              await axios.post(config.backendUrl + '/cancelAttend', {
                 id: id,
                 nickname: nickname,
              }),
              this.error = false;
          } catch {
              this.error = true;
          }
        },

        async comment(nickname, text){
            try {
                await axios.post(config.backendUrl + '/addActionsComment', {
                    nickname: nickname,
                    text: text,
                    actionID: this.actionDetail._id,
                    date: null,
                    updateDate: null,
                });
                const responseComments = await axios.post(config.backendUrl + '/loadActionComments', {
                    nickname: nickname,
                    id: this.actionDetail._id,
                });
                this.actionComments = responseComments.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při postování komentáře.';
            }
        },

        async getComments(nickname){
            try {
                const responseComments = await axios.post(config.backendUrl + '/loadActionComments', {
                    nickname: nickname,
                    id: this.actionDetail._id,
                });
                this.actionComments = responseComments.data.reverse();
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteComment(nickname, id){
            try {
                await axios.post(config.backendUrl + '/deleteActionComment', {
                    nickname: nickname,
                    id: id,
                });
                const responseComments = await axios.post(config.backendUrl + '/loadActionComments', {
                    nickname: nickname,
                    id: this.actionDetail._id,
                });
                this.actionComments = responseComments.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při odstranění komentáře.';
            }
        },

        async updateComment(nickname, text, id){
            try {
                await axios.post(config.backendUrl + '/updateActionComment', {
                    nickname: nickname,
                    text: text,
                    id: id,
                    updateDate: null,
                });
                const responseComments = await axios.post(config.backendUrl + '/loadActionComments', {
                    nickname: nickname,
                    id: this.actionDetail._id,
                });
                this.actionComments = responseComments.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při odstranění komentáře.';
            }
        }

    },

    persist: true
});