<template>
  <div class="applicationsContainer" v-if="!this.mobileStore.mobile">
    <h1>Vyřízené přihlášky</h1>
    <div class="applications" v-for="applications in getData()" :key="applications.nickname" id="applicationsShowed">
      <p>
        <b>Přezdívka: </b>{{applications.nickname}}
        <b>ID přihlášky: </b>{{applications._id}}<br>
        <b>Datum podání přihlášky: </b>{{applications.date}}
        <b>Status přihlášky: </b>{{applications.status}}</p>
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail přihlášky" flat @click="getDetails(applications._id)"/><br><br>
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>

  <div class="applicationsContainer mobile" v-if="this.mobileStore.mobile">
    <h1>Vyřízené přihlášky</h1>
    <div class="applications" v-for="applications in getData()" :key="applications.nickname" id="applicationsShowed mobile">
      <p>
        <b>Přezdívka: </b>{{applications.nickname}}
        <b>ID přihlášky: </b>{{applications._id}}<br>
        <b>Datum podání přihlášky: </b>{{applications.date}}
        <b>Status přihlášky: </b>{{applications.status}}</p>
      <q-btn class="bg-purple text-white shadow-2 deleteButton all-page-button" align="center" label="Zobrazit detail přihlášky" flat @click="getDetails(applications._id)"/><br><br>
    </div>

    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-model="page"
          :max="totalPages"
          boundary-numbers
          direction-links
          color="purple"
      ></q-pagination><br>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";
import {useApplicantStore} from "@/stores/ApplicantStore";
import {useMobileStore} from "../stores/MobileStore";

export default {
  name: "AdminViewCompletedView",
  data(){
    return {
      page: 1,
      totalPages: null,
    }
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useApplicantStore),
    ...mapStores(useMobileStore),
  },

  async created(){
    await this.getCompletedApplications();
    this.totalPages = Math.ceil(this.applicantStore.completedApplications.length/5);
  },

  methods: {
    getData() {
      window.scroll(0, 0);
      if (this.page === 1) {
        return this.applicantStore.completedApplications.slice(this.page - 1, this.page * 5);
      } else {
        return this.applicantStore.completedApplications.slice(this.page * 5 - 5, this.page * 5);
      }
    },

    async getDetails(id){
      try {
        await this.applicantStore.getDetail(this.userStore.user.nickname, id);
        if (this.applicantStore.error === null){
          await this.$router.push({name: 'applicantDetail'});
        }
        this.error = null;
      } catch {
        this.error = true;
      }
    },

    async getCompletedApplications(){
      try {
        await this.applicantStore.getCompletedApplications(this.userStore.user.nickname);
        this.error = null;
      } catch {
        this.error = true;
      }
    },

  },
}
</script>

<style scoped>
  .applicationsContainer {
    margin: auto;
    width: 50%;
  }

  .all-page-button {
    width: 100%;
  }

  .applicationsContainer h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .mobile {
    width: 90%;
  }
</style>
