import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useAccountDetailStore = defineStore('accountDetail', {
    state: () => ({
        user: null,
        error: null,
        nickname: null,
    }),

    actions: {
        async getUser(nickname) {
            try {
                this.nickname = nickname;
                const response = await axios.post(config.backendUrl + '/getAboutUs', {
                    nickname: nickname
                });
                this.user = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },
    },

    persist: true
});