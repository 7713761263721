<template>
  <h1 v-if="this.registeredStore.users.length === 0" class="fixed-center noUsers">Žádní registrovaní uživatelé :(</h1>
  <div v-if="!this.mobileStore.mobile">
  <div v-for="user in getData()" :key="user.nickname">
    <q-card class="usersCard">
      <q-card-section horizontal>
          <img v-bind:src="user.profilePicture" alt="Uživatelský avatar" class="userPicture"/>
        <q-card-section>
          <p><b>Přezdívka: </b>{{user.nickname}}<br></p>
          <p><b>Role: </b>{{user.role}}</p>
          <p class="error" v-if="this.error !== false">{{this.errorMessage}}</p>
          <q-btn class="bg-purple text-white shadow-2 changeRoleButton allPageButton" align="center" label="Zobrazit profil" @click="this.showProfile(user.nickname)"/><br><br>
          <q-btn class="bg-purple text-white shadow-2 changeRoleButton allPageButton" align="center" label="Změnit roli uživatele" @click="this.changeUpdateState(), this.getName(user.nickname)"/><br><br>
          <q-btn class="bg-red text-white shadow-2 allPageButton" align="center" label="Odstranit uživatele" @click="this.changedeleteState(), this.getName(user.nickname)"/><br><br>
        </q-card-section>
      </q-card-section>

      <q-dialog v-model="this.deleteDialogue">
        <q-card class="deleteAccountDialogue">
          <q-card-section>
            <div class="text-h6">Zrušení účtu:</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            Jste si jisti, že chcete zrušit účet uživatele <b>{{this.deleteNickname}}</b>?
          </q-card-section>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteUser(this.deleteNickname)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-dialog v-model="this.updateDialog">
        <q-card class="updateAccountDialogue">
          <q-card-section>
            <div class="text-h6">Update role uživatele: {{this.deleteNickname}}</div>
          </q-card-section>
          <div class="row">
            <q-radio color="purple" v-model="newRole" val="user" label="Registrovaný uživatel" />
            <q-radio color="purple" v-model="newRole" val="member" label="Člen OLT" />
            <q-radio color="purple" v-model="newRole" val="admin" label="Administrátor" />
          </div><br>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="changeRole(this.deleteNickname, this.newRole)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

    </q-card>
    <br><br>
  </div>
  </div>

  <div class="mobileCard" v-if="this.mobileStore.mobile">
  <div v-for="user in getData()" :key="user.nickname">
    <q-card class="usersCard mobile">
      <q-card-section horizontal>
        <img v-bind:src="user.profilePicture" alt="Uživatelský avatar" class="userPicture"/>
        <q-card-section>
          <p><b>Přezdívka: </b>{{user.nickname}}<br></p>
          <p><b>Role: </b>{{user.role}}</p>
          <p class="error" v-if="this.error !== false">{{this.errorMessage}}</p>
          <q-btn class="bg-purple text-white shadow-2 changeRoleButton allPageButton" align="center" label="Zobrazit profil" @click="this.showProfile(user.nickname)"/><br><br>
          <q-btn class="bg-purple text-white shadow-2 changeRoleButton allPageButton" align="center" label="Změnit roli uživatele" @click="this.changeUpdateState(), this.getName(user.nickname)"/><br><br>
          <q-btn class="bg-red text-white shadow-2 allPageButton" align="center" label="Odstranit uživatele" @click="this.changedeleteState(), this.getName(user.nickname)"/><br><br>
        </q-card-section>
      </q-card-section>

      <q-dialog v-model="this.deleteDialogue">
        <q-card class="deleteAccountDialogue">
          <q-card-section>
            <div class="text-h6">Zrušení účtu:</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            Jste si jisti, že chcete zrušit účet uživatele <b>{{this.deleteNickname}}</b>?
          </q-card-section>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteUser(this.deleteNickname)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-dialog v-model="this.updateDialog">
        <q-card class="updateAccountDialogue">
          <q-card-section>
            <div class="text-h6">Update role uživatele: {{this.deleteNickname}}</div>
          </q-card-section>
          <div class="row">
            <q-radio color="purple" v-model="newRole" val="user" label="Registrovaný uživatel" />
            <q-radio color="purple" v-model="newRole" val="member" label="Člen OLT" />
            <q-radio color="purple" v-model="newRole" val="admin" label="Administrátor" />
          </div><br>
          <q-card-actions>
            <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="changeRole(this.deleteNickname, this.newRole)"  />
            <q-space />
            <q-btn flat label="Zrušit" color="purple" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

    </q-card>
    <br><br>
  </div>
  </div>
  <div class="q-pa-lg flex flex-center">
    <q-pagination
        v-model="page"
        :max="totalPages"
        boundary-numbers
        direction-links
        color="purple"
    ></q-pagination><br>
  </div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useRegisteredUsersStore} from "../stores/RegisteredUsersStore";
import {useUserStore} from "../stores/UserStore";
import {useMobileStore} from "../stores/MobileStore";
import {useAccountDetailStore} from "@/stores/AccountDetailStore";


export default {
  name: "RegisteredUsersView",
  setup() {
    return {
      roles: [
          'user', 'member', 'admin',
      ]
    }
  },

  computed: {
    ...mapStores(useRegisteredUsersStore),
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
    ...mapStores(useAccountDetailStore)

  },

  data(){
    return {
      newRole: null,
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      deleteDialogue: false,
      deleteNickname: '',
      page: 1,
      totalPages: null,
      updateDialog: false,
    }
  },

  async created(){
    this.totalPages = Math.ceil(this.registeredStore.users.length/5);
    await this.registeredStore.getAllUsers(this.userStore.user.nickname);
    this.getData();
  },

  methods: {
    async changeRole(user, role){
      if (this.newRole !== null) {
        try {
          await this.registeredStore.changeRole(this.userStore.user.nickname, user, role);
          this.success = true;
          this.error = false;
          this.successMessage = "Role úspěšně změněna."
        } catch {
          this.error = true;
          this.errorMessage ='Chyba při změně role. Zkuste prosím později';
        }
      } else {
        this.error = true;
        this.errorMessage = 'Pro změnu role musíte vybrat novou roli.';
      }
    },

    changedeleteState(){
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

    changeUpdateState(){
      if (this.updateDialog) {
        this.updateDialog = false;
      } else {
        this.updateDialog = true;
      }
    },

    getName(name){
      this.deleteNickname = name;
    },

    async deleteUser(user){
      try {
        await this.registeredStore.deleteUser(this.userStore.user.nickname, user);
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = 'Chyba při odstranění uživatele. Zkuste prosím později.';
      }
    },

    getData(){
      window.scroll(0,0);
      if (this.page === 1) {
        return this.registeredStore.users.slice(this.page-1, this.page*5);
      } else {
        return this.registeredStore.users.slice(this.page*5-5, this.page*5);
      }
    },

    async showProfile(nickname){
      try {
        await this.accountDetailStore.getUser(nickname);
        this.$router.push({name: 'userDetail'});
        this.error = false;
      } catch {
        this.error = true;
      }
    },

  },
}

</script>

<style scoped>
  .usersCard {
    width: 600px;
    margin: auto;
  }

  .usersCard p {
    font-size: 20px;
  }

  .userPicture {
    height: 100px;
    margin: 20px 20px 20px 20px;
  }

  .changeRoleButton {
    margin-right: 20px;
  }

  .error {
    color: darkred;
    font-size: 15px;
  }

  .noUsers {
    text-align: center;
  }

  .mobile {
    width: 90%;
  }

  .mobileCard {
    width: 100%;
  }

  .allPageButton {
    width: 100%;
  }
</style>