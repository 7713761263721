import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useApplicantStore = defineStore('applicant', {
    state: () => ({
        applicants: [],
        myApplications: [],
        openApplications: [],
        completedApplications: [],
        length: null,
        test: 'Hello there',
        error: null,
        detail: null,
        comments: [],
    }),

    actions: {
        async newApplication(nickname, nicknameVS, nicknameTR, time, style, why, note){
          try {
              await axios.post(config.backendUrl + '/newApplication', {
                  nickname: nickname,
                  vsChar: nicknameVS,
                  trChar: nicknameTR,
                  howLong: time,
                  style: style,
                  whyUs: why,
                  note: note,
                  status: 'Podána'
              });
              this.error = null;
          } catch {
              this.error = 'Chyba při podání přihlášky. Zkuste prosím později.'
          }
        },

        async getMyApplications(nick){
          try {
              const response = await axios.post(config.backendUrl + '/getUserApplications', {
                 nickname: nick,
              });
              this.myApplications = response.data.reverse();
              this.error = null;
          } catch {
              this.error = 'Chyba při načítání Vaěich formulářů.'
          }
        },

        async getDetail(nick, id){
            try {
                const response = await axios.post(config.backendUrl + '/getApplicationDetail', {
                    nickname: nick,
                    id: id,
                });
                this.detail = response.data;
                const responseComments = await axios.post(config.backendUrl + '/loadComments', {
                    nickname: nick,
                    id: id,
                });
                this.comments = responseComments.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při hledání detailu přihlášky.'
            }
        },

        async cancelApplication(nick){
            try {
                await axios.post(config.backendUrl + '/cancelApplication', {
                    nickname: nick,
                    id: this.detail._id,
                });
                this.error = null;
            } catch {
                this.error = 'Chyba při rušení přihlášky.'
            }
        },

        async getOpenApplications(nickname){
            try {
                const response = await axios.post(config.backendUrl + '/getOpenApplications', {
                    nickname: nickname,
                });
                this.openApplications = response.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při načítání přihlášek.'
            }
        },

        async getCompletedApplications(nickname){
            try {
                const response = await axios.post(config.backendUrl + '/getCompletedApplications', {
                    nickname: nickname,
                });
                this.completedApplications = response.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při načítání přihlášek.'
            }
        },

        async updateStatus(nickname, state){
            try {
                await axios.post(config.backendUrl + '/changeApplicationStatus', {
                    nickname: nickname,
                    status: state,
                    id: this.detail._id,
                });
                this.detail.status = state;
                this.error = null;
            } catch {
                this.error = 'Chyba při změně sttavu přihlášky.';
            }
        },

        async comment(nickname, text){
          try {
              await axios.post(config.backendUrl + '/postComment', {
                  nickname: nickname,
                  text: text,
                  applicationID: this.detail._id,
                  date: null,
                  updateDate: null,
              });
              const responseComments = await axios.post(config.backendUrl + '/loadComments', {
                  nickname: nickname,
                  id: this.detail._id,
              });
              this.comments = responseComments.data.reverse();
              this.error = null;
          } catch {
              this.error = 'Chyba při postování komentáře.';
          }
        },

        async deleteComment(nickname, id){
            try {
                await axios.post(config.backendUrl + '/deleteComment', {
                    nickname: nickname,
                    id: id,
                });
                const responseComments = await axios.post(config.backendUrl + '/loadComments', {
                    nickname: nickname,
                    id: this.detail._id,
                });
                this.comments = responseComments.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při odstranění komentáře.';
            }
        },

        async updateComment(nickname, text, id){
            try {
                await axios.post(config.backendUrl + '/updateComment', {
                    nickname: nickname,
                    text: text,
                    id: id,
                    updateDate: null,
                });
                const responseComments = await axios.post(config.backendUrl + '/loadComments', {
                    nickname: nickname,
                    id: this.detail._id,
                });
                this.comments = responseComments.data.reverse();
                this.error = null;
            } catch {
                this.error = 'Chyba při odstranění komentáře.';
            }
        }
    },

    persist: true

})