import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useRegisteredUsersStore = defineStore('registered', {
    state: () => ({
        users: [],
        length: null,
        test: 'Hello there',
    }),

    actions: {
        async getAllUsers(nick){
            try {
                const response = await axios.post(config.backendUrl +'/users', {
                    nickname: nick,
                });
                this.users = response.data;
            } catch {
                this.error = nick;
                this.error = "Chyba při hledání uživatelů!"
            }
        },

        async changeRole(adminNickname, nickname, newRole){
          try {
              await axios.post(config.backendUrl + '/changeRole', {
                  admin: adminNickname,
                  nick: nickname,
                  role: newRole
              });
              await this.getAllUsers(adminNickname);
          } catch {
              this.error = "Chyba při změně přezdívky!";
          }
        },

        async deleteUser(adminNickname, user){
            try {
                await axios.post(config.backendUrl + '/deleteUserByAdmin', {
                    admin: adminNickname,
                    nick: user,
                });
                await this.getAllUsers(adminNickname);
            }catch {
                this.error = "Chyba při odstranení uživatele";
            }
        },
    },

})