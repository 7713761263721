<template>
  <div v-if="!this.mobileStore.mobile">
  <div class="formContainer">
    <p class="accountText">Moje údaje:</p>
    <q-form v-on:submit="changeNickname()">
      <q-input
          color="purple" square filled
          v-model="nickname"
          label="Moje přezdívka"
          v-on:change="checkNickname()"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím svou přezdívku']"/>
      <p v-if="this.nicknameAvailable && this.nickname !== this.userStore.user.nickname" class="success">Přezdívka je dostupná :)</p>
      <p v-if="!this.nicknameAvailable" class="notice">Přezdívka je již zabraná :(</p>
      <p><b>Pro změnu přezdívky zadejte odpověď na bezpečnostní otázku:</b></p>
      <q-input
          color="purple" square filled
          v-model="answeredQuestion"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím odpověď na bezpečnostní otázku']"
          label="Odpověď na bezpečnostní otázku *" required/>
      <p class="accountText">Vaše práva v aplikaci jsou: {{this.role}}</p>
      <p class="notice">Upozornění: Při změně hesla budete odhlášeni a přesměrováni na stránku pro obnovu hesla.</p>
      <q-btn class="bg-purple text-white shadow-2" align="center" label="Změnit přezdívku" flat type="submit" />
      <q-btn class="bg-purple text-white shadow-2 button-right" align="center" label="Změnit heslo" @click="changePassword()" />
      <br><br>
      <p v-if="this.userStore.error && !this.userStore.success" class="notice">{{this.userStore.errorMessage}}</p>
    </q-form>
    <br>
    <p class="accountText">Úprava profilu:</p>
    <p class="accountText">Profilový obrázek:</p>
    <q-form class="imageSelection">
      <div class="row">
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="VS.png" label="VS" />
          <br>
          <img src="../icons/VS.png" class="radioImage" alt="VS logo">
        </div>
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="TR.png" label="TR" />
          <br>
          <img src="../icons/TR.png" class="radioImage" alt="TR logo">
        </div>
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="NC.png" label="NC" />
          <br>
          <img src="../icons/NC.png" class="radioImage" alt="NC logo">
        </div>
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="NS.png" label="NS" />
          <br>
          <img src="../icons/NS.png" class="radioImage" alt="NS logo">
        </div>
      </div><br>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Změnit avatar" @click="changeAvatar" />
      <br><br>
    </q-form>

    <p class="accountText">Postavy na serveru Miller:</p>
    <q-input
        color="purple" square filled
        v-model="VS"
        label="Postava Suverenity Vanu"/><br>
    <q-input
        color="purple" square filled
        v-model="TR"
        label="Postava Terranské republiky"/><br>
    <q-input
        color="purple" square filled
        v-model="NC"
        label="Postava Nového Konglomerátu"/><br>
    <q-input
        color="purple" square filled
        v-model="NS"
        label="Postava Nanite Systems"/><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Uložit moje postavy" @click="updateCharacters()" /><br><br>
    <p class="accountText">Krátký popis:</p>
    <q-input
        label="O mně:"
        color="purple"
        v-model="aboutMe"
        filled
        autogrow
    /><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Uložit můj popis" @click="updateAboutMe()" /><br><br>

    <div>
      <p class="accountText">Zadat kód pro oveření účtu</p>
      <q-input
          color="purple" square filled
          v-model="authenticateCode"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím Váš autentizační kód']"
          label="Autentizační kód *" required/>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Ověřit účet" @click="authenticateMe()" /><br><br>
    </div>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Moje přihláška" :to="{name: 'applicant'}" /><br><br>

    <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Zrušit účet" @click="changedeleteState" />

    <q-dialog v-model="deleteDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Zrušení účtu:</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
        Jste si jisti, že chcete zrušit svůj účet?
        </q-card-section>
        <q-card-section>
          <q-input
              color="purple" square filled
              v-model="password"
              :rules="[ val => val && val.length > 7 || 'Zadejte prosím heslo o délce alespoň 8 znaků']"
              type="password"
              label="Heslo *" />
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteAccount()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div><br><br>
  </div>

<div v-if="this.mobileStore.mobile">
  <div class="formContainer mobile">
    <p class="accountText">Moje údaje:</p>
    <q-form v-on:submit="changeNickname()">
      <q-input
          color="purple" square filled
          v-model="nickname"
          label="Moje přezdívka"
          v-on:change="checkNickname()"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím svou přezdívku']"/>
      <p v-if="this.nicknameAvailable && this.nickname !== this.userStore.user.nickname" class="success">Přezdívka je dostupná :)</p>
      <p v-if="!this.nicknameAvailable" class="notice">Přezdívka je již zabraná :(</p>
      <p><b>Pro změnu přezdívky zadejte odpověď na bezpečnostní otázku:</b></p>
      <q-input
          color="purple" square filled
          v-model="answeredQuestion"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím odpověď na bezpečnostní otázku']"
          label="Odpověď na bezpečnostní otázku *" required/>
      <p class="accountText">Vaše práva v aplikaci jsou: {{this.role}}</p>
      <p class="notice">Upozornění: Při změně hesla budete odhlášeni a přesměrováni na stránku pro obnovu hesla.</p>
      <q-btn class="bg-purple text-white shadow-2" align="center" label="Změnit přezdívku" flat type="submit" />
      <q-btn class="bg-purple text-white shadow-2 button-right" align="center" label="Změnit heslo" @click="changePassword()" />
      <br><br>
      <p v-if="this.userStore.error && !this.userStore.success" class="notice">{{this.userStore.errorMessage}}</p>
    </q-form>
    <br>
    <p class="accountText">Úprava profilu:</p>
    <p class="accountText">Profilový obrázek:</p>
    <q-form class="imageSelection">
      <div class="row">
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="VS.png" label="VS" />
          <br>
          <img src="../icons/VS.png" class="radioImage" alt="VS logo">
        </div>
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="TR.png" label="TR" />
          <br>
          <img src="../icons/TR.png" class="radioImage" alt="TR logo">
        </div>
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="NC.png" label="NC" />
          <br>
          <img src="../icons/NC.png" class="radioImage" alt="NC logo">
        </div>
        <div class="col">
          <q-radio color="purple" v-model="selectedAvatar" val="NS.png" label="NS" />
          <br>
          <img src="../icons/NS.png" class="radioImage" alt="NS logo">
        </div>
      </div><br>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Změnit avatar" @click="changeAvatar" />
      <br><br>
    </q-form>

    <p class="accountText">Postavy na serveru Miller:</p>
    <q-input
        color="purple" square filled
        v-model="VS"
        label="Postava Suverenity Vanu"/><br>
    <q-input
        color="purple" square filled
        v-model="TR"
        label="Postava Terranské republiky"/><br>
    <q-input
        color="purple" square filled
        v-model="NC"
        label="Postava Nového Konglomerátu"/><br>
    <q-input
        color="purple" square filled
        v-model="NS"
        label="Postava Nanite Systems"/><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Uložit moje postavy" @click="updateCharacters()" /><br><br>
    <p class="accountText">Krátký popis:</p>
    <q-input
        label="O mně:"
        color="purple"
        v-model="aboutMe"
        filled
        autogrow
    /><br>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Uložit můj popis" @click="updateAboutMe()" /><br><br>

    <div>
      <p class="accountText">Zadat kód pro oveření účtu</p>
      <q-input
          color="purple" square filled
          v-model="authenticateCode"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím Váš autentizační kód']"
          label="Autentizační kód *" required/>
      <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Ověřit účet" @click="authenticateMe()" /><br><br>
    </div>
    <q-btn class="bg-purple text-white shadow-2 all-page-button" align="center" label="Moje přihláška" :to="{name: 'applicant'}" /><br><br>

    <q-btn class="bg-red text-white shadow-2 all-page-button" align="center" label="Zrušit účet" @click="changedeleteState" />

    <q-dialog v-model="deleteDialogue">
      <q-card class="deleteAccountDialogue">
        <q-card-section>
          <div class="text-h6">Zrušení účtu:</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Jste si jisti, že chcete zrušit svůj účet?
        </q-card-section>
        <q-card-section>
          <q-input
              color="purple" square filled
              v-model="password"
              :rules="[ val => val && val.length > 7 || 'Zadejte prosím heslo o délce alespoň 8 znaků']"
              type="password"
              label="Heslo *" />
        </q-card-section>
        <q-card-actions>
          <q-btn class="bg-purple text-white shadow-2" label="Potvrdit" color="purple" v-close-popup @click="deleteAccount()"  />
          <q-space />
          <q-btn flat label="Zrušit" color="purple" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div><br><br>
</div>

</template>

<script>
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "../stores/UserStore";
import {useMobileStore} from "../stores/MobileStore";
import axios from "axios";
import config from "@/config";

export default {
  setup() {
    return {
    }
  },

  name: "AccountView",

  data(){
    return {
      nickname: '',
      error: '',
      answeredQuestion: '',
      selectedSecurityQuestion: '',
      role: '',
      picture: '',
      selectedAvatar: '',
      nicknameError: false,
      success: false,
      successMessage: '',
      nicknameAvailable: true,
      deleteDialogue: false,
      password: '',
      authenticateCode: null,
      VS: null,
      TR: null,
      NC: null,
      NS: null,
      aboutMe: null,
    }
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore),
  },

  async created(){
    this.nickname = this.userStore.user.nickname;
    this.error = this.picture;
    this.role = this.userStore.user.role;
    this.picture = this.userStore.user.profilePicture;
    this.getRole();
    this.selectedAvatar = this.userStore.user.profilePicture;
    this.VS = this.userStore.user.VS;
    this.TR = this.userStore.user.TR;
    this.NC = this.userStore.user.NC;
    this.NS = this.userStore.user.NS;
    this.aboutMe = this.userStore.user.aboutMe;
  },

  methods: {
    getRole(){
      if (this.role === "user"){
        this.role = "Registrovaný uživatel.";
      } else if (this.role === "member") {
        this.role = "Člen outfitu."
      } else {
        this.role = "Administrátor."
      }
    },

    changePassword(){
      this.userStore.logout(this.nickname);
      this.$router.push({name: 'restore'});
    },

    async changeAvatar(){
      try {
        await this.userStore.changeAvatar(this.nickname, this.selectedAvatar);
        this.picture = this.selectedAvatar;
        this.userStore.user.profilePicture = this.selectedAvatar;
        this.success = true;
        this.successMessage = "Avatar byl úspěšně změněn."
        this.error = false;
      } catch {
        this.error = "Chyba při změně obrázku!";
        this.success = false;
      }
    },

    async changeNickname(){
      try {
        if (this.nicknameAvailable === true) {
          await this.userStore.changeNickname(this.nickname, this.answeredQuestion);
          this.userStore.user.nickname = this.nickname;
          this.success = true;
          this.successMessage = "Přezdívka byla úspěšně změněna.";
          this.error = false;
        }
      } catch {
        this.error = 'Chyba při změně přezdívky.';
        this.success = false;
      }
    },

    async checkNickname(){
      try {
        this.response = await axios.post(config.backendUrl + '/checkNickname', {
          nickname: this.nickname,
        });
        this.nicknameAvailable = this.response.data;
        if (this.nickname === this.userStore.user.nickname){
          this.nicknameAvailable = true;
        }
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = "Chyba při kontrole dostupnosti přezdívky. Zkuste prosím později.";
      }
    },

    changedeleteState(){
      if (this.deleteDialogue) {
        this.deleteDialogue = false;
      } else {
        this.deleteDialogue = true;
      }
    },

    async deleteAccount() {
      if (this.password !== '') {
        this.changedeleteState();
        try {
          await this.userStore.deleteAccount(this.password, this.userStore.user.nickname);
          this.$router.push('login');
        } catch {
          this.error = true;
          this.errorMessage = "Chyba při rušení účtu. Zkuste prosím později.";
        }
      }
    },

    async authenticateMe(){
      try {
        await this.userStore.authenticateMe(this.userStore.user.nickname, this.authenticateCode);
        this.role = this.userStore.user.role;
        this.getRole();
        if(this.userStore.error === false){
          await this.$router.push({name: 'home'});
        }
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = "Chyba při ověření kódu. Zkuste prosím později."
      }
    },

    async updateCharacters(){
      try {
        await this.userStore.saveCharacters(this.userStore.user.nickname, this.VS, this.TR, this.NC, this.NS);
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = "Chyba při ukládání postav. Zkuste prosím později."
      }
    },

    async updateAboutMe(){
      try {
        await this.userStore.updateAbout(this.userStore.user.nickname, this.aboutMe);
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = "Chyba při ukládání popisu profilu. Zkuste prosím později."
      }
    },
  },
}

</script>

<style scoped>
  .formContainer{
    width: 600px;
    margin: auto;
  }

  .button-right {
    float: right;
  }

  .notice{
    font-size: 15px;
    color: darkred;
    font-weight: bold;
  }

  .accountText {
    font-weight: bold;
    font-size: 15px;
  }

  .userNameContainer {
    width: 600px;
    height: 100px;
    margin: auto;
    font-size: 50px;
    font-weight: bold;
    justify-content: center;
  }

  .userPicture {
    height: 100px;
  }

  .imageSelection {
  }

  .radioImage {
    width: 50%;
  }

  .success {
    font-size: 15px;
    color: green;
    font-weight: bold;
  }

  .all-page-button {
    width: 100%;
  }

  .deleteAccountDialogue {
    width: 100%;
  }

  .mobile {
    width: 90%;
  }

  .userNameContainerMobile{
    width: 90%;
    height: 100px;
    font-size: 50px;
    font-weight: bold;
    justify-content: center;
    margin: auto;
  }
</style>