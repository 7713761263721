<template>
  <div class="fixed-center registerForm" v-if="!this.mobileStore.mobile">
    <q-form v-on:submit="register()">
    <p class="registerText">Registrujte se pro odemknutí všech možností aplikace:</p>
    <q-input
        color="purple" square filled
        v-model="nickname"
        v-on:change="checkNickname()"
        :rules="[ val => val && val.length > 0 || 'Zadejte prosím přezdívku']"
        label="Přezdívka *" />
     <p v-if="this.nicknameAvailable" class="success">Přezdívka je dostupná :)</p>
     <p v-if="!this.nicknameAvailable" class="error">Přezdívka je již zabraná :(</p>
    <q-select
        color="purple" filled
        v-model="selectedSecurityQuestion"
        :options="securityQuestion"
        :rules="[ val => val && val.length > 0 || 'Vyberte si prosím bezpečnostní otázku']"
        label="Bezpečnostní otázka *" />
    <br>
    <q-input
        color="purple" square filled
        v-model="answeredQuestion"
        :rules="[ val => val && val.length > 0 || 'Zadejte prosím odpověď na bezpečnostní otázku']"
        label="Odpověď na bezpečnostní otázku *" required/>
    <br>
    <q-input
        color="purple" square filled
        v-model="password"
        :rules="[ val => val && val.length > 7 || 'Zadejte prosím heslo o délce alespoň 8 znaků']"
        v-on:change="testPasswords()"
        type="password"
        label="Heslo *" />
    <br>
    <q-input
        color="purple" square filled
        v-model="passwordCheck"
        v-on:change="testPasswords()"
        error-message="Hesla se neshodují!"
        :error="!passwordsMatch"
        type="password"
        label="Potvrďte prosím heslo *" />
    <br>
    <q-checkbox
        color="purple"
        class="registerText"
        v-model="userAgreed"
        label="Souhlasím s">
    </q-checkbox><a href="" class="licence" @click.prevent="changeLicenceState()"> podmínkami používání aplikace *</a><br><br>
      <p v-if="this.error" class="error">{{this.errorMessage}}</p>
      <p class="error" v-if="agreeToLicence">Pro registraci musíte souhlasit s podmínkami používání aplikace.</p>
    <q-btn class="bg-purple text-white shadow-2 button" align="center" label="Registrovat" flat type="submit" /><br>
    </q-form>
  </div>

  <div class="registerForm mobile" v-if="this.mobileStore.mobile">
    <q-form v-on:submit="register()">
      <p class="registerText">Registrujte se pro odemknutí všech možností aplikace:</p>
      <q-input
          color="purple" square filled
          v-model="nickname"
          v-on:change="checkNickname()"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím přezdívku']"
          label="Přezdívka *" />
      <p v-if="this.nicknameAvailable" class="success">Přezdívka je dostupná :)</p>
      <p v-if="!this.nicknameAvailable" class="error">Přezdívka je již zabraná :(</p>
      <q-select
          color="purple" filled
          v-model="selectedSecurityQuestion"
          :options="securityQuestion"
          :rules="[ val => val && val.length > 0 || 'Vyberte si prosím bezpečnostní otázku']"
          label="Bezpečnostní otázka *" />
      <br>
      <q-input
          color="purple" square filled
          v-model="answeredQuestion"
          :rules="[ val => val && val.length > 0 || 'Zadejte prosím odpověď na bezpečnostní otázku']"
          label="Odpověď na bezpečnostní otázku *" required/>
      <br>
      <q-input
          color="purple" square filled
          v-model="password"
          :rules="[ val => val && val.length > 7 || 'Zadejte prosím heslo o délce alespoň 8 znaků']"
          v-on:change="testPasswords()"
          type="password"
          label="Heslo *" />
      <br>
      <q-input
          color="purple" square filled
          v-model="passwordCheck"
          v-on:change="testPasswords()"
          error-message="Hesla se neshodují!"
          :error="!passwordsMatch"
          type="password"
          label="Potvrďte prosím heslo *" />
      <br>
      <q-checkbox
          color="purple"
          class="registerText"
          v-model="userAgreed"
          label="Souhlasím s">
      </q-checkbox><a href="" class="licence" @click.prevent="changeLicenceState()"> podmínkami používání aplikace *</a><br><br>
      <p v-if="this.error" class="error">{{this.errorMessage}}</p>
      <p class="error" v-if="agreeToLicence">Pro registraci musíte souhlasit s podmínkami používání aplikace.</p>
      <q-btn class="bg-purple text-white shadow-2 button" align="center" label="Registrovat" flat type="submit" /><br><br><br>
    </q-form>
  </div>

  <q-dialog v-model="showLicence">
    <q-card class="relative">
      <q-card-section>
        <div class="text-h6">Licenční podmínky</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <b>Registrací účtu a používáním aplikace dáváte najevo, že souhlasíte s následujícími podmínkami.</b><br><br>
        <b>Bezpečnost:</b><br>
        - Pro zajištění co největší bezpečnosti dat a anonymity uživatelů z osobních dat ukládáme pouze přezdívku.<br>
        - Heslo a odpověď na bezpečnostní otázku jsou uloženy až po zašifrování.<br>
        - Bezpečnostní otázka samotná není uložena, slouží jen jako pomocník pro uživatele.<br><br>
        <b>Obnova hesla:</b><br>
        - Uživatel má právo obnovit si heslo po úspěšném zadání odpovědi na bezpečnostní otázku.<br><br>
        <b>Změna údajů:</b><br>
        - Kromě změny hesla zmíněného výše je možné také změnit přezdívku.<br>
        - Uživatel si po registraci může zvolit jeden z avatarů inspirovaných frakcemi z Planetside.<br><br>
        <b>Co uživatelé získají po registraci:</b><br>
        - Po registraci uživatelé získají přístup k následujícím částem aplikace:<br><br>
        <b>Registrovaní uživatelé:</b><br>
        - Přístup k diskuznímu fóru.<br>
        - Přístup k formuláři umožňujícímu podat žádost o členství v outfitu ve hře Planetside 2 na straně Suverenity Vanu, nebo Terranské republiky.<br><br>
        <b>Členové outfitu:</b><br>
        - Členové outfitu navíc získají přístup k možnosti publikovat návody.<br><br>
        <b>Pravidla používání aplikace:</b><br>
        - Všichni uživatelé jsou si rovni. Jakákoliv diskriminace na základě pohlaví, sexuality, národnosti, oblíbené frakce a další jsou výslovně zakázány.<br>
        - Sdílení pirátského obsahu je zakázáno.<br>
        - Válka je zábavná jen v Planetside. Jakákoliv podpora útočné války ve skutečném světě je silně zakázána.<br><br>
        <b>Není ničeho majestátnějšího něž-li lama. Lamy jsou božská stvoření, jež nemají konkurenci.</b><br><br>
        <b>Beru na vědomí, že porušení výše zmíněných pravidel povede k odstranění mého účtu bez jakéhokoliv varování.</b><br>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Zavřít" color="purple" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "axios";
import config from "../config";
import {useMobileStore} from "../stores/MobileStore";
import {mapStores} from "pinia/dist/pinia";
import {useUserStore} from "@/stores/UserStore";

export default {
  setup(){
    return {
      securityQuestion: [
        'Jméno Vašeho prvního mazlíčka', 'V jakém roce jste začali hrát Planetside', 'Rodné příjmení Vaší matky', 'Váš první outfit v Planetside', 'Vaše oblíbená zbraň/vozidlo v Planetside', 'Prostě mě pusťte. Něco si vymyslím',
      ],
    }
  },
  name: "RegisterView",

  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useMobileStore)
  },


  data(){
    return {
      userAgreed: false,
      showLicence: false,
      passwordsMatch: true,
      formFilled: false,
      selectedSecurityQuestion: '',
      answeredQuestion: '',
      password: '',
      passwordCheck: '',
      nickname: '',
      agreeToLicence: false,
      error: false,
      errorMessage: '',
      nicknameAvailable: true,
      response: '',
    }
  },

  methods: {
    changeLicenceState(){
      if (this.showLicence) {
        this.showLicence = false;
      } else {
        this.showLicence = true;
      }
    },

    testPasswords(){
      if (this.password !== this.passwordCheck) {
        this.passwordsMatch = false;
      } else {
            this.passwordsMatch = true;
        }
    },

    async register(){
      try {
        if (this.nicknameAvailable === true) {
          if (this.userAgreed === true) {
            await axios.post(config.backendUrl + '/add_user', {
              nickname: this.nickname,
              password: this.password,
              securityAnswer: this.answeredQuestion,
              role: 'user',
              profilePicture: 'VS.png',
            });
            await this.$router.push('login');
          } else {
            this.error = true;
            this.errorMessage = "Pro vytvoření účtu musíte souhlasit s podmínkami používáí aplikace.";
          }
        } else {
          this.error = true;
          this.errorMessage = "Vyberte prosím platnou přezdívku.";
        }
      } catch {
        this.error = true;
        this.errorMessage = "Stala se chyba při registraci. Zkuste prosím později.";
      }
    },

    async checkNickname(){
      try {
        this.response = await axios.post(config.backendUrl + '/checkNickname', {
          nickname: this.nickname,
        });
        this.nicknameAvailable = this.response.data;
        this.error = false;
      } catch {
        this.error = true;
        this.errorMessage = "Chyba při kontrole dostupnosti přezdívky. Zkuste prosím později.";
      }
    },

  },
};

</script>

<style scoped>
  .registerForm {
    max-width: 600px;
    margin: auto;
    text-align: center;
  }
  .registerText {
    font-size: 20px;
  }
  .licence {
    color: purple;
    text-decoration: none;
    font-size: 20px;
  }

  .licence:hover{
    color: #008080;
  }

  .error {
    color: darkred;
    font-weight: bold;
    font-size: 15px;
  }

  .success {
    color: green;
    font-weight: bold;
    font-size: 15px;
  }

  .button {
    width: 100%;
  }

  .mobile {
    width: 90%;
  }
</style>