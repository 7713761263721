import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useCodesStore = defineStore('codes', {
    state: () => ({
        codes: [],
        length: null,
        test: 'Hello there',
        error: null,
    }),

    actions: {
        async saveNewCode(admin, faction, role, code){
            try {
                await axios.post(config.backendUrl + '/saveCode', {
                    admin: admin,
                    faction: faction,
                    role: role,
                    code: code
                })
            } catch {
                this.error = "Chyba při ukládání nového kódu"
            }
        },

        async getAllCodes(nick){
            try {
                const response = await axios.post(config.backendUrl +'/getCodes', {
                    nickname: nick,
                });
                this.codes = response.data;
            } catch {
                this.error = "Chyba při hledání kódů!"
            }
        },

        async deleteCodes(admin, id){
            try {
                await axios.post(config.backendUrl + '/deleteCode', {
                    id: id,
                    admin: admin
                });
                await this.getAllCodes(admin);
            } catch {
                this.error = "Chyba při odstranění kódu!"
            }
        },
    }
})