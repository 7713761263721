import { defineStore } from 'pinia'
import axios from "axios";
import config from "../config";

export const useStrategicGuideStore = defineStore('strategicGuide', {
    state: () => ({
        error: null,
        guides: [],
        lastID: null,
        role: null,
        guideDetail: null,
    }),

    actions: {
        async addGuide(nickname, name, description, text, image){
            try {
                const results = await axios.post(config.backendUrl + '/addStrategicGuide', {
                    author: nickname,
                    updateAuthor: null,
                    name: name,
                    description: description,
                    text: text,
                    date: null,
                    updateDate: null,
                    image: image
                });
                this.lastID = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getGuides(){
            try {
                const results = await axios.get(config.backendUrl + '/getStrategicGuides', {

                });
                this.guides = results.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async getGuideDetail(id){
            try {
                const response = await axios.post(config.backendUrl + '/strategicDetail', {
                    ID: id,
                });
                this.guideDetail = response.data;
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async updateGuide(nickname, name, description, text, image){
            try {
                await axios.post(config.backendUrl + '/updateStrategicGuide', {
                    id: this.guideDetail._id,
                    updateAuthor: nickname,
                    name: name,
                    description: description,
                    text: text,
                    updateDate: null,
                    image: image,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

        async deleteGuide(nickname){
            try {
                await axios.post(config.backendUrl + '/deleteStrategicGuide',{
                    id: this.guideDetail._id,
                    nickname: nickname,
                });
                this.error = false;
            } catch {
                this.error = true;
            }
        },

    },

    persist: true
})